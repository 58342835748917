var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { attrs: { title: _vm.$t("rooms") } },
        [
          _c(
            "vs-table",
            {
              ref: "table",
              attrs: { data: _vm.rooms },
              on: { selected: _vm.editRoom },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return [
                      _c(
                        "tbody",
                        _vm._l(data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _c("vs-td", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "product-name font-medium truncate",
                                  },
                                  [_vm._v(_vm._s(tr.name))]
                                ),
                              ]),
                              _c("vs-td", [
                                _c("p", { staticClass: "product-category" }, [
                                  _vm._v(_vm._s(tr.count)),
                                ]),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "div",
                {
                  staticClass: "w-full mb-5 flex justify-end",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "vs-button",
                    { attrs: { icon: "add" }, on: { click: _vm.addRoom } },
                    [_vm._v(_vm._s(_vm.$t("Add")))]
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { "sort-key": "name" } }, [
                    _vm._v(_vm._s(_vm.$t("editRoomForm.name"))),
                  ]),
                  _c("vs-th", { attrs: { "sort-key": "category" } }, [
                    _vm._v(_vm._s(_vm.$t("editRoomForm.count"))),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("room-form", {
        attrs: {
          editRoomActive: _vm.editRoomActive,
          currentRoom: _vm.currentRoom,
          isEditRoom: _vm.isEditRoom,
        },
        on: {
          "update:editRoomActive": function ($event) {
            _vm.editRoomActive = $event
          },
          "update:edit-room-active": function ($event) {
            _vm.editRoomActive = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }