var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex flex-wrap justify-center" },
      _vm._l(_vm.services, function (service) {
        return _c(
          "div",
          {
            key: service.i18n,
            staticClass:
              "bg-white p-10 w-5/12 sm:w-1/4 m-5 flex flex-col justify-between rounded-lg shadow-sm",
          },
          [
            _c("img", {
              staticClass: "w-16 h-16 mb-4 mx-auto",
              attrs: { src: service.icon, alt: "Service Icon" },
            }),
            _c("span", { staticClass: "text-xl my-3 text-center text-p" }, [
              _vm._v(_vm._s(_vm.$t(service.i18n))),
            ]),
            _c("span", { staticClass: "text-sm my-3 text-center text-p" }, [
              _vm._v(_vm._s(_vm.$t(service.details))),
            ]),
            _c(
              "vs-button",
              { attrs: { color: "#7732c5", type: "border", icon: "link" } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("pages.eventfulServices.buttonToGo")) +
                    " "
                ),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }