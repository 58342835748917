var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "scaffold" } }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full mb-10" },
        [
          _c(
            "vs-tabs",
            [
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("home.bookingOptions") } },
                [_c("booking-options-list")],
                1
              ),
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("home.services") } },
                [_c("services-list")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }