var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "mb-base h-full",
      attrs: { title: _vm.$t("bookingsLineChart") },
    },
    [
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("chartjs-component-line-chart", {
            ref: "the_chart",
            attrs: { height: 100, chartData: _vm.data, options: _vm.options },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }