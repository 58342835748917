var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full mb-5" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("venueInformation")))]),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-2 required" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t("venueInformationForm.name"),
              autocomplete: "off",
              placeholder: _vm.$t("venueInformationForm.namePlaceholder"),
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.arabic_name,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "arabic_name", $$v)
              },
              expression: "venue.arabic_name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-2 required" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t("venueInformationForm.address"),
              autocomplete: "off",
              placeholder: _vm.$t("venueInformationForm.addressPlaceholder"),
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.address,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "address", $$v)
              },
              expression: "venue.address",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mb-2 mt-2" },
        [
          _c("vs-textarea", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t("venueInformationForm.about"),
              height: "120px",
              placeholder: _vm.$t("venueInformationForm.aboutPlaceholder"),
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.about,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "about", $$v)
              },
              expression: "venue.about",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mb-2 mt-2" },
        [
          _c("label", { staticClass: "v-select-label" }, [
            _vm._v(_vm._s(_vm.$t("venueInformationForm.conditions"))),
          ]),
          _c("editor", {
            attrs: { theme: "snow", options: _vm.options },
            model: {
              value: _vm.venue.conditions,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "conditions", $$v)
              },
              expression: "venue.conditions",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/3 mb-2 required" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t("venueInformationForm.capacityDescription"),
              autocomplete: "off",
              placeholder: _vm.$t(
                "venueInformationForm.capacityDescriptionPlaceholder"
              ),
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.capacity_description,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "capacity_description", $$v)
              },
              expression: "venue.capacity_description",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "vx-col w-full lg:w-1/3 md:mb-2" }, [
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "w-9/12 required" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  label: _vm.$t("venueInformationForm.phoneNumber"),
                  dir: "ltr",
                  autocomplete: "off",
                  placeholder: _vm.$t(
                    "venueInformationForm.phoneNumberPlaceholder"
                  ),
                  danger: _vm.validation.hasError(
                    "venue.phone_number_without_country_key"
                  ),
                  "danger-text": _vm.validation.firstError(
                    "venue.phone_number_without_country_key"
                  ),
                },
                on: { keyup: _vm.enableSaveButton },
                model: {
                  value: _vm.venue.phone_number_without_country_key,
                  callback: function ($$v) {
                    _vm.$set(_vm.venue, "phone_number_without_country_key", $$v)
                  },
                  expression: "venue.phone_number_without_country_key",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-3/12" },
            [
              _c("v-select", {
                staticClass: "v-select-background w-full mt-6",
                attrs: { clearable: false, options: ["+966"], dir: "ltr" },
                on: { input: _vm.enableSaveButton },
                model: {
                  value: _vm.venue.phone_number_key,
                  callback: function ($$v) {
                    _vm.$set(_vm.venue, "phone_number_key", $$v)
                  },
                  expression: "venue.phone_number_key",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "vx-col w-full lg:w-1/3 md:mb-2" }, [
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "w-9/12 required" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  label: _vm.$t("venueInformationForm.whatsappNumber"),
                  dir: "ltr",
                  autocomplete: "off",
                  placeholder: _vm.$t(
                    "venueInformationForm.phoneNumberPlaceholder"
                  ),
                  danger: _vm.validation.hasError(
                    "venue.whatsapp_number_without_country_key"
                  ),
                  "danger-text": _vm.validation.firstError(
                    "venue.whatsapp_number_without_country_key"
                  ),
                },
                on: { keyup: _vm.enableSaveButton },
                model: {
                  value: _vm.venue.whatsapp_number_without_country_key,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.venue,
                      "whatsapp_number_without_country_key",
                      $$v
                    )
                  },
                  expression: "venue.whatsapp_number_without_country_key",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-3/12" },
            [
              _c("v-select", {
                staticClass: "v-select-background w-full mt-6",
                attrs: { clearable: false, options: ["+966"], dir: "ltr" },
                on: { input: _vm.enableSaveButton },
                model: {
                  value: _vm.venue.whatsapp_number_key,
                  callback: function ($$v) {
                    _vm.$set(_vm.venue, "whatsapp_number_key", $$v)
                  },
                  expression: "venue.whatsapp_number_key",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "vx-col w-full" }, [_c("vs-divider")], 1),
      _c("div", { staticClass: "vx-col w-full lg:w-1/2 mb-2 mt-5" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("venueInformationForm.currentLogo")))]),
        _vm.venue.logo
          ? _c("img", {
              staticClass: "venue-logo border rounded max-w-sm",
              attrs: { src: `${_vm.imagesBaseURL}/${_vm.venue.logo}` },
            })
          : _c("p", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.$t("venueInformationForm.noLogo"))),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-2 mt-5" },
        [
          _c("h4", [
            _vm._v(_vm._s(_vm.$t("venueInformationForm.uploadNewLogo"))),
          ]),
          _c("vs-upload", {
            attrs: {
              action: `${_vm.baseURL}/venues/${_vm.venue.id}/uploadLogo`,
              headers: _vm.headers,
              fileName: "logo",
              limit: 1,
              automatic: "",
            },
            on: { "on-success": _vm.logoChanged, "on-error": _vm.logoError },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "vx-col w-full" }, [_c("vs-divider")], 1),
      _c("div", { staticClass: "vx-col w-full mb-4" }, [
        _c("h4", [
          _vm._v(_vm._s(_vm.$t("venueInformationForm.sections.pricings"))),
        ]),
      ]),
      _c("div", { staticClass: "vx-col w-full lg:w-1/4 mb-2" }, [
        _c("div", { staticClass: "container mt-2" }, [
          _c("div", { staticClass: "vx-row required" }, [
            _c("label", { staticClass: "v-select-label" }, [
              _vm._v(_vm._s(_vm.$t("venueInformationForm.taxingStatus"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "vx-row mt-3" },
            [
              _c(
                "vs-radio",
                {
                  staticClass: "mr-2",
                  attrs: { "vs-name": "venue.is_taxable", "vs-value": 1 },
                  model: {
                    value: _vm.venue.is_taxable,
                    callback: function ($$v) {
                      _vm.$set(_vm.venue, "is_taxable", $$v)
                    },
                    expression: "venue.is_taxable",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("venueInformationForm.selectText.taxable"))
                  ),
                ]
              ),
              _c(
                "vs-radio",
                {
                  staticClass: "mr-2",
                  attrs: { "vs-name": "venue.is_taxable", "vs-value": 0 },
                  model: {
                    value: _vm.venue.is_taxable,
                    callback: function ($$v) {
                      _vm.$set(_vm.venue, "is_taxable", $$v)
                    },
                    expression: "venue.is_taxable",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("venueInformationForm.selectText.nonTaxable"))
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "vx-col w-full lg:w-1/4 mb-2" }, [
        _c("div", { staticClass: "container mt-2" }, [
          _c("div", { staticClass: "vx-row required" }, [
            _c("label", { staticClass: "v-select-label" }, [
              _vm._v(_vm._s(_vm.$t("venueInformationForm.depositType"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "vx-row mt-3" },
            [
              _c(
                "vs-radio",
                {
                  staticClass: "mr-2",
                  attrs: {
                    "vs-name": "venue.deposit_type",
                    "vs-value": "fixed",
                  },
                  model: {
                    value: _vm.venue.deposit_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.venue, "deposit_type", $$v)
                    },
                    expression: "venue.deposit_type",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("venueInformationForm.selectText.fixed"))
                  ),
                ]
              ),
              _c(
                "vs-radio",
                {
                  staticClass: "mr-2",
                  attrs: {
                    "vs-name": "venue.deposit_type",
                    "vs-value": "percentage",
                  },
                  model: {
                    value: _vm.venue.deposit_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.venue, "deposit_type", $$v)
                    },
                    expression: "venue.deposit_type",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("venueInformationForm.selectText.percentage"))
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.venue.deposit_type == "fixed"
        ? _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/2 mb-2 required" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  label: _vm.$t("venueInformationForm.deposit"),
                  autocomplete: "off",
                },
                on: { keyup: _vm.enableSaveButton },
                model: {
                  value: _vm.venue.deposit,
                  callback: function ($$v) {
                    _vm.$set(_vm.venue, "deposit", $$v)
                  },
                  expression: "venue.deposit",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "vx-col w-1/2 md:mb-2 required" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  label: _vm.$t("venueInformationForm.percentageDeposit"),
                  autocomplete: "off",
                  placeholder: _vm.$t(
                    "venueInformationForm.percentageDepositPlaceholder"
                  ),
                },
                on: { keyup: _vm.enableSaveButton },
                model: {
                  value: _vm.venue.percentage_deposit,
                  callback: function ($$v) {
                    _vm.$set(_vm.venue, "percentage_deposit", $$v)
                  },
                  expression: "venue.percentage_deposit",
                },
              }),
            ],
            1
          ),
      _c("div", { staticClass: "vx-col w-full" }, [_c("vs-divider")], 1),
      _c("div", { staticClass: "vx-col w-full mb-4" }, [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm.$t("venueInformationForm.sections.commercialInformation")
            )
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/3 mb-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t("venueInformationForm.commercialName"),
              autocomplete: "off",
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.commercial_name,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "commercial_name", $$v)
              },
              expression: "venue.commercial_name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/3 mb-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t(
                "venueInformationForm.commercialRegistrationNumber"
              ),
              autocomplete: "off",
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.commercial_registration_number,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "commercial_registration_number", $$v)
              },
              expression: "venue.commercial_registration_number",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/3 mb-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t("venueInformationForm.vatNumber"),
              autocomplete: "off",
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.vat_number,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "vat_number", $$v)
              },
              expression: "venue.vat_number",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t("venueInformationForm.iban"),
              autocomplete: "off",
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.iban,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "iban", $$v)
              },
              expression: "venue.iban",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/4 mb-2" },
        [
          _c("label", { staticClass: "v-select-label" }, [
            _vm._v(_vm._s(_vm.$t("venueInformationForm.bankName"))),
          ]),
          _c("v-select", {
            staticClass: "v-select-background",
            attrs: {
              clearable: false,
              options: _vm.saudiBanksList,
              label: "name",
              reduce: (bank) => bank.name,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: { input: _vm.enableSaveButton },
            model: {
              value: _vm.venue.bank_name,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "bank_name", $$v)
              },
              expression: "venue.bank_name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/4 mb-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t("venueInformationForm.bankAccountHolderName"),
              autocomplete: "off",
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.bank_account_holder_name,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "bank_account_holder_name", $$v)
              },
              expression: "venue.bank_account_holder_name",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "vx-col w-full" }, [_c("vs-divider")], 1),
      _c("div", { staticClass: "vx-col w-full mb-4" }, [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm.$t("venueInformationForm.sections.additionalInformation")
            )
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-full md:mb-2 mt-2" },
        [
          _c("vs-textarea", {
            staticClass: "w-full",
            attrs: {
              label: _vm.$t("venueInformationForm.notesForAdmin"),
              height: "120px",
              placeholder: _vm.$t(
                "venueInformationForm.notesForAdminPlaceholder"
              ),
            },
            on: { keyup: _vm.enableSaveButton },
            model: {
              value: _vm.venue.notes_for_admin,
              callback: function ($$v) {
                _vm.$set(_vm.venue, "notes_for_admin", $$v)
              },
              expression: "venue.notes_for_admin",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "vx-col w-full" }, [
        _c(
          "div",
          { staticClass: "vx-row m-0 mb-5 justify-end" },
          [
            _c(
              "vx-tooltip",
              {
                attrs: {
                  text: _vm.isSaveDisabled
                    ? _vm.$t(
                        "venueInformationForm.tooltip.disabledButtonVenueInformation"
                      )
                    : _vm.$t(
                        "venueInformationForm.tooltip.saveVenueInformation"
                      ),
                },
              },
              [
                _c(
                  "vs-button",
                  {
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-check",
                      disabled: _vm.isSaveDisabled,
                    },
                    on: { click: _vm.submitForm },
                  },
                  [_vm._v(_vm._s(_vm.$t("save")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "vx-row mt-10" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:mb-2" },
        [_c("rooms-table")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }