var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentBooking != null
    ? _c(
        "div",
        [
          _c(
            "vs-popup",
            {
              staticClass: "holamundo",
              attrs: {
                title:
                  "#" +
                  _vm.currentBooking.booking_number +
                  " | " +
                  _vm.currentBooking.customer_name,
                active: _vm.isActive,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isActive = $event
                },
                close: _vm.closePopup,
              },
            },
            [
              _c("div", { staticClass: "container w-full" }, [
                !_vm.isLeadBooking
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-row w-full items-end justify-between m-0",
                      },
                      [
                        _c("div"),
                        _c(
                          "div",
                          { staticClass: "w-full flex justify-end" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "mx-2",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-file",
                                  color: "success",
                                  type: "border",
                                },
                                on: { click: _vm.generateReceipt },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("buttons.generateReceipt"))
                                ),
                              ]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "mx-2",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-edit",
                                },
                                on: { click: _vm.editBooking },
                              },
                              [_vm._v(_vm._s(_vm.$t("buttons.edit")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.isLeadBooking
                  ? _c("hr", {
                      staticClass: "row w-full my-5",
                      staticStyle: { height: "1px", opacity: "0.2" },
                    })
                  : _vm._e(),
                _vm.currentBooking.state && !_vm.isLeadBooking
                  ? _c(
                      "div",
                      { staticClass: "vx-row m-0 mb-5 justify-around" },
                      [
                        _c("div", { staticClass: "vx-col lg:w-1/3 mt-5" }, [
                          _c("h5", { staticClass: "mb-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("bookingPreview.currentBookingState")
                              )
                            ),
                          ]),
                          _c(
                            "h5",
                            {
                              staticClass:
                                "rounded-lg p-3 px-5 text-base text-white text-center",
                              style: {
                                background: _vm.currentBooking.state.color,
                              },
                              attrs: { color: _vm.currentBooking.state.color },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.currentBooking.state.agent_display_name
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col lg:w-2/3 mt-5 pl-10" },
                          [
                            _c("h5", { staticClass: "mb-2 w-full" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("bookingPreview.changeBookingStateTo")
                                )
                              ),
                            ]),
                            _vm.statesList.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "vx-row justify-start" },
                                  [
                                    _vm._l(_vm.statesList, function (state) {
                                      return _c(
                                        "vx-tooltip",
                                        {
                                          key: state.id,
                                          staticClass: "tooltip-popup",
                                          attrs: {
                                            text: state.action_description,
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass:
                                                "state-button mt-1 mx-1",
                                              attrs: { color: state.color },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.manageStateSubmission(
                                                    state
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(state.agent_action))]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "state-button mt-1 mx-1",
                                        attrs: { color: "danger" },
                                        on: {
                                          click: function ($event) {
                                            _vm.canceledPromptActive = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "bookingPreview.cancelBooking"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  { staticClass: "vx-row justify-start" },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "state-button mt-1 mx-1",
                                        attrs: {
                                          "icon-pack": "feather",
                                          icon: "icon-message-circle",
                                          color: "success",
                                        },
                                        on: { click: _vm.changeStateWhatsapp },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "bookingPreview.whatsappContactToChangeState"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        ),
                        _vm.statesList.length > 0
                          ? _c(
                              "div",
                              { staticClass: "vx-col w-full my-3" },
                              [
                                _c(
                                  "vs-alert",
                                  {
                                    attrs: {
                                      color: "warning",
                                      icon: "new_releases",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "bookingPreview.updateStateNote"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm.currentBooking.cancelation_reason &&
                (_vm.currentBooking.state_id == 6 ||
                  _vm.currentBooking.state_id == 19 ||
                  _vm.currentBooking.state_id == 20 ||
                  _vm.currentBooking.state_id == 21)
                  ? _c("div", { staticClass: "w-full px-3 py-5" }, [
                      _vm.currentBooking.state_id == 20
                        ? _c("div", [
                            _c("div", { staticClass: "flex space-x-3" }, [
                              _c("span", { staticClass: "text-lg" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("bookingPreview.refundAmount")
                                  ) + ":"
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentBooking.receipt.refund_amount
                                  ) +
                                    " " +
                                    _vm._s(_vm.$t("SAR"))
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "mt-3" }, [
                        _c("span", { staticClass: "text-lg" }, [
                          _vm._v(
                            _vm._s(_vm.$t("bookingPreview.cancelationReason"))
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "px-1" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.currentBooking.cancelation_reason)),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "vx-row m-0 w-full" }, [
                  _c(
                    "div",
                    { staticClass: "vx-row w-full sm:flex flex m-0 mt-5" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                        },
                        [
                          _c(
                            "h4",
                            { staticClass: "opacity-75 text-lg lg:text-2xl" },
                            [
                              _c("i", { staticClass: "far fa-user" }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("booking.customerName"))
                              ),
                            ]
                          ),
                          _c("h4", { staticClass: "text-lg lg:text-2xl" }, [
                            _vm._v(_vm._s(_vm.currentBooking.customer_name)),
                          ]),
                        ]
                      ),
                      !_vm.isLeadBooking
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                            },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _c("i", { staticClass: "far fa-mobile" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("booking.customerPhoneNumber")
                                      )
                                  ),
                                ]
                              ),
                              _c("h4", { staticClass: "text-lg lg:text-2xl" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentBooking.customer_phone_number
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.isLeadBooking && _vm.currentBooking.customer_city
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                            },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _c("i", { staticClass: "far fa-map-pin" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("booking.customer_address"))
                                  ),
                                ]
                              ),
                              _c("h4", { staticClass: "text-lg lg:text-2xl" }, [
                                _vm._v(
                                  _vm._s(_vm.currentBooking.customer_city)
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.isLeadBooking &&
                      _vm.currentBooking.customer_commercial_registration_number
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                            },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _c("i", { staticClass: "far fa-file" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "booking.customer_commercial_registration_number"
                                        )
                                      )
                                  ),
                                ]
                              ),
                              _c("h4", { staticClass: "text-lg lg:text-2xl" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentBooking
                                      .customer_commercial_registration_number
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.isLeadBooking &&
                      _vm.currentBooking.customer_vat_number
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                            },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _c("i", { staticClass: "far fa-file" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("booking.customer_vat_number")
                                      )
                                  ),
                                ]
                              ),
                              _c("h4", { staticClass: "text-lg lg:text-2xl" }, [
                                _vm._v(
                                  _vm._s(_vm.currentBooking.customer_vat_number)
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.currentBooking.customer_notes
                        ? _c("vs-textarea", {
                            staticClass: "mt-5 w-full",
                            attrs: {
                              label: _vm.$t("addBookingSidebar.customerNotes"),
                              value: _vm.currentBooking.customer_notes,
                              readonly: "",
                            },
                          })
                        : _vm._e(),
                      !_vm.isLeadBooking
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-row p-0 w-full mt-4 justify-between",
                            },
                            [
                              _vm.isPhoneScreen
                                ? _c(
                                    "vs-button",
                                    {
                                      staticClass: "flex-grow mx-1 h-12",
                                      attrs: {
                                        "icon-pack": "feather",
                                        icon: "icon-phone",
                                      },
                                      on: { click: _vm.callCustomer },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "addBookingSidebar.phone_call"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "vs-button",
                                {
                                  staticClass: "flex-grow mx-1",
                                  attrs: {
                                    "icon-pack": "feather",
                                    icon: "icon-message-circle",
                                    color: "success",
                                  },
                                  on: { click: _vm.whatsappCustomer },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("addBookingSidebar.whatsapp")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "vx-row w-full m-0 mt-5 mb-5 opacity-50",
                        },
                        [_c("hr", { staticClass: "w-full" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-row w-full sm:flex flex m-0" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                        },
                        [
                          _c(
                            "h3",
                            { staticClass: "opacity-75 text-lg lg:text-2xl" },
                            [
                              _c("i", {
                                staticClass: "far fa-solid fa-thumbs-up",
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("booking.eventType"))),
                            ]
                          ),
                          _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentBooking.event_type
                                  ? _vm.currentBooking.event_type.arabic_name
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                        },
                        [
                          _c(
                            "h3",
                            { staticClass: "opacity-75 text-lg lg:text-2xl" },
                            [
                              _c("i", {
                                staticClass: "far fa-solid fa-calendar-check",
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("booking.bookingDate"))
                              ),
                            ]
                          ),
                          _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentBooking.event_type
                                  ? _vm.currentBooking.start_date
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm.currentBooking.start_time &&
                      _vm.currentBooking.end_time
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _c("i", {
                                    staticClass: "far fa-solid fa-clock",
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("booking.bookingTime"))
                                  ),
                                ]
                              ),
                              _c("div", [
                                _c(
                                  "h3",
                                  { staticClass: "text-lg lg:text-2xl" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentBooking.start_time
                                          ? _vm.convertToAmPm(
                                              _vm.currentBooking.start_time
                                            )
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "h3",
                                  { staticClass: "text-lg lg:text-2xl" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentBooking.end_time
                                          ? _vm.convertToAmPm(
                                              _vm.currentBooking.end_time
                                            )
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._l(
                        _vm.currentBooking.event_type.guests_inputs,
                        function (guestInput) {
                          return _c(
                            "div",
                            {
                              key: guestInput.key,
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _c("i", {
                                    staticClass: "far fa-solid fa-ballot-check",
                                  }),
                                  _vm._v(" " + _vm._s(guestInput.name)),
                                ]
                              ),
                              _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentBooking[guestInput.key] > 0
                                      ? _vm.currentBooking[guestInput.key]
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                        },
                        [
                          _c(
                            "h3",
                            { staticClass: "opacity-75 text-lg lg:text-2xl" },
                            [
                              _c("i", {
                                staticClass:
                                  "far fa-solid fa-dollar-sign ml-1 mr-1",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("booking.bookingFinalValue"))
                              ),
                            ]
                          ),
                          _c("div", [
                            _c(
                              "h3",
                              {
                                staticClass: "text-danger text-lg lg:text-2xl",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.currentBooking.receipt
                                        .local_final_price_without_discount
                                        ? _vm.currentBooking.receipt.local_final_price_without_discount.toLocaleString()
                                        : 0 + " "
                                    ) +
                                    " " +
                                    _vm._s(_vm.$t("SAR")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col p-0 w-full flex flex-wrap justify-center",
                              },
                              [
                                _c(
                                  "small",
                                  { staticClass: "very-small-note" },
                                  [_vm._v(_vm._s(_vm.$t("vatIncluded")))]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm.currentBooking.state_id == 17
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "opacity-75 text-success text-lg lg:text-2xl",
                                },
                                [
                                  _c("i", {
                                    staticClass: "far fa-solid fa-check",
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$t("depositPaid"))),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.currentBooking.receipt.discount > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap sm:justify-between justify-center mt-5",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "opacity-75 sm:mx-0 mx-9 text-success",
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "far fa-solid fa-dollar-sign ml-1 mr-1",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "booking.bookingFinalValueAfterDiscount",
                                          {
                                            discount:
                                              _vm.currentBooking.receipt
                                                .discount,
                                          }
                                        )
                                      )
                                  ),
                                ]
                              ),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-success text-lg lg:text-2xl sm:mt-1 sm:mx-0 mx-20 mt-3",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentBooking.receipt
                                          .discounted_price
                                          ? _vm.currentBooking.receipt.discounted_price.toLocaleString()
                                          : 0 + " "
                                      ) +
                                      " " +
                                      _vm._s(_vm.$t("SAR")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("hr", {
                    staticClass: "row w-full mt-5 mb-3",
                    staticStyle: { height: "1px", opacity: "0.2" },
                  }),
                  !_vm.isLeadBooking
                    ? _c(
                        "div",
                        { staticClass: "vx-row w-full sm:flex flex m-0" },
                        [
                          _c("div", { staticClass: "vx-col p-0 w-full mt-5" }, [
                            _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "invoice.paymentDetails.paymentDetails"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "invoice.paymentDetails.collectingFromCustomer"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentBooking.receipt.local_final_price
                                      ? _vm.currentBooking.receipt.local_final_price.toLocaleString()
                                      : 0 + " "
                                  ) +
                                    " " +
                                    _vm._s(_vm.$t("SAR"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "invoice.paymentDetails.collectedFromEventful"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentBooking.receipt
                                      .total_paid_amount_at_qosoor
                                      ? _vm.currentBooking.receipt.total_paid_amount_at_qosoor.toLocaleString()
                                      : 0 + " "
                                  ) +
                                    " " +
                                    _vm._s(_vm.$t("SAR"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "invoice.paymentDetails.collectedFromPlace"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentBooking.receipt
                                      .paid_at_place_amount
                                      ? _vm.currentBooking.receipt.paid_at_place_amount.toLocaleString()
                                      : 0 + " "
                                  ) +
                                    " " +
                                    _vm._s(_vm.$t("SAR"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "opacity-75 text-lg lg:text-2xl",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "invoice.paymentDetails.remainingToBeCollected"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentBooking.receipt.remaining_amount
                                      ? _vm.currentBooking.receipt.remaining_amount.toLocaleString()
                                      : 0 + " "
                                  ) +
                                    " " +
                                    _vm._s(_vm.$t("SAR"))
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "vx-col p-0 w-full mt-5" }, [
                            _c(
                              "h3",
                              { staticClass: "opacity-75 text-lg lg:text-2xl" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "invoice.paymentDetails.paymentNotesByAgent"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c("p", { staticClass: "text-md mt-3" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.currentBooking.payment_notes_by_agent
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "vx-row w-full sm:flex flex m-0 mt-5" },
                    [
                      _c(
                        "vs-collapse",
                        {
                          staticClass: "p-0 w-full",
                          attrs: { type: "margin" },
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.$t("booking.bookingOptionTitle"))
                            ),
                          ]),
                          _vm.currentBooking.receipt.booking_option
                            ? _c(
                                "vs-collapse-item",
                                {
                                  attrs: {
                                    "icon-arrow": "arrow_downward",
                                    open: _vm.isLeadBooking,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vx-col p-0 w-full flex flex-wrap lg:justify-between justify-center pr-5",
                                        },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "opacity-75 text-lg lg:text-2xl",
                                              class: {
                                                "mt-3":
                                                  _vm.currentBooking.receipt
                                                    .receipt_taxable ||
                                                  _vm.currentBooking.receipt
                                                    .qosoor_discount > 0,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "| " +
                                                  _vm._s(
                                                    _vm.currentBooking.receipt
                                                      .booking_option.name
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "details-text truncate",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "booking.showDetails"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex flex-wrap lg:mt-1 lg:justify-end justify-center mt-2",
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "text-danger text-lg lg:text-2xl",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentBooking
                                                          .receipt
                                                          .total_price_with_tax_for_agent
                                                          ? _vm.currentBooking.receipt.total_price_with_tax_for_agent.toLocaleString()
                                                          : 0 + " "
                                                      ) +
                                                      " " +
                                                      _vm._s(_vm.$t("SAR")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vx-col p-0 w-full flex flex-wrap lg:justify-end justify-center",
                                                },
                                                [
                                                  _vm.currentBooking.receipt
                                                    .receipt_taxable
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "very-small-note",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "vatIncluded"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.currentBooking.receipt.booking_option
                                    ? _c(
                                        "div",
                                        { staticClass: "flex flex-wrap mb-5" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vx-col w-full flex flex-wrap",
                                            },
                                            [
                                              _vm.currentBooking.event_type
                                                ? _c(
                                                    "div",
                                                    { staticClass: "w-full" },
                                                    _vm._l(
                                                      _vm.currentBooking
                                                        .event_type
                                                        .guests_inputs,
                                                      function (guestInput) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: guestInput.key,
                                                            staticClass:
                                                              "vx-col w-full flex flex-wrap mt-3",
                                                          },
                                                          [
                                                            _vm.currentBooking[
                                                              guestInput.key
                                                            ] > 0
                                                              ? _c(
                                                                  "h5",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2 w-1/2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        guestInput.name
                                                                          ? guestInput.name
                                                                          : _vm.$t(
                                                                              "booking.guestsCount"
                                                                            )
                                                                      ) + ":"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm.currentBooking[
                                                              guestInput.key
                                                            ] > 0
                                                              ? _c("vs-input", {
                                                                  staticClass:
                                                                    "w-1/2",
                                                                  attrs: {
                                                                    readonly:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentBooking[
                                                                        guestInput
                                                                          .key
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.currentBooking,
                                                                          guestInput.key,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "currentBooking[guestInput.key]",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "w-full" },
                                                [
                                                  _c("vs-input", {
                                                    staticClass: "w-full mt-5",
                                                    attrs: {
                                                      readonly: "",
                                                      label: _vm.$t(
                                                        "booking.bookingOption.description"
                                                      ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.currentBooking
                                                          .receipt
                                                          .booking_option
                                                          .description,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.currentBooking
                                                            .receipt
                                                            .booking_option,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "currentBooking.receipt.booking_option.description",
                                                    },
                                                  }),
                                                  _c("vs-input", {
                                                    staticClass: "w-full mt-5",
                                                    attrs: {
                                                      readonly: "",
                                                      label: _vm.$t(
                                                        "booking.bookingOption.priceNoVat"
                                                      ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.currentBooking
                                                          .receipt
                                                          .booking_option.price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.currentBooking
                                                            .receipt
                                                            .booking_option,
                                                          "price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "currentBooking.receipt.booking_option.price",
                                                    },
                                                  }),
                                                  _c("vs-textarea", {
                                                    staticClass: "w-full mt-5",
                                                    attrs: {
                                                      readonly: "",
                                                      label: _vm.$t(
                                                        "booking.bookingOption.detailedDescription"
                                                      ),
                                                      height: "150px",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.currentBooking
                                                          .receipt
                                                          .booking_option
                                                          .detailed_description,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.currentBooking
                                                            .receipt
                                                            .booking_option,
                                                          "detailed_description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "currentBooking.receipt.booking_option.detailed_description",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm.currentBooking.receipt.booking_copy
                            ? _c(
                                "div",
                                [
                                  _vm.currentBooking.receipt.booking_copy
                                    .internal_services.length > 0
                                    ? _c("h3", { staticClass: "m-5" }, [
                                        _vm._v("الإضافات:"),
                                      ])
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.currentBooking.receipt.booking_copy
                                      .internal_services,
                                    function (internalService) {
                                      return _c(
                                        "vs-collapse-item",
                                        {
                                          key: internalService.id,
                                          attrs: {
                                            "icon-arrow": "arrow_downward",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "header" },
                                              slot: "header",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vx-col p-0 w-full flex flex-wrap xl:justify-between justify-center pr-5",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex flex-wrap lg:mt-1 lg:justify-end justify-center mt-2",
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "opacity-75 text-lg lg:text-2xl mt-3",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "| " +
                                                              _vm._s(
                                                                internalService.name
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "details-text truncate lg:mt-5 mt-3",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "booking.showDetails"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex flex-wrap lg:mt-1 lg:justify-end justify-center mt-2",
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "text-danger text-lg lg:text-2xl",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  internalService.final_price.toLocaleString() +
                                                                    " "
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "SAR"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vx-col p-0 w-full flex flex-wrap lg:justify-end justify-center",
                                                        },
                                                        [
                                                          internalService.is_taxable
                                                            ? _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "very-small-note",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "vatIncluded"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "vx-col w-full" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex flex-wrap",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vx-col w-full flex flex-wrap",
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "mt-2 w-1/4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "booking.service.serviceName"
                                                              )
                                                            ) + ":"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("vs-input", {
                                                        staticClass: "w-3/4",
                                                        attrs: { readonly: "" },
                                                        model: {
                                                          value:
                                                            internalService.name,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              internalService,
                                                              "name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "internalService.name",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vx-col w-full flex flex-wrap mt-4",
                                                    },
                                                    [
                                                      internalService.description
                                                        ? _c("vs-input", {
                                                            staticClass:
                                                              "w-full px-1",
                                                            attrs: {
                                                              readonly: "",
                                                              label: _vm.$t(
                                                                "booking.service.description"
                                                              ),
                                                            },
                                                            model: {
                                                              value:
                                                                internalService.description,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    internalService,
                                                                    "description",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "internalService.description",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _c("vs-input", {
                                                        staticClass:
                                                          "w-1/2 px-1",
                                                        attrs: {
                                                          readonly: "",
                                                          label: _vm.$t(
                                                            "booking.service.price"
                                                          ),
                                                          value:
                                                            internalService.final_price -
                                                            internalService.tax,
                                                        },
                                                      }),
                                                      _c("vs-input", {
                                                        staticClass:
                                                          "w-1/2 px-1",
                                                        attrs: {
                                                          readonly: "",
                                                          label: _vm.$t(
                                                            "booking.service.taxAmount"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            internalService.tax,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              internalService,
                                                              "tax",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "internalService.tax",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          !_vm.isLeadBooking
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-row w-full m-0 mt-10 mb-5 opacity-50",
                                },
                                [_c("hr", { staticClass: "w-full" })]
                              )
                            : _vm._e(),
                          !_vm.isLeadBooking
                            ? _c(
                                "vs-collapse-item",
                                {
                                  staticClass: "mb-20",
                                  attrs: { "icon-arrow": "arrow_downward" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vx-col p-0 w-full flex flex-wrap justify-between pr-5",
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "opacity-75" },
                                            [
                                              _vm._v(
                                                "| " +
                                                  _vm._s(
                                                    _vm.$t("invoice.invoice")
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "vs-chip",
                                            {
                                              staticClass:
                                                "product-order-status",
                                              attrs: {
                                                color:
                                                  _vm.currentBooking
                                                    .source_color,
                                              },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "font-medium truncate",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.currentBooking.source
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "vx-col w-full" }, [
                                    _c("h4", [
                                      _vm._v(_vm._s(_vm.$t("invoice.general"))),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-wrap" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "vx-row w-full ml-1" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full mt-4 flex flex-wrap",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "inline" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.totalPrice"
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass: "inline ml-10",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.currentBooking
                                                            .receipt
                                                            .final_price_for_agent
                                                            ? _vm.currentBooking.receipt.final_price_for_agent.toLocaleString()
                                                            : 0 + " "
                                                        ) +
                                                        " " +
                                                        _vm._s(_vm.$t("SAR")) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full lg:w-1/2 mt-2 flex flex-wrap",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "inline" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.eventfulPercentage"
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass: "inline ml-5",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentBooking
                                                          .receipt
                                                          .final_percentage_qosoor_commission
                                                      ) + " %"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full lg:w-1/2 mt-2 flex flex-wrap",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "inline" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.yourNet"
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass: "inline ml-10",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentBooking
                                                          .receipt
                                                          .cost_of_goods_sold
                                                          ? _vm.currentBooking.receipt.cost_of_goods_sold.toLocaleString()
                                                          : 0 + " "
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.$t("SAR"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-row w-full m-0 mt-5 mb-5 opacity-50",
                                      },
                                      [_c("hr", { staticClass: "w-full" })]
                                    ),
                                    _c("h4", [
                                      _vm._v(_vm._s(_vm.$t("invoice.details"))),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-wrap" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "vx-row w-full ml-1" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full mt-3",
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    "| " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.option.optionPrice"
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full mt-2 flex flex-wrap",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "inline" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.option.beforeVat"
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "inline lg:ml-3 ml-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentBooking
                                                          .receipt.total_price
                                                          ? _vm.currentBooking.receipt.total_price.toLocaleString()
                                                          : 0 + " "
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.$t("SAR"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full mt-2 flex flex-wrap",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "inline" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.option.vat"
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "inline lg:ml-3 ml-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentBooking
                                                          .receipt
                                                          .tax_without_discount
                                                          ? _vm.currentBooking.receipt.tax_without_discount.toLocaleString()
                                                          : 0 + " "
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.$t("SAR"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full mt-2 flex flex-wrap",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "inline" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.option.afterVat"
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "inline lg:ml-3 ml-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentBooking
                                                          .receipt
                                                          .total_price_with_tax
                                                          ? _vm.currentBooking.receipt.total_price_with_tax.toLocaleString()
                                                          : 0 + " "
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.$t("SAR"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.currentBooking.receipt
                                              .discount > 0
                                              ? _c("div", [
                                                  _vm.currentBooking.receipt
                                                    .agent_manual_discount > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vx-col w-full mt-2 flex flex-wrap",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "inline",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "invoice.option.discountBy"
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .currentVenue
                                                                      .arabic_name
                                                                  ) +
                                                                  ":"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "inline lg:ml-3 ml-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.currentBooking.receipt.agent_manual_discount.toLocaleString()
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.currentBooking.receipt
                                                    .manual_discount > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vx-col w-full mt-2 flex flex-wrap",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "inline",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "invoice.option.discountBy"
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "eventful"
                                                                    )
                                                                  ) +
                                                                  ":"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "inline lg:ml-3 ml-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.currentBooking.receipt.manual_discount.toLocaleString()
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "SAR"
                                                                    )
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.currentBooking.receipt
                                                    .discount -
                                                    _vm.currentBooking.receipt
                                                      .agent_manual_discount >
                                                    0 &&
                                                  (_vm.currentBooking.receipt
                                                    .manual_discount == null ||
                                                    _vm.currentBooking.receipt
                                                      .manual_discount == 0)
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vx-col w-full mt-2 flex flex-wrap",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "inline",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "invoice.option.discountBy"
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "eventful"
                                                                    )
                                                                  ) +
                                                                  ":"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "inline lg:ml-3 ml-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  (
                                                                    _vm
                                                                      .currentBooking
                                                                      .receipt
                                                                      .discount -
                                                                    _vm
                                                                      .currentBooking
                                                                      .receipt
                                                                      .agent_manual_discount
                                                                  ).toLocaleString()
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "SAR"
                                                                    )
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vx-col w-full mt-2 flex flex-wrap",
                                                    },
                                                    [
                                                      _c(
                                                        "h5",
                                                        {
                                                          staticClass: "inline",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "invoice.option.priceAfterDiscount"
                                                              )
                                                            ) + ":"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "inline lg:ml-3 ml-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.currentBooking.receipt.discounted_price.toLocaleString()
                                                            ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t("SAR")
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vx-row w-full m-0 my-5 justify-center opacity-50",
                                          },
                                          [_c("hr", { staticClass: "w-3/4" })]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "vx-row w-full ml-1" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full mt-3",
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    "| " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.services.servicesPrice"
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full mt-2 flex flex-wrap",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "inline" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.services.beforeVat"
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "inline lg:ml-3 ml-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentBooking
                                                          .receipt
                                                          .internal_services_total_price_without_tax
                                                          ? _vm.currentBooking.receipt.internal_services_total_price_without_tax.toLocaleString()
                                                          : 0 + " "
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.$t("SAR"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full mt-2 flex flex-wrap",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "inline" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.services.vat"
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "inline lg:ml-3 ml-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentBooking
                                                          .receipt
                                                          .total_tax_of_internal_services
                                                          ? _vm.currentBooking.receipt.total_tax_of_internal_services.toLocaleString()
                                                          : 0 + " "
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.$t("SAR"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full mt-2 flex flex-wrap",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "inline" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.services.afterVat"
                                                        )
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "inline lg:ml-3 ml-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentBooking
                                                          .receipt
                                                          .services_total_price
                                                          ? _vm.currentBooking.receipt.services_total_price.toLocaleString()
                                                          : 0 + " "
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.$t("SAR"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-row w-full mt-10 p-2 w-full",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "vx-row m-0 w-full" },
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass: "my-3 opacity-75",
                                              },
                                              [
                                                _vm._v(
                                                  "| " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "addBookingSidebar.exportedReceipts"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid grid-cols-3 gab-4",
                                          },
                                          _vm._l(
                                            _vm.receiptDocuments,
                                            function (doc) {
                                              return _c(
                                                "div",
                                                {
                                                  key: doc.id,
                                                  staticClass:
                                                    "flex p-4 m-2 rounded-lg justify-center items-center vx-card",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    { staticClass: "m-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(doc.created_at)
                                                      ),
                                                    ]
                                                  ),
                                                  _c("vs-button", {
                                                    staticClass: "p-2",
                                                    staticStyle: {
                                                      background:
                                                        "#673ab7 !important",
                                                    },
                                                    attrs: {
                                                      "icon-pack": "feather",
                                                      icon: "icon-eye",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showReceiptDocButtonClicked(
                                                          doc.booking_copy
                                                            .booking_number,
                                                          doc.id
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "vs-prompt",
                {
                  staticClass: "export-options",
                  attrs: {
                    color: "danger",
                    title: _vm.$t("addBookingSidebar.cancelBooking"),
                    "buttons-hidden": true,
                    active: _vm.canceledPromptActive,
                  },
                  on: {
                    "update:active": function ($event) {
                      _vm.canceledPromptActive = $event
                    },
                  },
                },
                [
                  _vm.currentBooking.receipt.paid_amount > 0
                    ? _c("div", [
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("bookingPreview.refundAmountNote", {
                                  amount:
                                    _vm.currentBooking.receipt.paid_amount,
                                })
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex flex-col space-y-3 my-3" },
                          [
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  color: "primary",
                                  "vs-name": "cancelPromptRefundSelection",
                                  "vs-value": "without-refund",
                                },
                                model: {
                                  value: _vm.cancelPromptRefundSelection,
                                  callback: function ($$v) {
                                    _vm.cancelPromptRefundSelection = $$v
                                  },
                                  expression: "cancelPromptRefundSelection",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("bookingPreview.cancelWithoutRefund")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  color: "primary",
                                  "vs-name": "cancelPromptRefundSelection",
                                  "vs-value": "with-refund",
                                },
                                model: {
                                  value: _vm.cancelPromptRefundSelection,
                                  callback: function ($$v) {
                                    _vm.cancelPromptRefundSelection = $$v
                                  },
                                  expression: "cancelPromptRefundSelection",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("bookingPreview.cancelWithRefund")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.cancelPromptRefundSelection == "without-refund"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "my-3",
                                    attrs: {
                                      color: "warning",
                                      icon: "new_releases",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "bookingPreview.cancelationNotificationNote"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex justify-end" },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        attrs: { color: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelBooking(
                                              "canceled_without_refund"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "bookingPreview.cancelWithoutRefund"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  { staticClass: "my-2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-row mx-1 mb-2 required",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "v-select-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "bookingPreview.refundAmountFancy"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      model: {
                                        value: _vm.refundAmount,
                                        callback: function ($$v) {
                                          _vm.refundAmount = $$v
                                        },
                                        expression: "refundAmount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "my-3",
                                    attrs: {
                                      color: "warning",
                                      icon: "new_releases",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "bookingPreview.cancelationNotificationNote"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex justify-end" },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        attrs: { color: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelBooking(
                                              "canceled_with_refund"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "bookingPreview.cancelWithRefund"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ])
                    : _c(
                        "div",
                        [
                          _c(
                            "vs-alert",
                            {
                              staticClass: "my-3",
                              attrs: { color: "warning", icon: "new_releases" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "bookingPreview.cancelationNotificationNote"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex justify-end" },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: { color: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelBooking("cancelled")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("bookingPreview.cancelBooking")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }