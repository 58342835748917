var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "simple-calendar-app" } },
    [
      _c("booking-preview", {
        ref: "preview",
        attrs: {
          bookingPreviewActive: _vm.bookingPreviewActive,
          currentBooking: _vm.currentBooking,
        },
        on: {
          "update:bookingPreviewActive": function ($event) {
            _vm.bookingPreviewActive = $event
          },
          "update:booking-preview-active": function ($event) {
            _vm.bookingPreviewActive = $event
          },
          "update:currentBooking": function ($event) {
            _vm.currentBooking = $event
          },
          "update:current-booking": function ($event) {
            _vm.currentBooking = $event
          },
          updateCurrentBooking: function ($event) {
            _vm.currentBooking = $event
          },
          openEditBookingPopUp: _vm.editData,
        },
      }),
      _c("booking-form-pop-up", {
        attrs: {
          bookingFormPopUpActive: _vm.bookingFormPopUpActive,
          currentBooking: _vm.currentBooking,
          isEdit: _vm.isBookingEdit,
          startDate: _vm.startDate,
          endDate: _vm.endDate,
          enableBlockBooking: _vm.enableBlockBooking,
        },
        on: {
          "update:bookingFormPopUpActive": function ($event) {
            _vm.bookingFormPopUpActive = $event
          },
          "update:booking-form-pop-up-active": function ($event) {
            _vm.bookingFormPopUpActive = $event
          },
          "update:currentBooking": function ($event) {
            _vm.currentBooking = $event
          },
          "update:current-booking": function ($event) {
            _vm.currentBooking = $event
          },
          updateCurrentBooking: function ($event) {
            _vm.currentBooking = $event
          },
          "update:enableBlockBooking": function ($event) {
            _vm.enableBlockBooking = $event
          },
          "update:enable-block-booking": function ($event) {
            _vm.enableBlockBooking = $event
          },
        },
      }),
      _c("booking-confirmation", {
        attrs: {
          bookingConfirmationPopUp: _vm.bookingConfirmationPopUp,
          currentBooking: _vm.currentBooking,
          bookingConfirmationType: _vm.bookingConfirmationType,
        },
        on: {
          "update:bookingConfirmationPopUp": function ($event) {
            _vm.bookingConfirmationPopUp = $event
          },
          "update:booking-confirmation-pop-up": function ($event) {
            _vm.bookingConfirmationPopUp = $event
          },
          "update:currentBooking": function ($event) {
            _vm.currentBooking = $event
          },
          "update:current-booking": function ($event) {
            _vm.currentBooking = $event
          },
          updateCurrentBooking: function ($event) {
            _vm.currentBooking = $event
          },
          openPreview: function ($event) {
            _vm.bookingPreviewActive = true
          },
        },
      }),
      _c(
        "div",
        { staticClass: "vx-card no-scroll-content" },
        [
          _c(
            "calendar-view",
            {
              ref: "calendar",
              staticClass: "theme-default",
              attrs: {
                displayPeriodUom: _vm.calendarView,
                "show-date": _vm.showDate,
                events: _vm.calendarEvents,
                eventTop: _vm.windowWidth <= 400 ? "2rem" : "3rem",
                eventBorderHeight: "0px",
                eventContentHeight: "1.65rem",
              },
              on: {
                "click-date": _vm.addNewBookingData,
                "click-event": _vm.showData,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mb-4",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vx-row no-gutter",
                      attrs: { id: "calendar" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-1/3 items-center sm:flex hidden",
                        },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                "icon-pack": "feather",
                                icon: "icon-plus",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addNewBookingData()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Add")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex items-center" },
                            [
                              _c("feather-icon", {
                                staticClass:
                                  "cursor-pointer bg-primary text-white rounded-full",
                                attrs: {
                                  icon: _vm.$vs.rtl
                                    ? "ChevronRightIcon"
                                    : "ChevronLeftIcon",
                                  svgClasses: "w-5 h-5 m-1",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateMonth(-1)
                                  },
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "mx-3 text-xl font-medium whitespace-no-wrap",
                                },
                                [_vm._v(_vm._s(_vm._f("month")(_vm.showDate)))]
                              ),
                              _c("feather-icon", {
                                staticClass:
                                  "cursor-pointer bg-primary text-white rounded-full",
                                attrs: {
                                  icon: _vm.$vs.rtl
                                    ? "ChevronLeftIcon"
                                    : "ChevronRightIcon",
                                  svgClasses: "w-5 h-5 m-1",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateMonth(1)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col sm:w-1/3 w-full flex justify-center",
                        },
                        [
                          _vm._l(_vm.calendarViewTypes, function (view, index) {
                            return [
                              _vm.calendarView === view.val
                                ? _c(
                                    "vs-button",
                                    {
                                      key: String(view.val) + "filled",
                                      staticClass: "p-3 md:px-8 md:py-3",
                                      class: {
                                        "border-l-0 rounded-l-none": index,
                                        "rounded-r-none":
                                          _vm.calendarViewTypes.length !==
                                          index + 1,
                                      },
                                      attrs: { type: "filled" },
                                      on: {
                                        click: function ($event) {
                                          _vm.calendarView = view.val
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(view.label))]
                                  )
                                : _c(
                                    "vs-button",
                                    {
                                      key: String(view.val) + "border",
                                      staticClass: "p-3 md:px-8 md:py-3",
                                      class: {
                                        "border-l-0 rounded-l-none": index,
                                        "rounded-r-none":
                                          _vm.calendarViewTypes.length !==
                                          index + 1,
                                      },
                                      attrs: { type: "border" },
                                      on: {
                                        click: function ($event) {
                                          _vm.calendarView = view.val
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(view.label))]
                                  ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "vx-row sm:flex hidden mt-4" }, [
                    _c("div", { staticClass: "vx-col w-full flex" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap sm:justify-start justify-center",
                        },
                        [
                          _vm._l(_vm.bookingStates, function (state, index) {
                            return _c(
                              "div",
                              {
                                key: "state-" + state.name + "-" + index,
                                staticClass: "flex items-center mr-4 mb-2",
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "h-3 w-3 inline-block rounded-full mr-2",
                                  style: { background: state.color },
                                }),
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: state.description } },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(state.display_name)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "flex items-center mr-4 mb-2 mt-5",
                              attrs: { color: "red" },
                            },
                            [
                              _c(
                                "vx-tooltip",
                                {
                                  attrs: {
                                    text: "Booking added by Eventful operating team (admins)",
                                  },
                                },
                                [
                                  _c(
                                    "vs-chip",
                                    {
                                      staticClass:
                                        "product-no font-medium truncate mx-1",
                                      attrs: { color: "10163A" },
                                    },
                                    [_vm._v(" A| Admin ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "vx-tooltip",
                                {
                                  attrs: {
                                    text: "Booking added by a customer using Eventful app",
                                  },
                                },
                                [
                                  _c(
                                    "vs-chip",
                                    {
                                      staticClass:
                                        "product-no font-medium truncate mx-1",
                                      attrs: { color: "10163A" },
                                    },
                                    [_vm._v(" E| Eventful App ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "vx-tooltip",
                                {
                                  attrs: {
                                    text: "Booking Added By Agent User (You)",
                                  },
                                },
                                [
                                  _c(
                                    "vs-chip",
                                    {
                                      staticClass:
                                        "product-no font-medium truncate mx-1",
                                      attrs: { color: "10163A" },
                                    },
                                    [_vm._v(" Ag| Agent ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "vs-prompt",
            {
              staticClass: "export-options",
              attrs: {
                title: _vm.$t("calendar.bookingBlock"),
                color: "danger",
                "accept-text": _vm.$t("delete"),
                "cancel-text": _vm.$t("close"),
                active: _vm.bookingBlockPromptActive,
              },
              on: {
                accept: _vm.deleteBookingBlock,
                "update:active": function ($event) {
                  _vm.bookingBlockPromptActive = $event
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("calendar.doYouWantToRemoveBookingBlock"))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }