var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "holamundo",
          attrs: {
            title: _vm.$t("venueSettlements.settlementDetails"),
            active: _vm.isActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.isActive = $event
            },
            close: _vm.closePopup,
          },
        },
        [
          _vm.currentSettlement
            ? _c("div", [
                _c("div", { staticClass: "container mb-5" }, [
                  _c("div", { staticClass: "vx-row w-full sm:flex flex" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                      },
                      [
                        _c(
                          "h3",
                          { staticClass: "opacity-75 text-lg lg:text-2xl" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("venueSettlements.settlementAmount")
                              )
                            ),
                          ]
                        ),
                        _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentSettlement.total_amount
                                ? _vm.currentSettlement.total_amount.toLocaleString()
                                : 0 + " "
                            ) +
                              " " +
                              _vm._s(_vm.$t("SAR"))
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "flex space-x-2" }, [
                    _c("span", { staticClass: "text-lg text-black" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("venueSettlements.completed_bookings_count")
                        ) + ":"
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.currentSettlement.completed_bookings_count)
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex space-x-2" }, [
                    _c("span", { staticClass: "text-lg text-black" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("venueSettlements.total_completed_final_price")
                        ) + ":"
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.currentSettlement.total_completed_final_price
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex space-x-2" }, [
                    _c("span", { staticClass: "text-lg text-black" }, [
                      _vm._v(
                        _vm._s(_vm.$t("venueSettlements.total_paid_at_place")) +
                          ":"
                      ),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.currentSettlement.total_paid_at_place)),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex space-x-2" }, [
                    _c("span", { staticClass: "text-lg text-black" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("venueSettlements.total_paid_at_eventful")
                        ) + ":"
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.currentSettlement.total_paid_at_eventful)
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex space-x-2" }, [
                    _c("span", { staticClass: "text-lg text-black" }, [
                      _vm._v(
                        _vm._s(_vm.$t("venueSettlements.total_paid_amount")) +
                          ":"
                      ),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.currentSettlement.total_paid_amount)),
                    ]),
                  ]),
                ]),
                _c("span", { staticClass: "text-xl" }, [
                  _vm._v(
                    _vm._s(_vm.$t("venueSettlements.bookingsInSuttlement"))
                  ),
                ]),
                _vm.isLoaded
                  ? _c(
                      "div",
                      [
                        _c(
                          "vs-table",
                          {
                            ref: "involvedBookingsTable",
                            staticClass: "mb-5",
                            attrs: {
                              sst: true,
                              pagination: "",
                              total: parseInt(_vm.tableConfig.total),
                              "max-items": parseInt(_vm.tableConfig.perPage),
                              data: _vm.involvedBookings,
                            },
                            on: { "change-page": _vm.handleChangePage },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ data }) {
                                    return [
                                      _c(
                                        "tbody",
                                        [
                                          _vm._l(data, function (tr, indextr) {
                                            return _c(
                                              "vs-tr",
                                              {
                                                key: indextr,
                                                attrs: { data: tr },
                                              },
                                              [
                                                _c("vs-td", [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "product-name font-medium truncate",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          tr.booking_number
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("vs-td", [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "booking-date truncate",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .moment(
                                                              tr.created_at
                                                            )
                                                            .format(
                                                              "yyyy-MM-DD"
                                                            )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("vs-td", [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "booking-date truncate",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(tr.start_date)
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "vs-td",
                                                  [
                                                    _c(
                                                      "vs-chip",
                                                      {
                                                        attrs: {
                                                          color: tr.state.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            tr.state
                                                              .display_name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("vs-td", [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "booking-date truncate",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(tr.customer_name)
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("vs-td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tr.receipt
                                                          .local_final_price_without_discount
                                                          ? tr.receipt.local_final_price_without_discount.toLocaleString()
                                                          : 0
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("vs-td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tr.receipt
                                                          .total_paid_amount_at_qosoor
                                                          ? tr.receipt.total_paid_amount_at_qosoor.toLocaleString()
                                                          : 0
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("vs-td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tr.receipt
                                                          .paid_at_place_amount
                                                          ? tr.receipt.paid_at_place_amount.toLocaleString()
                                                          : 0
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("vs-td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tr.receipt.paid_amount
                                                          ? tr.receipt.paid_amount.toLocaleString()
                                                          : 0
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("vs-td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tr.receipt
                                                          .cost_of_goods_sold
                                                          ? tr.receipt.cost_of_goods_sold.toLocaleString()
                                                          : 0
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("vs-td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tr.receipt
                                                          .final_percentage_qosoor_commission
                                                      ) +
                                                      "% "
                                                  ),
                                                ]),
                                                _c("vs-td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tr.receipt
                                                          .settlement_amount
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          }),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3178092599
                            ),
                          },
                          [
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                [
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.bookingNumber"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.bookingDate"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.eventDate"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.state"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.customerName"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.totalPrice"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.paidViaEventful"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.paidAtPlace"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.totalPaidAmount"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.cogs"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.eventfulCommissionPercentage"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "table-head font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.booking.settlementAmount"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "w-full flex justify-center" },
                        [_c("Loading")],
                        1
                      ),
                    ]),
                _c(
                  "div",
                  { staticClass: "flex flex-wrap" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "mx-1",
                        attrs: { type: "border", color: "success" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.exportToExcel(_vm.currentSettlement)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("venueSettlements.exportBookings")) +
                            " "
                        ),
                      ]
                    ),
                    ["settled"].includes(_vm.currentSettlement.status)
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "mx-1",
                            attrs: { type: "border", color: "#6e6e6e" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.goToLink(
                                  _vm.currentSettlement
                                    .last_receipt_document_url
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("venueSettlements.viewReceipt")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }