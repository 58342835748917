var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-card",
        {
          staticClass: "w-full h-full",
          attrs: { actionable: _vm.pageName != null },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-col justify-between items-center h-full py-5",
              on: { click: _vm.cardClicked },
            },
            [
              _c("img", {
                staticClass: "w-1/4 mt-2",
                attrs: { src: _vm.image },
              }),
              _c(
                "span",
                {
                  class: `text-2xl font-bold text-center mt-5`,
                  style: `color: ${_vm.textColor};`,
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _c(
                "span",
                {
                  class: `text-2xl font-bold text-center mt-5`,
                  style: `color: ${_vm.subtitleColor};`,
                },
                [_vm._v(_vm._s(_vm.subtitle))]
              ),
              _c(
                "span",
                {
                  class: `text-lg font-light text-center mt-5 underline`,
                  style: `color: #02d3de;`,
                },
                [_vm._v(_vm._s(_vm.cta))]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }