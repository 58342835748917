var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: _vm.currentVenue.arabic_name } },
    [
      _c(
        "swiper",
        {
          key: _vm.$vs.rtl,
          attrs: {
            options: _vm.swiperOption,
            dir: _vm.$vs.rtl ? "rtl" : "ltr",
          },
        },
        [
          _vm._l(_vm.images, function (image) {
            return _c("swiper-slide", { key: image.id }, [
              _c("img", {
                staticClass: "responsive h-auto max-h-400",
                attrs: { src: _vm.baseURL + "/" + image.image, alt: "banner" },
              }),
            ])
          }),
          _c("div", {
            staticClass: "swiper-pagination swiper-pagination-white",
            attrs: { slot: "pagination" },
            slot: "pagination",
          }),
          _c("div", {
            staticClass: "swiper-button-prev swiper-button-white",
            attrs: { slot: "button-prev" },
            slot: "button-prev",
          }),
          _c("div", {
            staticClass: "swiper-button-next swiper-button-white",
            attrs: { slot: "button-next" },
            slot: "button-next",
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }