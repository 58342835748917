var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "vx-row mt-5 justify-center" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("onBoarding.thankyouHeader")))]),
    ]),
    _c("div", { staticClass: "vx-row mt-5 justify-center" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("onBoarding.thankyouLine1")))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row mt-10 justify-center" }, [
      _c("img", {
        staticClass: "w-full md:w-full lg:w-1/2 h-auto",
        attrs: {
          src: require("@/assets/images/QLoader.gif"),
          alt: "loading...",
          width: "200",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }