var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex w-full justify-end my-3" },
        [
          _c(
            "vs-button",
            { attrs: { icon: "add" }, on: { click: _vm.addBookingOption } },
            [_vm._v(_vm._s(_vm.$t("Add")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5" },
        _vm._l(_vm.bookingOptions, function (bookingOption) {
          return _c(
            "div",
            { key: bookingOption.id },
            [
              _c("vs-card", { attrs: { "fixed-height": "" } }, [
                _c("div", { attrs: { slot: "media" }, slot: "media" }, [
                  _c("img", {
                    staticClass: "object-cover h-48 rounded-t-lg",
                    attrs: {
                      src:
                        _vm.imagesURL +
                        "/" +
                        (bookingOption.images.length > 0
                          ? bookingOption.images[0]
                          : bookingOption.main_image),
                    },
                  }),
                ]),
                _c(
                  "div",
                  { attrs: { slot: "extra-content" }, slot: "extra-content" },
                  [
                    _c("div", { staticClass: "container" }, [
                      _c(
                        "div",
                        { staticClass: "vx-row justify-between px-2 pt-2" },
                        [
                          _c(
                            "div",
                            [
                              bookingOption.availability == "available"
                                ? _c(
                                    "vs-tooltip",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        text: "This booking option is available to the customers",
                                      },
                                    },
                                    [
                                      _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "home.editBookingOptionForm.bookingOptionAvailable"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "vs-tooltip",
                                    {
                                      attrs: {
                                        text: "his booking option is hidden from the customers",
                                      },
                                    },
                                    [
                                      _c(
                                        "vs-chip",
                                        { attrs: { color: "warning" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "home.editBookingOptionForm.bookingOptionHidden"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c(
                                "vs-dropdown",
                                {
                                  staticClass: "mx-1",
                                  attrs: { "vs-trigger-click": "" },
                                },
                                [
                                  _c("vs-button", {
                                    attrs: {
                                      color: "dark",
                                      type: "border",
                                      icon: "more_horiz",
                                    },
                                  }),
                                  _c(
                                    "vs-dropdown-menu",
                                    [
                                      bookingOption.has_seasonal_pricing
                                        ? _c(
                                            "vs-dropdown-item",
                                            [
                                              _c(
                                                "vs-button",
                                                {
                                                  staticClass: "px-2",
                                                  attrs: {
                                                    type: "flat",
                                                    color: "dark",
                                                    "icon-pack": "FA5",
                                                    icon: "fas fa-dollar-sign",
                                                    to: {
                                                      name: "custom-pricings",
                                                      params: {
                                                        option_id:
                                                          bookingOption.id,
                                                      },
                                                      hash: "#seasonalPricings",
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "home.seasonalPricings"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      bookingOption.has_days_pricing
                                        ? _c(
                                            "vs-dropdown-item",
                                            [
                                              _c(
                                                "vs-button",
                                                {
                                                  staticClass: "px-2",
                                                  attrs: {
                                                    type: "flat",
                                                    color: "dark",
                                                    "icon-pack": "FA5",
                                                    icon: "fas fa-dollar-sign",
                                                    to: {
                                                      name: "custom-pricings",
                                                      params: {
                                                        option_id:
                                                          bookingOption.id,
                                                      },
                                                      hash: "#daysPricings",
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "home.daysPricings"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "vs-dropdown-item",
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "px-2",
                                              attrs: {
                                                type: "flat",
                                                color: "dark",
                                                "icon-pack": "FA5",
                                                icon: "far fa-clock",
                                                to: {
                                                  name: "option-working-hours",
                                                  params: {
                                                    option_id: bookingOption.id,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("home.timeSlots"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-dropdown-item",
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "mt-2 px-2",
                                              attrs: {
                                                "icon-pack": "FA5",
                                                icon: "far fa-copy",
                                                type: "flat",
                                                color: "dark",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.duplicateBookingOption(
                                                    bookingOption
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "home.editBookingOptionForm.duplicateBookingOption"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-dropdown-item",
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "mt-2 px-2",
                                              attrs: {
                                                "icon-pack": "FA5",
                                                icon: "far fa-trash",
                                                type: "flat",
                                                color: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteBookingOptionPrompt(
                                                    bookingOption
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "home.editBookingOptionForm.deleteBookingOption"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("vs-button", {
                                attrs: {
                                  color: "primary",
                                  type: "border",
                                  icon: "edit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editBookingOption(bookingOption)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("hr"),
                    ]),
                    _c("div", {}, [
                      _c("div", { staticClass: "vx-row px-2 py-5" }, [
                        _c("div", { staticClass: "vx-col w-2/3" }, [
                          _c("span", [_vm._v(_vm._s(bookingOption.name))]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", { staticClass: "font-medium" }, [
                            _vm._v(_vm._s(bookingOption.description)),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col w-1/3" },
                          [
                            _c(
                              "vs-tooltip",
                              {
                                attrs: {
                                  text: _vm.$t(
                                    "home.editBookingOptionForm.taxApplied"
                                  ),
                                },
                              },
                              [
                                _c("small", { staticClass: "font-medium" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.thePrice"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      bookingOption.is_taxable_final
                                        ? Math.round(
                                            bookingOption.price * 1.15
                                          ).toLocaleString()
                                        : bookingOption.price.toLocaleString()
                                    ) +
                                      " " +
                                      _vm._s(_vm.$t("SAR"))
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    bookingOption.event_start_date_str ||
                    bookingOption.bookings_count_per_size_str
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "container mt-2 text-center flex-1 justify-center items-center",
                          },
                          [
                            _c("hr"),
                            _c("div", { staticClass: "px-2 mt-5" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    background: "#b3b3b3",
                                    "border-radius": "20px",
                                    color: "white",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        bookingOption.bookings_count_per_size_str
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            bookingOption.event_start_date_str
                              ? _c("div", { staticClass: "px-2 mt-5" }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "border-radius": "20px",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            bookingOption.event_start_date_str
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c("vs-pagination", {
        staticClass: "mt-5",
        attrs: { total: Math.ceil(_vm.tableConfig.totalPages) },
        on: { input: _vm.handleChangePage },
        model: {
          value: _vm.currentx,
          callback: function ($$v) {
            _vm.currentx = $$v
          },
          expression: "currentx",
        },
      }),
      _c("booking-option-form-popup", {
        attrs: {
          isBookingOptionFormActive: _vm.isBookingOptionFormActive,
          bookingOption: _vm.selectedBookingOption,
          actionType: _vm.actionType,
        },
        on: {
          "update:isBookingOptionFormActive": function ($event) {
            _vm.isBookingOptionFormActive = $event
          },
          "update:is-booking-option-form-active": function ($event) {
            _vm.isBookingOptionFormActive = $event
          },
        },
      }),
      _vm.selectedBookingOption
        ? _c(
            "vs-prompt",
            {
              attrs: {
                title: _vm.$t("home.editBookingOptionForm.delete.promptHeader"),
                color: "danger",
                "accept-text": _vm.$t(
                  "home.editBookingOptionForm.delete.accept"
                ),
                "cancel-text": _vm.$t(
                  "home.editBookingOptionForm.delete.cancel"
                ),
                active: _vm.deleteBookingOptionActive,
              },
              on: {
                accept: _vm.deleteBookingOption,
                cancel: function ($event) {
                  _vm.selectedBookingOption = null
                },
                close: function ($event) {
                  _vm.selectedBookingOption = null
                },
                "update:active": function ($event) {
                  _vm.deleteBookingOptionActive = $event
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("home.editBookingOptionForm.delete.message", {
                      bookingOptionName: _vm.selectedBookingOption.name,
                    })
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }