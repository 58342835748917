var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "dashboard-analytics" } }, [
    _c("div", { staticClass: "vx-row flex justify-between mx-3 mb-5" }, [
      _c("div", { staticClass: "content-area__heading" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$route.meta.pageTitle))]),
      ]),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full", attrs: { color: "red" } },
        [
          !!_vm.error
            ? _c(
                "vs-alert",
                { staticClass: "h-auto", attrs: { color: "danger" } },
                [_vm._v(" " + _vm._s(_vm.error) + " ")]
              )
            : _vm._e(),
          _c(
            "vx-card",
            { staticClass: "w-full mb-5 p-3 pb-0 mr-9" },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("home.BookingsNumbers")))]),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c("h6", [_vm._v(_vm._s(_vm.$t("home.BookingsNumbersDesc")))]),
              ]),
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row w-full mt-5 items-center" }, [
                _c("div", { staticClass: "vx-col w-full m-0 p-0" }, [
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          mode: "date",
                          "is-range": "",
                          color: "orange",
                          masks: _vm.dateFormat,
                          "min-date": _vm.minDate,
                          "max-date": _vm.maxDate,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({
                              inputValue,
                              inputEvents,
                              isDragging,
                            }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-col sm:flex-row justify-between items-center",
                                  },
                                  [
                                    _c(
                                      "vs-input",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "flex-grow py-1 bg-gray-100 border rounded w-full",
                                          class: isDragging
                                            ? "text-gray-600"
                                            : "text-gray-900",
                                          attrs: {
                                            label: _vm.$t(
                                              "dateRangePicker.from"
                                            ),
                                            value: inputValue.start,
                                            icon: "today",
                                          },
                                        },
                                        inputEvents.start
                                      )
                                    ),
                                    _c(
                                      "vs-input",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "flex-grow py-1 bg-gray-100 border rounded w-full",
                                          class: isDragging
                                            ? "text-gray-600"
                                            : "text-gray-900",
                                          attrs: {
                                            label: _vm.$t("dateRangePicker.to"),
                                            value: inputValue.end,
                                            icon: "event",
                                          },
                                        },
                                        inputEvents.end
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.bookingsDateRange,
                          callback: function ($$v) {
                            _vm.bookingsDateRange = $$v
                          },
                          expression: "bookingsDateRange",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-col w-full m-0 p-0" }, [
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("label", { staticClass: "v-select-label" }, [
                        _vm._v(_vm._s(_vm.$t("home.chooseBookingsStates"))),
                      ]),
                      _c("v-select", {
                        attrs: {
                          multiple: "",
                          options: _vm.bookingStates,
                          label: "display_name",
                          name: "display_name",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        model: {
                          value: _vm.selectedStates,
                          callback: function ($$v) {
                            _vm.selectedStates = $$v
                          },
                          expression: "selectedStates",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("date-buttons", { on: { dateClicked: _vm.dateClicked } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mb-5" },
        [
          _c("bookings-line-chart", {
            attrs: {
              bookingsDateRange: _vm.bookingsDateRange,
              selectedStates: _vm.selectedStates,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-base" },
        [
          _c("statistics-card-line", {
            staticClass: "h-full",
            attrs: {
              icon: "HashIcon",
              statistic: _vm.bookingAnalytics.bookingsCount,
              statisticTitle: _vm.$t("home.BookingsCount"),
              chartData: _vm.sales,
              type: "area",
              hideChart: "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-base" },
        [
          _c("statistics-card-line", {
            staticClass: "h-full",
            attrs: {
              icon: "DollarSignIcon",
              statistic: _vm.bookingAnalytics.totalPriceWithTax,
              statisticTitle: _vm.$t("home.totalPriceWithTax"),
              chartData: _vm.sales,
              type: "area",
              extraDescription: _vm.bookingAnalytics.totalPrice,
              hideChart: "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-base" },
        [
          _c("bookings-states-pie-chart", {
            attrs: {
              bookingsDateRange: _vm.bookingsDateRange,
              selectedStates: _vm.selectedStates,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }