var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "scaffold" } },
    [
      _c("vx-card", [
        _c("div", { staticClass: "container-fluid" }, [
          _c(
            "div",
            { staticClass: "row w-full" },
            [
              _c(
                "form-wizard",
                {
                  ref: "wizardForm",
                  attrs: {
                    color: "rgba(var(--vs-primary), 1)",
                    title: null,
                    subtitle: null,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            { staticClass: "container-fluid mt-5 mx-4" },
                            [
                              _c("div", { staticClass: "vx-row justify-end" }, [
                                _c(
                                  "div",
                                  { staticClass: "wizard-footer-left" },
                                  [
                                    props.activeTabIndex > 0
                                      ? _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: _vm.$t(
                                                "onBoarding.tooltip.previous"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "mx-5",
                                                attrs: { type: "border" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    props.prevTab()
                                                    _vm.trackCurrentTab()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "onBoarding.previous"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "wizard-footer-right" },
                                  [
                                    !props.isLastStep
                                      ? _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: _vm.$t(
                                                "onBoarding.tooltip.nextStep"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass:
                                                  "wizard-footer-right",
                                                nativeOn: {
                                                  click: function ($event) {
                                                    props.nextTab()
                                                    _vm.trackCurrentTab()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("onBoarding.next")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: _vm.$t(
                                                "onBoarding.tooltip.complete"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass:
                                                  "wizard-footer-right finish-button",
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.finishOnBoarding.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      props.isLastStep
                                                        ? _vm.$t(
                                                            "onBoarding.complete"
                                                          )
                                                        : _vm.$t(
                                                            "onBoarding.next"
                                                          )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                _vm._l(_vm.tabs, function (tab) {
                  return _c(
                    "tab-content",
                    {
                      key: tab.title,
                      ref: tab.ref,
                      refInFor: true,
                      attrs: {
                        title: tab.title,
                        icon: tab.icon,
                        "before-change": tab.beforeChange,
                      },
                    },
                    [
                      _c(tab.component, {
                        tag: "component",
                        on: { dataUpdated: tab.dataUpdated },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }