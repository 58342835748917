var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "holamundo",
          attrs: {
            title: _vm.isEdit
              ? _vm.bookingClone.booking_number
              : _vm.$t("addBookingSidebar.newBooking"),
            active: _vm.isActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.isActive = $event
            },
            close: _vm.closePopup,
          },
        },
        [
          !_vm.isEdit && _vm.enableBlockBooking
            ? _c(
                "div",
                { staticClass: "flex flex-wrap pt-10 justify-between" },
                [
                  _c("h5", { staticClass: "mt-2" }, [
                    _vm._v(_vm._s(_vm.$t("addBookingSidebar.blockBooking"))),
                  ]),
                  _c(
                    "vs-button",
                    {
                      attrs: { icon: "block", color: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.isBookingBlock = true
                          _vm.submitBookingForm()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("block")))]
                  ),
                  _c("vs-divider"),
                ],
                1
              )
            : _vm._e(),
          _vm.bookingClone
            ? _c(
                "div",
                { staticClass: "container w-full" },
                [
                  _c("customer-information-booking", {
                    attrs: { booking: _vm.bookingClone, isEdit: _vm.isEdit },
                    on: {
                      "update:booking": function ($event) {
                        _vm.bookingClone = $event
                      },
                      CustomEventInputChanged: function ($event) {
                        _vm.bookingClone = $event
                      },
                    },
                  }),
                  _c("booking-information", {
                    ref: "bookingInformation",
                    attrs: {
                      booking: _vm.bookingClone,
                      isEdit: _vm.isEdit,
                      propStartDate: _vm.startDate,
                      propEndDate: _vm.endDate,
                    },
                    on: {
                      "update:booking": function ($event) {
                        _vm.bookingClone = $event
                      },
                      CustomEventInputChanged:
                        _vm.dataChangedInBookingInformation,
                      servicesUpdated: _vm.updateSelectedServices,
                    },
                  }),
                  _c("receipts-component", {
                    ref: "receiptsRef",
                    attrs: {
                      receipt: _vm.bookingClone.receipt,
                      isEdit: _vm.isEdit,
                      currentBooking: _vm.bookingClone,
                      selectedServicesIds: _vm.selectedServicesIds,
                    },
                    on: {
                      "update:receipt": function ($event) {
                        return _vm.$set(_vm.bookingClone, "receipt", $event)
                      },
                      CustomEventInputChanged: _vm.updateReceiptInfo,
                      bookingCloneChanged: function ($event) {
                        _vm.bookingClone = $event
                      },
                    },
                  }),
                  !_vm.isEdit
                    ? _c("div", [
                        _c("div", [
                          _c("span", { staticClass: "text-lg" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("addBookingSidebar.theNewBookingState")
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex flex-wrap p-5 space-x-2" },
                          [
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  "vs-name": "newBookingStatusId",
                                  "vs-value": 4,
                                },
                                model: {
                                  value: _vm.newBookingStatusId,
                                  callback: function ($$v) {
                                    _vm.newBookingStatusId = $$v
                                  },
                                  expression: "newBookingStatusId",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("addBookingSidebar.confirmed"))
                                ),
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  "vs-name": "newBookingStatusId",
                                  "vs-value": 14,
                                },
                                model: {
                                  value: _vm.newBookingStatusId,
                                  callback: function ($$v) {
                                    _vm.newBookingStatusId = $$v
                                  },
                                  expression: "newBookingStatusId",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("addBookingSidebar.completed"))
                                ),
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  "vs-name": "newBookingStatusId",
                                  "vs-value": 20,
                                },
                                model: {
                                  value: _vm.newBookingStatusId,
                                  callback: function ($$v) {
                                    _vm.newBookingStatusId = $$v
                                  },
                                  expression: "newBookingStatusId",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "addBookingSidebar.cancelledWithRefund"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  "vs-name": "newBookingStatusId",
                                  "vs-value": 21,
                                },
                                model: {
                                  value: _vm.newBookingStatusId,
                                  callback: function ($$v) {
                                    _vm.newBookingStatusId = $$v
                                  },
                                  expression: "newBookingStatusId",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "addBookingSidebar.cancelledWithoutRefund"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.newBookingStatusId == 20 ||
                        _vm.newBookingStatusId == 21
                          ? _c(
                              "div",
                              [
                                _c("vs-textarea", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: _vm.$t(
                                      "addBookingSidebar.cancelationReason"
                                    ),
                                    height: "90px",
                                  },
                                  model: {
                                    value: _vm.bookingClone.cancelation_reason,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.bookingClone,
                                        "cancelation_reason",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "bookingClone.cancelation_reason",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "my-10 pb-10" },
                    [
                      !_vm.isEdit
                        ? _c(
                            "vs-button",
                            {
                              attrs: { icon: "done" },
                              on: { click: _vm.submitBookingForm },
                            },
                            [_vm._v(_vm._s(_vm.$t("Add")))]
                          )
                        : _c(
                            "vs-button",
                            {
                              attrs: { icon: "edit" },
                              on: { click: _vm.submitBookingForm },
                            },
                            [_vm._v(_vm._s(_vm.$t("edit")))]
                          ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }