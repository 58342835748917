var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "holamundo",
          attrs: {
            title: _vm.$t("venueReviews.bookingDetails"),
            active: _vm.isActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.isActive = $event
            },
            close: _vm.closePopup,
          },
        },
        [
          _vm.currentReview
            ? _c("div", [
                _c("div", { staticClass: "grid grid-cols-5 gap-4" }, [
                  _c("div", { staticClass: "col-span-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("venueReviews.addedAt")) + " "),
                  ]),
                  _c("div", { staticClass: "col-span-4" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatReviewDate(_vm.currentReview.created_at)
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "col-span-1" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("venueReviews.bookingRating")) + ": "
                    ),
                  ]),
                  _c("div", { staticClass: "col-span-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      _vm._l(5, function (index) {
                        return _c("span", [
                          index <= _vm.currentReview.rating
                            ? _c(
                                "svg",
                                {
                                  staticClass: "h-6 w-6 star-yellow",
                                  attrs: {
                                    "aria-hidden": "true",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "currentColor",
                                    viewBox: "0 0 22 20",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z",
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "svg",
                                {
                                  staticClass: "h-6 w-6 star-grey",
                                  attrs: {
                                    "aria-hidden": "true",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "currentColor",
                                    viewBox: "0 0 22 20",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z",
                                    },
                                  }),
                                ]
                              ),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "col-span-1" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("venueReviews.bookingReview")) + ": "
                    ),
                  ]),
                  _c("div", { staticClass: "col-span-4" }, [
                    _vm._v(" " + _vm._s(_vm.currentReview.review) + " "),
                  ]),
                  _c("div", { staticClass: "col-span-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("venueReviews.status")) + ": "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-span-4" },
                    [
                      _vm.currentReview.status === "visible_to_agent"
                        ? _c(
                            "vs-chip",
                            { attrs: { transparent: "", color: "primary" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("venueReviews.visibleToAgent")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm.currentReview.status === "visible_to_customers"
                        ? _c(
                            "vs-chip",
                            { attrs: { transparent: "", color: "success" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("venueReviews.visibleToCustomers")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-span-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("venueReviews.image")) + ": "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-span-4 gap-4 grid grid-cols-1 lg:grid-cols-2",
                    },
                    _vm._l(_vm.currentReview.images, function (image, index) {
                      return _c("img", {
                        key: `img-${index}`,
                        staticClass: "w-64 self-center rounded-lg",
                        attrs: { src: `${_vm.baseURL}/${image}`, alt: "Image" },
                      })
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }