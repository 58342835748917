var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center w-full h-screen img bg-secondary-500 loading",
        attrs: { id: "loading" },
      },
      [
        _c("img", {
          staticClass: "w-full md:w-2/5 lg:w-1/5 h-auto",
          staticStyle: { "max-width": "100px" },
          attrs: {
            src: require("@/assets/images/eventful-loader.gif"),
            alt: "loading...",
            width: "50",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }