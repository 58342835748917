var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentService != null
    ? _c(
        "div",
        [
          _c(
            "vs-popup",
            {
              staticClass: "holamundo",
              attrs: {
                title: !_vm.isEdit
                  ? _vm.$t("servicesForm.addService")
                  : _vm.$t("servicesForm.editService") +
                    " | " +
                    _vm.currentService.name,
                active: _vm.isActive,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isActive = $event
                },
                close: _vm.closePopup,
              },
            },
            [
              _c("div", { staticClass: "container w-full" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap" },
                  [
                    _c("h4", { staticClass: "my-3 opacity-75 w-full" }, [
                      _vm._v(_vm._s(_vm.$t("servicesForm.serviceImages"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2",
                      },
                      [
                        _c("vue-upload-multiple-image", {
                          attrs: {
                            idUpload: "services-upload",
                            showEdit: false,
                            popupText: _vm.$t(
                              "VueUploadMultipleImages.popupText"
                            ),
                            primaryText: _vm.$t(
                              "VueUploadMultipleImages.primaryText"
                            ),
                            markIsPrimaryText: _vm.$t(
                              "VueUploadMultipleImages.markIsPrimaryText"
                            ),
                            browseText: _vm.$t(
                              "VueUploadMultipleImages.browseText"
                            ),
                            dragText: "",
                            "data-images": _vm.images,
                          },
                          on: {
                            "upload-success": _vm.uploadImageSuccess,
                            "before-remove": _vm.beforeRemove,
                            "mark-is-primary": _vm.primaryMarked,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "vx-col w-full md:mb-2 pl-2 mt-2 required",
                      },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("servicesForm.tooltip.name"),
                            },
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: _vm.$t("servicesForm.name") },
                              model: {
                                value: _vm.currentService.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.currentService, "name", $$v)
                                },
                                expression: "currentService.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:mb-2 pl-2 mt-2" },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("servicesForm.tooltip.description"),
                            },
                          },
                          [
                            _c("vs-textarea", {
                              staticClass: "w-full",
                              attrs: {
                                label: _vm.$t("servicesForm.description"),
                                height: "150px",
                              },
                              model: {
                                value: _vm.currentService.description,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentService,
                                    "description",
                                    $$v
                                  )
                                },
                                expression: "currentService.description",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full lg:w-1/2 mb-2 pl-4" },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("servicesForm.tooltip.pricingType"),
                            },
                          },
                          [
                            _c("div", { staticClass: "container mt-2" }, [
                              _c("div", { staticClass: "vx-row required" }, [
                                _c("label", { staticClass: "v-select-label" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("servicesForm.choosePricingType")
                                    )
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "vx-row mt-3" },
                                [
                                  _c(
                                    "vs-radio",
                                    {
                                      staticClass: "mr-6",
                                      attrs: {
                                        "vs-name":
                                          "currentService.pricing_type",
                                        "vs-value": "fixed",
                                      },
                                      model: {
                                        value: _vm.currentService.pricing_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentService,
                                            "pricing_type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "currentService.pricing_type",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "home.editBookingOptionForm.selectText.fixed"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-radio",
                                    {
                                      staticClass: "mr-6",
                                      attrs: {
                                        "vs-name":
                                          "currentService.pricing_type",
                                        "vs-value": "variable",
                                      },
                                      model: {
                                        value: _vm.currentService.pricing_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentService,
                                            "pricing_type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "currentService.pricing_type",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "home.editBookingOptionForm.selectText.per_person"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.currentService.pricing_type == "fixed"
                      ? _c(
                          "div",
                          { staticClass: "vx-col w-full md:mb-2 pl-2 mt-2" },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: _vm.$t(
                                    "servicesForm.tooltip.fixedPrice"
                                  ),
                                },
                              },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    type: "number",
                                    label: _vm.$t("servicesForm.fixedPrice"),
                                  },
                                  model: {
                                    value: _vm.currentService.fixed_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.currentService,
                                        "fixed_price",
                                        $$v
                                      )
                                    },
                                    expression: "currentService.fixed_price",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.currentService.pricing_type == "variable"
                      ? _c(
                          "vs-tabs",
                          [
                            _c(
                              "vs-tab",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "servicesForm.groupPricing.primaryPrices"
                                  ),
                                },
                              },
                              [
                                _c("div", { staticClass: "con-tab-ejemplo" }, [
                                  _c("div", { staticClass: "vx-row mb-5" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col w-full md:mb-2 pl-2 mt-2",
                                      },
                                      [
                                        _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: _vm.$t(
                                                "servicesForm.tooltip.manPrice"
                                              ),
                                            },
                                          },
                                          [
                                            _c("vs-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                type: "number",
                                                label: _vm.$t(
                                                  "servicesForm.manPrice"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.currentService
                                                    .first_input_price,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentService,
                                                    "first_input_price",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentService.first_input_price",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col w-full md:mb-2 pl-2 mt-2",
                                      },
                                      [
                                        _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: _vm.$t(
                                                "servicesForm.tooltip.womanPrice"
                                              ),
                                            },
                                          },
                                          [
                                            _c("vs-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                type: "number",
                                                label: _vm.$t(
                                                  "servicesForm.womanPrice"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.currentService
                                                    .second_input_price,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentService,
                                                    "second_input_price",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentService.second_input_price",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "vs-tab",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "servicesForm.groupPricing.groupPrices"
                                  ),
                                },
                              },
                              [
                                _c("div", { staticClass: "con-tab-ejemplo" }, [
                                  _c(
                                    "div",
                                    { staticClass: "vx-row mb-5" },
                                    [
                                      _c(
                                        "vs-alert",
                                        {
                                          staticClass: "h-full mb-5 py-2 pr-2",
                                          attrs: {
                                            color: "warning",
                                            icon: "sms",
                                            active: _vm.active1,
                                            closable: "",
                                            "close-icon": "close",
                                          },
                                          on: {
                                            "update:active": function ($event) {
                                              _vm.active1 = $event
                                            },
                                          },
                                        },
                                        [
                                          _c("h5", { staticClass: "mb-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "servicesForm.groupPricing.alert.header"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "servicesForm.groupPricing.alert.line1"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "servicesForm.groupPricing.alert.line2"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "vx-row h-full" },
                                    [
                                      _vm._l(
                                        _vm.currentService.custom_prices,
                                        function (customPricing, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass:
                                                "mt-2 w-full flex justify-between",
                                            },
                                            [
                                              _c("vs-input", {
                                                staticClass: "pl-2",
                                                attrs: {
                                                  type: "number",
                                                  label: _vm.$t(
                                                    "servicesForm.groupPricing.menCount"
                                                  ),
                                                  autocomplete: "off",
                                                },
                                                model: {
                                                  value:
                                                    customPricing.men_count,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      customPricing,
                                                      "men_count",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "customPricing.men_count",
                                                },
                                              }),
                                              _c("vs-input", {
                                                staticClass: "pl-1",
                                                attrs: {
                                                  type: "number",
                                                  label: _vm.$t(
                                                    "servicesForm.groupPricing.manPrice"
                                                  ),
                                                  autocomplete: "off",
                                                },
                                                model: {
                                                  value:
                                                    customPricing.first_input_price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      customPricing,
                                                      "first_input_price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "customPricing.first_input_price",
                                                },
                                              }),
                                              _c("vs-input", {
                                                staticClass: "pl-1",
                                                attrs: {
                                                  type: "number",
                                                  label: _vm.$t(
                                                    "servicesForm.groupPricing.womenCount"
                                                  ),
                                                  autocomplete: "off",
                                                },
                                                model: {
                                                  value:
                                                    customPricing.women_count,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      customPricing,
                                                      "women_count",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "customPricing.women_count",
                                                },
                                              }),
                                              _c("vs-input", {
                                                staticClass: "pl-1",
                                                attrs: {
                                                  type: "number",
                                                  label: _vm.$t(
                                                    "servicesForm.groupPricing.womanPrice"
                                                  ),
                                                  autocomplete: "off",
                                                },
                                                model: {
                                                  value:
                                                    customPricing.second_input_price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      customPricing,
                                                      "second_input_price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "customPricing.second_input_price",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "mt-1 px-2" },
                                                [
                                                  _c("vs-button", {
                                                    staticClass: "m-0 mt-5",
                                                    attrs: {
                                                      color: "danger",
                                                      icon: "delete",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeRow(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mt-2 w-full flex justify-end",
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "m-0 mt-5 mr-2",
                                              attrs: {
                                                color: "success",
                                                icon: "add",
                                              },
                                              on: { click: _vm.addRow },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "servicesForm.groupPricing.addRow"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "vx-col w-full pl-4" }, [
                      _c("div", { staticClass: "container mt-2" }, [
                        _c("div", { staticClass: "vx-row" }, [
                          _c("div", { staticClass: "vx-col w-full lg:w-1/2" }, [
                            _c("div", { staticClass: "vx-row mt-2 required" }, [
                              _c("label", { staticClass: "v-select-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.taxingStatus"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "vx-row mt-1" },
                              [
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-6 mt-2",
                                    attrs: {
                                      "vs-name":
                                        "currentService.percentage_tax",
                                      "vs-value": 1,
                                    },
                                    model: {
                                      value: _vm.currentService.percentage_tax,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentService,
                                          "percentage_tax",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentService.percentage_tax",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.taxable"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-2 mt-2",
                                    attrs: {
                                      "vs-name":
                                        "currentService.percentage_tax",
                                      "vs-value": 0,
                                    },
                                    model: {
                                      value: _vm.currentService.percentage_tax,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentService,
                                          "percentage_tax",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentService.percentage_tax",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.nonTaxable"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm.currentService.percentage_tax
                            ? _c(
                                "div",
                                { staticClass: "vx-col w-full lg:w-1/2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "vx-row mt-2 required" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "v-select-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.editBookingOptionForm.taxingIncluding"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "vx-row mt-3" },
                                    [
                                      _c(
                                        "vs-radio",
                                        {
                                          staticClass: "mr-6 mt-1 lg:mt-0",
                                          attrs: {
                                            "vs-name": "tax_included",
                                            "vs-value": "included",
                                          },
                                          model: {
                                            value: _vm.tax_included,
                                            callback: function ($$v) {
                                              _vm.tax_included = $$v
                                            },
                                            expression: "tax_included",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.editBookingOptionForm.taxIncluded.included"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "vs-radio",
                                        {
                                          staticClass: "mr-2 mt-2 lg:mt-0",
                                          attrs: {
                                            "vs-name": "tax_included",
                                            "vs-value": "not-included",
                                          },
                                          model: {
                                            value: _vm.tax_included,
                                            callback: function ($$v) {
                                              _vm.tax_included = $$v
                                            },
                                            expression: "tax_included",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.editBookingOptionForm.taxIncluded.notIncluded"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full my-3 opacity-50" },
                      [_c("hr")]
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full mt-3" },
                      [
                        _c(
                          "vs-table",
                          { attrs: { data: _vm.eventTypesList } },
                          [
                            _c("template", { slot: "header" }, [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.finalPricesTable.header"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.finalPricesTable.pricing"
                                      )
                                    )
                                  ),
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.finalPricesTable.beforeTax"
                                      )
                                    )
                                  ),
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.finalPricesTable.tax"
                                      )
                                    )
                                  ),
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.finalPricesTable.totalPrice"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            [
                              _vm.currentService.pricing_type == "fixed"
                                ? _c(
                                    "vs-tr",
                                    [
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.finalPricesTable.fixedPrice"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.finalPrices.fixedPrices
                                              .beforeTax
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]),
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.finalPrices.fixedPrices.tax
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]),
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.finalPrices.fixedPrices
                                              .finalPrice
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.currentService.pricing_type != "fixed"
                                ? _c(
                                    "vs-tr",
                                    [
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.finalPricesTable.manPrice"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.finalPrices.variablePrices
                                              .manPrice.beforeTax
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]),
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.finalPrices.variablePrices
                                              .manPrice.tax
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]),
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.finalPrices.variablePrices
                                              .manPrice.finalPrice
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.currentService.pricing_type != "fixed"
                                ? _c(
                                    "vs-tr",
                                    [
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.finalPricesTable.womanPrice"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.finalPrices.variablePrices
                                              .womanPrice.beforeTax
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]),
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.finalPrices.variablePrices
                                              .womanPrice.tax
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]),
                                      _c("vs-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.finalPrices.variablePrices
                                              .womanPrice.finalPrice
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "vx-col w-full md:mb-2 pl-2 mt-2 required",
                      },
                      [
                        _c("label", { staticClass: "v-select-label" }, [
                          _vm._v(
                            _vm._s(_vm.$t("servicesForm.addBookingOptions"))
                          ),
                        ]),
                        _c("v-select", {
                          staticClass: "v-select-background",
                          attrs: {
                            multiple: "",
                            options: _vm.bookingOptionsList,
                            label: "name",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          model: {
                            value: _vm.selectedBookingOptions,
                            callback: function ($$v) {
                              _vm.selectedBookingOptions = $$v
                            },
                            expression: "selectedBookingOptions",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:mb-2 pl-2 mt-4" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mx-2",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-check",
                            },
                            on: { click: _vm.submitForm },
                          },
                          [_vm._v(_vm._s(_vm.$t("servicesForm.save")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }