var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.selectedBookingOption
      ? _c(
          "div",
          [
            _c(
              "vs-popup",
              {
                staticClass: "holamundo",
                attrs: {
                  title:
                    _vm.actionType == "addBookingOption"
                      ? _vm.$t("home.editBookingOptionForm.addBookingOption")
                      : _vm.actionType == "editBookingOption"
                      ? _vm.$t("home.editBookingOptionForm.editBookingOption") +
                        " | " +
                        _vm.selectedBookingOption.name
                      : _vm.$t(
                          "home.editBookingOptionForm.duplicateBookingOption"
                        ) +
                        " | " +
                        _vm.selectedBookingOption.name,
                  active: _vm.isActive,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.isActive = $event
                  },
                  close: _vm.closePopup,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "grid lg:grid-cols-2 gap-5 pt-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("vue-upload-multiple-image", {
                          attrs: {
                            idUpload: _vm.imagesUploadId,
                            showEdit: false,
                            popupText: _vm.$t(
                              "VueUploadMultipleImages.popupText"
                            ),
                            primaryText: _vm.$t(
                              "VueUploadMultipleImages.primaryText"
                            ),
                            markIsPrimaryText: _vm.$t(
                              "VueUploadMultipleImages.markIsPrimaryText"
                            ),
                            browseText: _vm.$t(
                              "VueUploadMultipleImages.browseText"
                            ),
                            dragText: "",
                            maxImage: 20,
                            "data-images": _vm.images,
                          },
                          on: {
                            "upload-success": _vm.uploadImageSuccess,
                            "before-remove": _vm.beforeRemove,
                            "mark-is-primary": _vm.primaryMarked,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("vs-input", {
                      staticClass: "w-full required col-span-2 lg:col-span-1",
                      attrs: {
                        label: _vm.$t("home.editBookingOptionForm.name"),
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.selectedBookingOption.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedBookingOption, "name", $$v)
                        },
                        expression: "selectedBookingOption.name",
                      },
                    }),
                    _c("vs-input", {
                      staticClass: "w-full col-span-2 lg:col-span-1",
                      attrs: {
                        label: _vm.$t("home.editBookingOptionForm.description"),
                      },
                      model: {
                        value: _vm.selectedBookingOption.description,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.selectedBookingOption,
                            "description",
                            $$v
                          )
                        },
                        expression: "selectedBookingOption.description",
                      },
                    }),
                    _c("vs-textarea", {
                      staticClass: "w-full col-span-2",
                      attrs: {
                        label: _vm.$t(
                          "home.editBookingOptionForm.detailedDescription"
                        ),
                        height: "150px",
                      },
                      model: {
                        value: _vm.selectedBookingOption.detailed_description,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.selectedBookingOption,
                            "detailed_description",
                            $$v
                          )
                        },
                        expression:
                          "selectedBookingOption.detailed_description",
                      },
                    }),
                    _c("div", { staticClass: "col-span-2" }, [
                      _c("span", { staticClass: "text-md" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "home.editBookingOptionForm.minMaxCountTitle"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse",
                        },
                        [
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name":
                                  "selectedBookingOption.has_min_max_men_women_count",
                                "vs-value": false,
                              },
                              model: {
                                value:
                                  _vm.selectedBookingOption
                                    .has_min_max_men_women_count,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "has_min_max_men_women_count",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.has_min_max_men_women_count",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.selectText.no"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name":
                                  "selectedBookingOption.has_min_max_men_women_count",
                                "vs-value": true,
                              },
                              model: {
                                value:
                                  _vm.selectedBookingOption
                                    .has_min_max_men_women_count,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "has_min_max_men_women_count",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.has_min_max_men_women_count",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.selectText.yes"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.selectedBookingOption.has_min_max_men_women_count
                      ? _c(
                          "div",
                          {
                            staticClass: "col-span-2 grid lg:grid-cols-2 gap-3",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full col-span-2 lg:col-span-1",
                              attrs: {
                                type: "number",
                                label: _vm.$t(
                                  "home.editBookingOptionForm.minimumMenCount"
                                ),
                              },
                              model: {
                                value: _vm.selectedBookingOption.men_count_min,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "men_count_min",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.men_count_min",
                              },
                            }),
                            _c("vs-input", {
                              staticClass: "w-full col-span-2 lg:col-span-1",
                              attrs: {
                                type: "number",
                                label: _vm.$t(
                                  "home.editBookingOptionForm.maximumMenCount"
                                ),
                              },
                              model: {
                                value: _vm.selectedBookingOption.men_count_max,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "men_count_max",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.men_count_max",
                              },
                            }),
                            _c("vs-input", {
                              staticClass: "w-full col-span-2 lg:col-span-1",
                              attrs: {
                                type: "number",
                                label: _vm.$t(
                                  "home.editBookingOptionForm.minimumWomenCount"
                                ),
                              },
                              model: {
                                value:
                                  _vm.selectedBookingOption.women_count_min,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "women_count_min",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.women_count_min",
                              },
                            }),
                            _c("vs-input", {
                              staticClass: "w-full col-span-2 lg:col-span-1",
                              attrs: {
                                type: "number",
                                label: _vm.$t(
                                  "home.editBookingOptionForm.maximumWomenCount"
                                ),
                              },
                              model: {
                                value:
                                  _vm.selectedBookingOption.women_count_max,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "women_count_max",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.women_count_max",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "col-span-2 lg:col-span-1" }, [
                      _c("span", { staticClass: "text-md" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("home.editBookingOptionForm.availability")
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse",
                        },
                        [
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name": "selectedBookingOption.availability",
                                "vs-value": "available",
                              },
                              model: {
                                value: _vm.selectedBookingOption.availability,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "availability",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.availability",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.selectText.available"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name": "selectedBookingOption.availability",
                                "vs-value": "hidden",
                              },
                              model: {
                                value: _vm.selectedBookingOption.availability,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "availability",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.availability",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.selectText.hidden"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-span-2 lg:col-span-1" }, [
                      _c("span", { staticClass: "text-md" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "home.editBookingOptionForm.bookingOptionType"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse",
                        },
                        [
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name":
                                  "selectedBookingOption.availability_type",
                                "vs-value": "size_based",
                              },
                              model: {
                                value:
                                  _vm.selectedBookingOption.availability_type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "availability_type",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.availability_type",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("home.editBookingOptionForm.sizeBased")
                                )
                              ),
                            ]
                          ),
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name":
                                  "selectedBookingOption.availability_type",
                                "vs-value": "rooms_based",
                              },
                              model: {
                                value:
                                  _vm.selectedBookingOption.availability_type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedBookingOption,
                                    "availability_type",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedBookingOption.availability_type",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.roomsBased"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.actionType == "duplicateBookingOption"
                      ? _c(
                          "div",
                          {
                            staticClass: "col-span-2 grid lg:grid-cols-2 gap-3",
                          },
                          [
                            _vm.agentVenues.length > 0
                              ? _c("div", { staticClass: "col-span-2" }, [
                                  _c("span", { staticClass: "text-md" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.branches"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(_vm.agentVenues, function (venue) {
                                      return _c(
                                        "li",
                                        {
                                          key: venue.id,
                                          staticClass: "mt-2 mr-6 mb-5",
                                        },
                                        [
                                          _c(
                                            "vs-checkbox",
                                            {
                                              attrs: { "vs-value": venue.id },
                                              model: {
                                                value: _vm.selectedBranches,
                                                callback: function ($$v) {
                                                  _vm.selectedBranches = $$v
                                                },
                                                expression: "selectedBranches",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(venue.name) +
                                                  " " +
                                                  _vm._s(
                                                    venue.id ==
                                                      _vm.currentVenueId
                                                      ? "(الفرع الحالي)"
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          ),
                                          _vm.selectedBookingOption
                                            .availability_type ==
                                            "rooms_based" &&
                                          _vm.selectedBranches.includes(
                                            venue.id
                                          )
                                            ? _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "flex flex-wrap mt-2 ml-5",
                                                },
                                                _vm._l(
                                                  venue.rooms,
                                                  function (room) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: room.id,
                                                        staticClass:
                                                          "mt-2 mr-5",
                                                      },
                                                      [
                                                        _c(
                                                          "vs-checkbox",
                                                          {
                                                            attrs: {
                                                              "vs-value":
                                                                room.id,
                                                            },
                                                            model: {
                                                              value:
                                                                venue.selectedRooms,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    venue,
                                                                    "selectedRooms",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "venue.selectedRooms",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(room.name)
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.selectedBookingOption
                                            .availability_type ==
                                            "size_based" &&
                                          _vm.selectedBranches.includes(
                                            venue.id
                                          )
                                            ? _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "flex flex-wrap mt-2 ml-5",
                                                },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass: "mt-2 mr-5",
                                                    },
                                                    [
                                                      _c("vs-input", {
                                                        staticClass: "w-full",
                                                        attrs: {
                                                          type: "number",
                                                          label:
                                                            _vm.$t(
                                                              "home.editBookingOptionForm.sizeNote"
                                                            ) + ":",
                                                        },
                                                        model: {
                                                          value:
                                                            venue.bookings_size,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              venue,
                                                              "bookings_size",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "venue.bookings_size",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "col-span-2 grid lg:grid-cols-2 gap-3",
                          },
                          [
                            _vm.selectedBookingOption.availability_type ==
                            "size_based"
                              ? _c("vs-input", {
                                  staticClass:
                                    "w-full col-span-2 lg:col-span-1",
                                  attrs: {
                                    type: "number",
                                    label: _vm.$t(
                                      "home.editBookingOptionForm.sizeNote"
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.selectedBookingOption.bookings_size,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedBookingOption,
                                        "bookings_size",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedBookingOption.bookings_size",
                                  },
                                })
                              : _vm._e(),
                            _vm.selectedBookingOption.availability_type ==
                            "rooms_based"
                              ? _c("div", { staticClass: "col-span-2" }, [
                                  _c("span", { staticClass: "text-md" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.chooseRooms"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 w-full flex flex-wrap space-x-3 rtl:space-x-reverse",
                                    },
                                    _vm._l(_vm.roomsList, function (room) {
                                      return _c(
                                        "vs-checkbox",
                                        {
                                          key: room.id,
                                          staticClass: "mt-1",
                                          attrs: { "vs-value": room.id },
                                          model: {
                                            value: _vm.selectedRooms,
                                            callback: function ($$v) {
                                              _vm.selectedRooms = $$v
                                            },
                                            expression: "selectedRooms",
                                          },
                                        },
                                        [_vm._v(_vm._s(room.name))]
                                      )
                                    }),
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                    _vm.showDate
                      ? _c("div", { staticClass: "col-span-2 pt-10" }, [
                          _c("span", { staticClass: "text-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.eventDateShowReason",
                                  {
                                    timeBasedEventTypes: _vm.timeBasedEventTypes
                                      .map(
                                        (eventType) => eventType.display_name
                                      )
                                      .join(", "),
                                  }
                                )
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-2 flex space-x-3 rtl:space-x-reverse",
                            },
                            [
                              _c("v-date-picker", {
                                staticClass: "flex w-full",
                                attrs: {
                                  mode: "datetime",
                                  "is-range": "",
                                  color: "orange",
                                  masks: _vm.dateFormat,
                                  "min-date": _vm.minDate,
                                  "max-date": _vm.maxDate,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({
                                        inputValue,
                                        inputEvents,
                                        isDragging,
                                      }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "flex w-full" },
                                            [
                                              _c(
                                                "vs-input",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "flex-grow py-1 bg-gray-100 border rounded w-full pr-1",
                                                    class: isDragging
                                                      ? "text-gray-600"
                                                      : "text-gray-900",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "home.editBookingOptionForm.eventStartDate"
                                                      ),
                                                      value: inputValue.start,
                                                      icon: "today",
                                                    },
                                                  },
                                                  inputEvents.start
                                                )
                                              ),
                                              _c(
                                                "vs-input",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "flex-grow py-1 bg-gray-100 border rounded w-full",
                                                    class: isDragging
                                                      ? "text-gray-600"
                                                      : "text-gray-900",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "home.editBookingOptionForm.eventEndDate"
                                                      ),
                                                      value: inputValue.end,
                                                      icon: "event",
                                                    },
                                                  },
                                                  inputEvents.end
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1016828722
                                ),
                                model: {
                                  value: _vm.optionDateRange,
                                  callback: function ($$v) {
                                    _vm.optionDateRange = $$v
                                  },
                                  expression: "optionDateRange",
                                },
                              }),
                              _vm.optionDateRange.start ||
                              _vm.optionDateRange.end
                                ? _c(
                                    "div",
                                    { staticClass: "pt-2" },
                                    [
                                      _c("vs-button", {
                                        staticClass: "my-5 mx-1",
                                        attrs: {
                                          type: "border",
                                          "icon-pack": "feather",
                                          icon: "icon-trash",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearDateRange()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "text-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.eventDatesNote"
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("hr", { staticClass: "my-10" }),
                _c("div", { staticClass: "grid lg:grid-cols-2 gap-5" }, [
                  _c("div", { staticClass: "col-span-2" }, [
                    _c("span", { staticClass: "text-md" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("home.editBookingOptionForm.chooseEventTypes")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-2 w-full flex flex-wrap space-x-3 rtl:space-x-reverse",
                      },
                      _vm._l(_vm.eventTypesList, function (eventType) {
                        return _c(
                          "vs-checkbox",
                          {
                            key: eventType.id,
                            staticClass: "mt-1",
                            attrs: { "vs-value": eventType.id },
                            model: {
                              value: _vm.selectedEventTypes,
                              callback: function ($$v) {
                                _vm.selectedEventTypes = $$v
                              },
                              expression: "selectedEventTypes",
                            },
                          },
                          [_vm._v(_vm._s(eventType.display_name))]
                        )
                      }),
                      1
                    ),
                  ]),
                ]),
                _c("hr", { staticClass: "my-10" }),
                _c("div", { staticClass: "grid lg:grid-cols-2 gap-5 pb-10" }, [
                  _c("div", { staticClass: "col-span-2" }, [
                    _c("span", { staticClass: "text-md" }, [
                      _vm._v(
                        _vm._s(_vm.$t("home.editBookingOptionForm.pricingType"))
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c(
                        "vs-radio",
                        {
                          attrs: {
                            "vs-name": "selectedBookingOption.pricing_type",
                            "vs-value": "fixed",
                          },
                          model: {
                            value: _vm.selectedBookingOption.pricing_type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedBookingOption,
                                "pricing_type",
                                $$v
                              )
                            },
                            expression: "selectedBookingOption.pricing_type",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.editBookingOptionForm.selectText.fixed"
                              )
                            )
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "mx-5" }, [
                        _c("small", { staticClass: "gray-note" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.editBookingOptionForm.selectText.fixedNote"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c(
                        "vs-radio",
                        {
                          attrs: {
                            "vs-name": "selectedBookingOption.pricing_type",
                            "vs-value": "per_person",
                          },
                          model: {
                            value: _vm.selectedBookingOption.pricing_type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedBookingOption,
                                "pricing_type",
                                $$v
                              )
                            },
                            expression: "selectedBookingOption.pricing_type",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.editBookingOptionForm.selectText.per_person"
                              )
                            )
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "mx-5" }, [
                        _c("small", { staticClass: "gray-note" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.editBookingOptionForm.selectText.perPersonNote"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c(
                        "vs-radio",
                        {
                          attrs: {
                            "vs-name": "selectedBookingOption.pricing_type",
                            "vs-value": "per_person_range",
                          },
                          model: {
                            value: _vm.selectedBookingOption.pricing_type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedBookingOption,
                                "pricing_type",
                                $$v
                              )
                            },
                            expression: "selectedBookingOption.pricing_type",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.editBookingOptionForm.selectText.perPersonRange"
                              )
                            )
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "mx-5" }, [
                        _c("small", { staticClass: "gray-note" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.editBookingOptionForm.selectText.perPersonRangeNote"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c(
                        "vs-radio",
                        {
                          attrs: {
                            "vs-name": "selectedBookingOption.pricing_type",
                            "vs-value": "fixed_range",
                          },
                          model: {
                            value: _vm.selectedBookingOption.pricing_type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedBookingOption,
                                "pricing_type",
                                $$v
                              )
                            },
                            expression: "selectedBookingOption.pricing_type",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.editBookingOptionForm.selectText.fixedRange"
                              )
                            )
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "mx-5" }, [
                        _c("small", { staticClass: "gray-note" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.editBookingOptionForm.selectText.fixedRangeNote"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2 grid lg:grid-cols-2 gap-3" },
                    [
                      _vm.selectedBookingOption.pricing_type == "fixed"
                        ? _c("vs-input", {
                            staticClass: "w-full col-span-2 lg:col-span-1",
                            attrs: {
                              type: "number",
                              label: _vm.$t("home.editBookingOptionForm.price"),
                            },
                            model: {
                              value: _vm.selectedBookingOption.price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "price",
                                  $$v
                                )
                              },
                              expression: "selectedBookingOption.price",
                            },
                          })
                        : _vm._e(),
                      _vm.selectedBookingOption.pricing_type == "per_person"
                        ? _c("vs-input", {
                            staticClass: "w-full col-span-2 lg:col-span-1",
                            attrs: {
                              type: "number",
                              label: _vm.$t(
                                "home.editBookingOptionForm.manPrice"
                              ),
                            },
                            model: {
                              value: _vm.selectedBookingOption.man_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "man_price",
                                  $$v
                                )
                              },
                              expression: "selectedBookingOption.man_price",
                            },
                          })
                        : _vm._e(),
                      _vm.selectedBookingOption.pricing_type == "per_person"
                        ? _c("vs-input", {
                            staticClass: "w-full col-span-2 lg:col-span-1",
                            attrs: {
                              type: "number",
                              label: _vm.$t(
                                "home.editBookingOptionForm.womanPrice"
                              ),
                            },
                            model: {
                              value: _vm.selectedBookingOption.woman_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "woman_price",
                                  $$v
                                )
                              },
                              expression: "selectedBookingOption.woman_price",
                            },
                          })
                        : _vm._e(),
                      _vm.selectedBookingOption.pricing_type == "per_person" ||
                      _vm.selectedBookingOption.pricing_type ==
                        "per_person_range"
                        ? _c("vs-input", {
                            staticClass: "w-full col-span-2 lg:col-span-1",
                            attrs: {
                              type: "number",
                              label: _vm.$t(
                                "home.editBookingOptionForm.minimumPrice"
                              ),
                            },
                            model: {
                              value: _vm.selectedBookingOption.minimum_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "minimum_price",
                                  $$v
                                )
                              },
                              expression: "selectedBookingOption.minimum_price",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.selectedBookingOption.pricing_type ==
                    "per_person_range" ||
                  _vm.selectedBookingOption.pricing_type == "fixed_range"
                    ? _c(
                        "div",
                        { staticClass: "col-span-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-row mb-5" },
                            [
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "h-full mb-5 py-2 pr-2",
                                  attrs: {
                                    color: "warning",
                                    icon: "sms",
                                    active: _vm.rangePricingAlert,
                                    closable: "",
                                    "close-icon": "close",
                                  },
                                  on: {
                                    "update:active": function ($event) {
                                      _vm.rangePricingAlert = $event
                                    },
                                  },
                                },
                                [
                                  _c("h5", { staticClass: "mb-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.rangePricing.alert.header"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.rangePricing.alert.line1"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm.selectedBookingOption.pricing_type ==
                                  "per_person_range"
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.rangePricing.alert.line2_per_person"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.selectedBookingOption.pricing_type ==
                                  "fixed_range"
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.rangePricing.alert.line2_fixed"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.selectedBookingOption.range_pricing,
                            function (rangePricing, index) {
                              return _c("div", { key: index }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-2 lg:grid-cols-9",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cos-span-1 lg:col-span-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-11/12",
                                          attrs: {
                                            type: "number",
                                            label: _vm.$t(
                                              "servicesForm.groupPricing.menCount"
                                            ),
                                            autocomplete: "off",
                                          },
                                          model: {
                                            value: rangePricing.men_count,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                rangePricing,
                                                "men_count",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rangePricing.men_count",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cos-span-1 lg:col-span-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-11/12",
                                          attrs: {
                                            type: "number",
                                            label: _vm.$t(
                                              "servicesForm.groupPricing.manPrice"
                                            ),
                                            autocomplete: "off",
                                          },
                                          model: {
                                            value: rangePricing.man_price,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                rangePricing,
                                                "man_price",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rangePricing.man_price",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cos-span-1 lg:col-span-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-11/12",
                                          attrs: {
                                            type: "number",
                                            label: _vm.$t(
                                              "servicesForm.groupPricing.womenCount"
                                            ),
                                            autocomplete: "off",
                                          },
                                          model: {
                                            value: rangePricing.women_count,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                rangePricing,
                                                "women_count",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rangePricing.women_count",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cos-span-1 lg:col-span-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-11/12",
                                          attrs: {
                                            type: "number",
                                            label: _vm.$t(
                                              "servicesForm.groupPricing.womanPrice"
                                            ),
                                            autocomplete: "off",
                                          },
                                          model: {
                                            value: rangePricing.woman_price,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                rangePricing,
                                                "woman_price",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rangePricing.woman_price",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mt-1 col-span-1 flex justify-end",
                                      },
                                      [
                                        _c("vs-button", {
                                          staticClass: "m-0 mt-5",
                                          attrs: {
                                            color: "danger",
                                            icon: "delete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeRow(index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("hr"),
                              ])
                            }
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-2 w-full flex justify-end" },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "m-0 mt-5 mr-2",
                                  attrs: { color: "success", icon: "add" },
                                  on: { click: _vm.addRow },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("servicesForm.groupPricing.addRow")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "col-span-2 lg:col-span-1" }, [
                    _c("span", { staticClass: "text-md" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("home.editBookingOptionForm.taxingStatus")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse",
                      },
                      [
                        _c(
                          "vs-radio",
                          {
                            attrs: {
                              "vs-name": "selectedBookingOption.is_taxable",
                              "vs-value": 1,
                            },
                            model: {
                              value: _vm.selectedBookingOption.is_taxable,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "is_taxable",
                                  $$v
                                )
                              },
                              expression: "selectedBookingOption.is_taxable",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("home.editBookingOptionForm.taxable")
                              )
                            ),
                          ]
                        ),
                        _c(
                          "vs-radio",
                          {
                            attrs: {
                              "vs-name": "selectedBookingOption.is_taxable",
                              "vs-value": 0,
                            },
                            model: {
                              value: _vm.selectedBookingOption.is_taxable,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "is_taxable",
                                  $$v
                                )
                              },
                              expression: "selectedBookingOption.is_taxable",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("home.editBookingOptionForm.nonTaxable")
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-span-2 lg:col-span-1" }, [
                    _vm.selectedBookingOption.is_taxable
                      ? _c("div", [
                          _c("span", { staticClass: "text-md" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.taxingIncluding"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse",
                            },
                            [
                              _c(
                                "vs-radio",
                                {
                                  attrs: {
                                    "vs-name":
                                      "selectedBookingOption.tax_included",
                                    "vs-value": "included",
                                  },
                                  model: {
                                    value:
                                      _vm.selectedBookingOption.tax_included,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedBookingOption,
                                        "tax_included",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedBookingOption.tax_included",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.taxIncluded.included"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "vs-radio",
                                {
                                  attrs: {
                                    "vs-name":
                                      "selectedBookingOption.tax_included",
                                    "vs-value": "not-included",
                                  },
                                  model: {
                                    value:
                                      _vm.selectedBookingOption.tax_included,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedBookingOption,
                                        "tax_included",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedBookingOption.tax_included",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.taxIncluded.notIncluded"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "col-span-2 lg:col-span-1" }, [
                    _c("span", { staticClass: "text-md" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "home.editBookingOptionForm.hasSeasonalPricing"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse",
                      },
                      [
                        _c(
                          "vs-radio",
                          {
                            attrs: {
                              "vs-name":
                                "selectedBookingOption.has_seasonal_pricing",
                              "vs-value": "1",
                            },
                            model: {
                              value:
                                _vm.selectedBookingOption.has_seasonal_pricing,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "has_seasonal_pricing",
                                  $$v
                                )
                              },
                              expression:
                                "selectedBookingOption.has_seasonal_pricing",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.selectText.yes"
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "vs-radio",
                          {
                            attrs: {
                              "vs-name":
                                "selectedBookingOption.has_seasonal_pricing",
                              "vs-value": "0",
                            },
                            model: {
                              value:
                                _vm.selectedBookingOption.has_seasonal_pricing,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "has_seasonal_pricing",
                                  $$v
                                )
                              },
                              expression:
                                "selectedBookingOption.has_seasonal_pricing",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.selectText.no"
                                )
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-span-2 lg:col-span-1" }, [
                    _c("span", { staticClass: "text-md" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("home.editBookingOptionForm.hasDaysPricing")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse",
                      },
                      [
                        _c(
                          "vs-radio",
                          {
                            attrs: {
                              "vs-name":
                                "selectedBookingOption.has_days_pricing",
                              "vs-value": "1",
                            },
                            model: {
                              value: _vm.selectedBookingOption.has_days_pricing,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "has_days_pricing",
                                  $$v
                                )
                              },
                              expression:
                                "selectedBookingOption.has_days_pricing",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.selectText.yes"
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "vs-radio",
                          {
                            attrs: {
                              "vs-name":
                                "selectedBookingOption.has_days_pricing",
                              "vs-value": "0",
                            },
                            model: {
                              value: _vm.selectedBookingOption.has_days_pricing,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedBookingOption,
                                  "has_days_pricing",
                                  $$v
                                )
                              },
                              expression:
                                "selectedBookingOption.has_days_pricing",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.selectText.no"
                                )
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.actionType == "duplicateBookingOption"
                    ? _c("div", { staticClass: "col-span-2" }, [
                        _c("div", { staticClass: "container mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "vx-row" },
                            [
                              _c(
                                "vs-checkbox",
                                {
                                  model: {
                                    value: _vm.copyCustomPricings,
                                    callback: function ($$v) {
                                      _vm.copyCustomPricings = $$v
                                    },
                                    expression: "copyCustomPricings",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.copyCustomPricings"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "vx-row mt-0 pt-0" },
                            [
                              _c(
                                "vs-list",
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c("vs-list-item", {
                                    attrs: {
                                      icon: "info",
                                      subtitle: _vm.$t(
                                        "home.editBookingOptionForm.copyCustomPricingsNote"
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "col-span-2 my-10" }, [
                  _c(
                    "div",
                    { staticClass: "flex w-full justify-end" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mx-2",
                          attrs: { "icon-pack": "feather", icon: "icon-check" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.submitForm.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }