import { render, staticRenderFns } from "./SpecialOffersPopup.vue?vue&type=template&id=a21a1952"
import script from "./SpecialOffersPopup.vue?vue&type=script&lang=js"
export * from "./SpecialOffersPopup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/qosoor-agent-web-app/qosoor-agent-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a21a1952')) {
      api.createRecord('a21a1952', component.options)
    } else {
      api.reload('a21a1952', component.options)
    }
    module.hot.accept("./SpecialOffersPopup.vue?vue&type=template&id=a21a1952", function () {
      api.rerender('a21a1952', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/qosoor-components/DiscountCoupons/SpecialOffersPopup.vue"
export default component.exports