var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "data-list-container",
      attrs: { id: "data-list-list-view" },
    },
    [
      _c("booking-preview", {
        ref: "preview",
        attrs: {
          bookingPreviewActive: _vm.bookingPreviewActive,
          currentBooking: _vm.currentBooking,
          isLeadBooking: true,
        },
        on: {
          "update:bookingPreviewActive": function ($event) {
            _vm.bookingPreviewActive = $event
          },
          "update:booking-preview-active": function ($event) {
            _vm.bookingPreviewActive = $event
          },
        },
      }),
      _vm.currentBooking
        ? _c(
            "vs-prompt",
            {
              staticClass: "export-options",
              attrs: {
                title:
                  _vm.currentBooking.state_id == 8
                    ? _vm.$t("unblockDate.header")
                    : _vm.$t("blockDate.header"),
                color: _vm.currentBooking.state_id == 8 ? "success" : "danger",
                "accept-text":
                  _vm.currentBooking.state_id == 8
                    ? _vm.$t("unblockDate.block")
                    : _vm.$t("blockDate.block"),
                "cancel-text": _vm.$t("blockDate.cancel"),
                active: _vm.bookingBlockPromptActive,
              },
              on: {
                accept: _vm.blockDate,
                "update:active": function ($event) {
                  _vm.bookingBlockPromptActive = $event
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.currentBooking.state_id == 8
                      ? _vm.$t("unblockDate.bodyCustomerDate", {
                          bookingOption:
                            _vm.currentBooking.receipt.booking_option.name,
                          date: _vm.currentBooking.start_date,
                        })
                      : _vm.$t("blockDate.bodyCustomerDate", {
                          bookingOption:
                            _vm.currentBooking.receipt.booking_option.name,
                          date: _vm.currentBooking.start_date,
                        })
                  )
                ),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.currentBooking.state_id == 8
                      ? _vm.$t("unblockDate.confirmation")
                      : _vm.$t("blockDate.confirmation")
                  )
                ),
              ]),
              _c(
                "vs-alert",
                {
                  staticClass: "my-4",
                  attrs: { color: "warning", icon: "new_releases" },
                },
                [
                  _c("small", [
                    _vm._v(
                      _vm._s(
                        _vm.currentBooking.state_id == 8
                          ? _vm.$t("unblockDate.note")
                          : _vm.$t("blockDate.note")
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("discount-coupons-form-popup", {
        attrs: {
          discountCouponPopupActive: _vm.sendOfferToCustomerPromptActive,
          currentDiscountCoupon: _vm.currentDiscountCoupon,
          isSpecificToCustomer: true,
          booking: _vm.currentBooking,
        },
        on: {
          "update:discountCouponPopupActive": function ($event) {
            _vm.sendOfferToCustomerPromptActive = $event
          },
          "update:discount-coupon-popup-active": function ($event) {
            _vm.sendOfferToCustomerPromptActive = $event
          },
          getLeads: _vm.getLeads,
        },
      }),
      _c("special-offers-popup", {
        attrs: {
          specialOffersPopupActive: _vm.specialOffersPopupActive,
          specialOffer: _vm.specialOffer,
          booking: _vm.currentBooking,
        },
        on: {
          "update:specialOffersPopupActive": function ($event) {
            _vm.specialOffersPopupActive = $event
          },
          "update:special-offers-popup-active": function ($event) {
            _vm.specialOffersPopupActive = $event
          },
          getLeads: _vm.getLeads,
        },
      }),
      !_vm.isPhoneScreen
        ? _c(
            "vs-table",
            {
              ref: "leadsTableRef",
              staticClass: "mb-5",
              attrs: {
                sst: true,
                pagination: "",
                total: parseInt(_vm.tableConfig.total),
                "max-items": parseInt(_vm.tableConfig.perPage),
                data: _vm.leads,
              },
              on: {
                "change-page": _vm.handleChangePage,
                selected: _vm.showData,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ data }) {
                      return [
                        _c(
                          "tbody",
                          [
                            _vm._l(data, function (tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr, attrs: { data: tr } },
                                [
                                  _c(
                                    "vs-td",
                                    { staticClass: "whitespace-no-wrap" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "flex flex-row whitespace-no-wrap",
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "mx-1 action-btn",
                                              attrs: {
                                                type: "border",
                                                "icon-pack": "feather",
                                                icon: "icon-info",
                                                size: _vm.isPhoneScreen
                                                  ? "small"
                                                  : "default",
                                                color: "#adadad",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showData(tr)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "bookingsLeads.showBooking"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          tr.special_offers.length > 0
                                            ? _c(
                                                "vs-button",
                                                {
                                                  staticClass:
                                                    "mx-1 action-btn",
                                                  attrs: {
                                                    type: "border",
                                                    "icon-pack": "feather",
                                                    icon: "icon-info",
                                                    size: _vm.isPhoneScreen
                                                      ? "small"
                                                      : "default",
                                                    color: "#34abeb",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.showSpecialOffers(
                                                        tr
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "bookingsLeads.showSpecialOffers"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "vs-button",
                                                {
                                                  staticClass:
                                                    "mx-1 action-btn",
                                                  attrs: {
                                                    type: "border",
                                                    "icon-pack": "feather",
                                                    icon: "icon-percent",
                                                    size: _vm.isPhoneScreen
                                                      ? "small"
                                                      : "default",
                                                    color: "success",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.sendOfferToCustomerBtn(
                                                        tr
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "bookingsLeads.sendOfferToCustomer"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                          tr.start_date > _vm.today
                                            ? _c(
                                                "vs-button",
                                                {
                                                  staticClass:
                                                    "mx-1 action-btn",
                                                  attrs: {
                                                    type: "border",
                                                    "icon-pack": "feather",
                                                    icon:
                                                      tr.state_id == 8
                                                        ? "icon-check"
                                                        : "icon-minus-circle",
                                                    size: _vm.isPhoneScreen
                                                      ? "small"
                                                      : "default",
                                                    color:
                                                      tr.state_id == 8
                                                        ? "success"
                                                        : "danger",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.blockDateBtn(
                                                        tr
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tr.state_id == 8
                                                          ? _vm.$t(
                                                              "bookingsLeads.unblockDate"
                                                            )
                                                          : _vm.$t(
                                                              "bookingsLeads.dateIsBooked"
                                                            )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "product-name font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            tr.receipt
                                              .local_final_price_without_discount
                                              ? tr.receipt.local_final_price_without_discount.toLocaleString()
                                              : 0 + " "
                                          ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      { staticClass: "booking-date truncate" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              tr.start_date,
                                              "DD-MM-YYYY"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      { staticClass: "booking-date truncate" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              tr.booked_at,
                                              "DD-MM-YYYY"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _c("p", { staticClass: "customer-phone" }, [
                                      _vm._v(
                                        _vm._s(
                                          tr.customer_name
                                            ? tr.customer_name.substring(0, 2) +
                                                "*****"
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                485246596
              ),
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _vm._l(_vm.generalInfoColumnsTitles, function (col, index) {
                    return _c(
                      "vs-th",
                      { key: index, attrs: { "sort-key": col.sortKey } },
                      [
                        _c(
                          "p",
                          { staticClass: "table-head font-medium truncate" },
                          [_vm._v(_vm._s(col.title))]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            2
          )
        : _c("vx-card", [
            _c("div", { staticClass: "container-fluid mt-2 px-0 mb-5" }, [
              _vm.leads && _vm.leads.length > 0
                ? _c(
                    "div",
                    { staticClass: "grid grid-cols-1 gap-3" },
                    _vm._l(_vm.leads, function (lead) {
                      return _c(
                        "div",
                        { key: lead.id },
                        [
                          _c(
                            "vs-card",
                            {
                              staticClass: "cardx mb-5",
                              attrs: { "fixed-height": "" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "opacity-75 text-lg lg:text-2xl",
                                    },
                                    [
                                      _c("i", { staticClass: "far fa-user" }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("bookingsLeads.name"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "text-lg lg:text-2xl" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          lead.customer_name
                                            ? lead.customer_name.substring(
                                                0,
                                                2
                                              ) + "*****"
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "opacity-75 text-lg lg:text-2xl",
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "far fa-solid fa-calendar-check",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("bookingsLeads.eventDate")
                                          )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "text-lg lg:text-2xl" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          lead.start_date ? lead.start_date : ""
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "opacity-75 text-lg lg:text-2xl",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-solid fa-clock",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("bookingsLeads.bookedAt")
                                          )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "text-lg lg:text-2xl" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          lead.booked_at ? lead.booked_at : ""
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                                },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "opacity-75 text-lg lg:text-2xl",
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "far fa-solid fa-dollar-sign ml-1 mr-1",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("bookingsLeads.price"))
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-danger text-lg lg:text-2xl",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              lead.receipt
                                                .local_final_price_without_discount
                                                ? lead.receipt.local_final_price_without_discount.toLocaleString()
                                                : 0 + " "
                                            ) +
                                            " " +
                                            _vm._s(_vm.$t("SAR")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col p-0 w-full flex flex-wrap justify-center",
                                      },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "very-small-note" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("vatIncluded"))
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col p-0 w-full flex flex-wrap justify-between mt-3",
                                },
                                [
                                  lead.special_offers.length > 0
                                    ? _c(
                                        "vs-button",
                                        {
                                          staticClass: "action-btn",
                                          attrs: {
                                            type: "border",
                                            "icon-pack": "feather",
                                            icon: "icon-percent",
                                            size: _vm.isPhoneScreen
                                              ? "small"
                                              : "default",
                                            color: "#34abeb",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.showSpecialOffers(lead)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "bookingsLeads.showSpecialOffers"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "vs-button",
                                        {
                                          staticClass: "action-btn",
                                          attrs: {
                                            type: "border",
                                            "icon-pack": "feather",
                                            icon: "icon-percent",
                                            size: _vm.isPhoneScreen
                                              ? "small"
                                              : "default",
                                            color: "success",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.sendOfferToCustomerBtn(
                                                lead
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "bookingsLeads.sendOfferToCustomer"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                  lead.start_date > _vm.today
                                    ? _c(
                                        "vs-button",
                                        {
                                          staticClass: "action-btn",
                                          attrs: {
                                            type: "border",
                                            "icon-pack": "feather",
                                            icon:
                                              lead.state_id == 8
                                                ? "icon-check"
                                                : "icon-minus-circle",
                                            size: _vm.isPhoneScreen
                                              ? "small"
                                              : "default",
                                            color:
                                              lead.state_id == 8
                                                ? "success"
                                                : "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.blockDateBtn(lead)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                lead.state_id == 8
                                                  ? _vm.$t(
                                                      "bookingsLeads.unblockDate"
                                                    )
                                                  : _vm.$t(
                                                      "bookingsLeads.dateIsBooked"
                                                    )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col p-0 w-full flex flex-wrap justify-center mt-3",
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "action-btn w-full",
                                      attrs: {
                                        type: "border",
                                        "icon-pack": "feather",
                                        icon: "icon-info",
                                        size: _vm.isPhoneScreen
                                          ? "small"
                                          : "default",
                                        color: "#adadad",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showData(lead)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("bookingsLeads.showBooking")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm.leads && _vm.leads.length == 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "row w-full mt-10 lg:mt-5 flex flex-wrap justify-center",
                    },
                    [
                      _c(
                        "h5",
                        { staticClass: "mt-3 mr-2 text-center gray-note" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("bookingOptionPreview.noBookingOptions")
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "container-fluid mt-2 px-0 mb-5" }, [
              _c(
                "div",
                { staticClass: "vx-col p-0 w-full mt-3" },
                [
                  _c("vs-pagination", {
                    attrs: {
                      total: Math.ceil(
                        _vm.tableConfig.total / _vm.tableConfig.perPage
                      ),
                      max: 5,
                    },
                    on: { input: _vm.handleChangePage },
                    model: {
                      value: _vm.currentx,
                      callback: function ($$v) {
                        _vm.currentx = $$v
                      },
                      expression: "currentx",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }