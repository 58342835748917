var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.activeUserInfo.displayName
    ? _c(
        "div",
        { staticClass: "the-navbar__user-meta flex items-center" },
        [
          _c(
            "div",
            { staticClass: "text-right leading-tight hidden sm:block" },
            [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.user.full_name) + " "),
              ]),
              _c("small", [_vm._v(_vm._s(_vm.user.email))]),
            ]
          ),
          _c(
            "vs-dropdown",
            {
              staticClass: "cursor-pointer",
              attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
            },
            [
              _c("div", { staticClass: "con-img ml-3" }, [
                _vm.activeUserInfo.photoURL
                  ? _c("img", {
                      key: "onlineImg",
                      staticClass:
                        "rounded-full shadow-md cursor-pointer block",
                      attrs: {
                        src: _vm.activeUserInfo.photoURL,
                        alt: "user-img",
                        width: "40",
                        height: "40",
                      },
                    })
                  : _vm._e(),
              ]),
              _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
                _c("ul", { staticStyle: { "min-width": "9rem" } }, [
                  _c(
                    "li",
                    {
                      staticClass:
                        "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                      on: { click: _vm.logout },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "LogOutIcon", svgClasses: "w-4 h-4" },
                      }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$t("logout"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      staticClass:
                        "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "flex items-center",
                          attrs: {
                            to: `/${_vm.currentVenueId}/agent-profile`,
                            tag: "span",
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "InfoIcon", svgClasses: "w-4 h-4" },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(_vm.$t("profile"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }