var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "holamundo",
          attrs: {
            title: _vm.$t("businessEventsList.businessEventDetails"),
            active: _vm.isActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.isActive = $event
            },
            close: _vm.closePopup,
          },
        },
        [
          _vm.currentBusinessEvent
            ? _c(
                "div",
                [
                  _c(
                    "vs-table",
                    { attrs: { stripe: "", data: _vm.fakeData } },
                    [
                      [
                        _c(
                          "vs-tr",
                          [
                            _c("vs-td", { attrs: { sortable: false } }, [
                              _vm._v(_vm._s(_vm.$t("businessEventsList.name"))),
                            ]),
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(_vm.currentBusinessEvent.event_name)
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "vs-tr",
                          [
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(_vm.$t("businessEventsList.guestsCount"))
                              ),
                            ]),
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(_vm.currentBusinessEvent.guests_count)
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "vs-tr",
                          [
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("businessEventsList.eventDescription")
                                )
                              ),
                            ]),
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(
                                  _vm.currentBusinessEvent.event_description
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "vs-tr",
                          [
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("businessEventsList.eventLocation")
                                )
                              ),
                            ]),
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(_vm.currentBusinessEvent.event_location)
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "vs-tr",
                          [
                            _c("vs-td", [
                              _vm._v(_vm._s(_vm.$t("businessEventsList.date"))),
                            ]),
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(_vm.currentBusinessEvent.event_date)
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "vs-tr",
                          [
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(_vm.$t("businessEventsList.budget"))
                              ),
                            ]),
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(
                                  _vm.currentBusinessEvent.budget.toLocaleString()
                                ) +
                                  " " +
                                  _vm._s(_vm.$t("SAR"))
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "vs-tr",
                          [
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(_vm.$t("businessEventsList.status"))
                              ),
                            ]),
                            _c(
                              "vs-td",
                              [
                                _c(
                                  "vs-chip",
                                  {
                                    staticClass: "product-order-status",
                                    attrs: {
                                      color:
                                        _vm.currentBusinessEvent.status.color,
                                    },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currentBusinessEvent.status
                                            .display_name
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-tr",
                          [
                            _c("vs-td", [
                              _vm._v(
                                _vm._s(_vm.$t("businessEventsList.attachments"))
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "div",
                                { staticClass: "vx-row px-2 py-5 flex" },
                                _vm._l(
                                  _vm.currentBusinessEvent.attachments,
                                  function (attachment, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: attachment,
                                        staticClass: "vx-col py-1 px-1",
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            attrs: {
                                              color: "warning",
                                              type: "filled",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.attachmentBtnClicked(
                                                  attachment
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "businessEventsList.attachment"
                                                )
                                              ) +
                                                " " +
                                                _vm._s(index + 1)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                  _c("h4", { staticClass: "mt-10" }, [
                    _vm._v(_vm._s(_vm.$t("businessEventsList.proposals"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid lg:grid-cols-2 md:grid-cols-1 gap-3 mb-5",
                    },
                    [
                      _vm._l(_vm.proposals, function (proposal) {
                        return _c(
                          "div",
                          { key: proposal.id, staticClass: "flex-none w-20" },
                          [
                            _c(
                              "vs-card",
                              {
                                staticClass: "cardx my-5",
                                attrs: { "fixed-height": "" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "extra-content" },
                                    slot: "extra-content",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "container h-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vx-row px-2 py-3 h-32 overflow-auto hover:overflow-scroll",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "vx-col w-full" },
                                              [
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "font-medium",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(proposal.details)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vx-row px-2 py-5 flex",
                                          },
                                          _vm._l(
                                            proposal.attachments,
                                            function (attachment, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: attachment,
                                                  staticClass:
                                                    "vx-col py-1 px-1",
                                                },
                                                [
                                                  _c(
                                                    "vs-button",
                                                    {
                                                      attrs: {
                                                        color: "warning",
                                                        type: "filled",
                                                        size: "small",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.attachmentBtnClicked(
                                                            attachment
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "businessEventsList.attachment"
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(index + 1)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { attrs: { slot: "footer" }, slot: "footer" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-row flex justify-end px-5",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              proposal.price
                                                ? proposal.price.toLocaleString()
                                                : ""
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "flex-none w-20" },
                        [
                          _c(
                            "vs-card",
                            {
                              staticClass: "cardx my-5",
                              attrs: { "fixed-height": "" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: { slot: "extra-content" },
                                  slot: "extra-content",
                                },
                                [
                                  _c("vs-button", {
                                    staticClass: "w-full h-full",
                                    attrs: {
                                      color: "success",
                                      type: "border",
                                      icon: "add",
                                    },
                                    on: { click: _vm.addProposal },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }