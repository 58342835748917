var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showLoginInputs
      ? _c("div", [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "w-9/12" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    label: _vm.$t("phoneNumber") || "رقم الجوال",
                    placeholder: _vm.$t("phoneNumberPlaceholder"),
                    dir: "ltr",
                    autocomplete: "off",
                    danger: _vm.validation.hasError("phoneNumberWithoutKey"),
                    "danger-text": _vm.validation.firstError(
                      "phoneNumberWithoutKey"
                    ),
                  },
                  model: {
                    value: _vm.phoneNumberWithoutKey,
                    callback: function ($$v) {
                      _vm.phoneNumberWithoutKey = $$v
                    },
                    expression: "phoneNumberWithoutKey",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-3/12" },
              [
                _c("v-select", {
                  staticClass: "v-select-background w-full mt-6",
                  attrs: { clearable: false, options: ["966"], dir: "rtl" },
                  model: {
                    value: _vm.phoneNumberKey,
                    callback: function ($$v) {
                      _vm.phoneNumberKey = $$v
                    },
                    expression: "phoneNumberKey",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "my-5" }, [
            _c("span", { staticClass: "my-3 text-lg" }, [
              _vm._v("Send VC via:"),
            ]),
            _c("ul", { staticClass: "mt-2" }, [
              _c(
                "li",
                [
                  _c(
                    "vs-radio",
                    {
                      attrs: {
                        "vs-name": "vc_channel",
                        "vs-value": "whatsapp",
                      },
                      model: {
                        value: _vm.vc_channel,
                        callback: function ($$v) {
                          _vm.vc_channel = $$v
                        },
                        expression: "vc_channel",
                      },
                    },
                    [_vm._v("Whatsapp")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "vs-radio",
                    {
                      attrs: { "vs-name": "vc_channel", "vs-value": "sms" },
                      model: {
                        value: _vm.vc_channel,
                        callback: function ($$v) {
                          _vm.vc_channel = $$v
                        },
                        expression: "vc_channel",
                      },
                    },
                    [_vm._v("SMS")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-between mb-3" },
            [
              _c(
                "vs-button",
                {
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.loginClicked },
                },
                [_vm._v(_vm._s(_vm.$t("buttons.sendCode")))]
              ),
              !_vm.isProduction
                ? _c("vs-button", { on: { click: _vm.changePhoneNumber } }, [
                    _vm._v("(:"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.showVerificationInputs
      ? _c(
          "div",
          [
            _c("div", [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("messages.sentCode")) + " "),
                _c("span", [_vm._v(_vm._s(_vm.phoneNumber))]),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$t("messages.enterCode")))]),
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "w-full py-3",
              attrs: {
                "data-vv-validate-on": "blur",
                name: "verification-code",
                "icon-no-border": "",
                icon: "icon icon-phone",
                "icon-pack": "feather",
                "label-placeholder": _vm.$t("verificationCode") || "رمز التحقق",
              },
              model: {
                value: _vm.verificationCode,
                callback: function ($$v) {
                  _vm.verificationCode = $$v
                },
                expression: "verificationCode",
              },
            }),
            _c("span", { staticClass: "text-danger text-sm" }, [
              _vm._v(_vm._s(_vm.errors.first("verification-code"))),
            ]),
            _vm.isSuperUser
              ? _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full py-3",
                  attrs: {
                    "data-vv-validate-on": "blur",
                    type: "password",
                    name: "password",
                    "icon-no-border": "",
                    icon: "icon icon-lock",
                    "icon-pack": "feather",
                    "label-placeholder": _vm.$t("password") || "كلمة المرور",
                  },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                })
              : _vm._e(),
            _vm.isSuperUser
              ? _c(
                  "div",
                  { staticClass: "w-full py-3 mb-4" },
                  [
                    _c("label", { staticClass: "v-select-label" }, [
                      _vm._v(_vm._s(_vm.$t("chooseVenue") || "اختر الصالة")),
                    ]),
                    _c("v-select", {
                      attrs: {
                        options: _vm.venues,
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        label: "name",
                      },
                      on: { search: _vm.requestVenues },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "option",
                            fn: function (option) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(option.arabic_name) +
                                    " - " +
                                    _vm._s(option.name) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2010887097
                      ),
                      model: {
                        value: _vm.venue_id,
                        callback: function ($$v) {
                          _vm.venue_id = $$v
                        },
                        expression: "venue_id",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between py-3" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { disabled: !_vm.validateForm },
                    on: { click: _vm.verifyClicked },
                  },
                  [_vm._v(_vm._s(_vm.$t("buttons.verify")))]
                ),
                _c(
                  "vs-button",
                  {
                    staticStyle: { direction: "rtl" },
                    attrs: {
                      color: "primary",
                      type: "border",
                      icon: "arrow_back_ios",
                      "icon-after": "",
                    },
                    on: { click: _vm.changePhoneNumberClicked },
                  },
                  [_vm._v(_vm._s(_vm.$t("buttons.changeNumber")))]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }