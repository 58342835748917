var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.specialOffer
    ? _c(
        "div",
        [
          _c(
            "vs-popup",
            {
              staticClass: "holamundo",
              attrs: { title: "العروض المرسلة", active: _vm.isActive },
              on: {
                "update:active": function ($event) {
                  _vm.isActive = $event
                },
                close: _vm.closePopup,
              },
            },
            [
              _c(
                "div",
                { staticClass: "container w-full" },
                [
                  _c("discount-coupons-form", {
                    attrs: {
                      isEditDiscountCoupon: true,
                      editFromLeads: true,
                      booking: _vm.booking,
                      currentDiscountCoupon: _vm.specialOffer,
                    },
                    on: { closePopup: _vm.closePopup, getLeads: _vm.getLeads },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }