var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "holamundo",
          attrs: {
            title: _vm.$t("questions.onBoardingPopup.title"),
            active: _vm.onBoardingPopup,
          },
          on: {
            "update:active": function ($event) {
              _vm.onBoardingPopup = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-center my-5" }, [
            _c("span", { staticClass: "text-4xl text-center" }, [
              _vm._v(_vm._s(_vm.$t("questions.onBoardingPopup.contectTitle"))),
            ]),
          ]),
          _c("div", { staticClass: "flex justify-center mb-5" }, [
            _c("span", { staticClass: "text-lg text-center" }, [
              _vm._v(_vm._s(_vm.$t("questions.onBoardingPopup.contect"))),
            ]),
          ]),
          _c("div", { staticClass: "flex justify-center" }, [
            _c("img", {
              staticStyle: { height: "15rem" },
              attrs: {
                src: require("@/assets/images/questions-and-answers/onboarding-hero.svg"),
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "flex justify-end my-5" },
            [
              _c(
                "vs-button",
                {
                  attrs: { type: "filled" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.onBoardingPopup = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("questions.onBoardingPopup.ok")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c("answer-question-form-popup", {
        attrs: {
          isAnswerQuestionActive: _vm.isAnswerQuestionActive,
          customerQuestionProp: _vm.currentQuestion,
        },
        on: {
          "update:isAnswerQuestionActive": function ($event) {
            _vm.isAnswerQuestionActive = $event
          },
          "update:is-answer-question-active": function ($event) {
            _vm.isAnswerQuestionActive = $event
          },
        },
      }),
      _c(
        "div",
        { staticClass: "hidden lg:block" },
        [
          _c(
            "vs-table",
            {
              ref: "SettlementsTable",
              staticClass: "mb-5",
              attrs: {
                sst: true,
                pagination: "",
                total: parseInt(_vm.tableConfig.total),
                "max-items": parseInt(_vm.tableConfig.perPage),
                data: _vm.customersQuestions,
                stripe: "",
              },
              on: {
                "change-page": _vm.handleChangePage,
                selected: _vm.showData,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return [
                      _c(
                        "tbody",
                        [
                          _vm._l(data, function (tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr, attrs: { data: tr } },
                              [
                                _c("vs-td", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "product-name font-medium truncate",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          tr.question.slice(0, 15) + "..."
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("vs-td", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "product-name font-medium truncate",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .moment(tr.created_at)
                                            .locale(_vm.$i18n.locale)
                                            .fromNow()
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("vs-td", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "product-name font-medium truncate",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          tr.answer
                                            ? tr.answer.slice(0, 15) + "..."
                                            : "-"
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("vs-td", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "product-name font-medium truncate",
                                    },
                                    [_vm._v(_vm._s(tr.booking_option.name))]
                                  ),
                                ]),
                                _c(
                                  "vs-td",
                                  [
                                    tr.answer
                                      ? _c(
                                          "vs-chip",
                                          {
                                            attrs: {
                                              transparent: "",
                                              color: "success",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("questions.answered", {
                                                    answeredAt: _vm
                                                      .moment(tr.answered_at)
                                                      .format("D-M-Y H:mm"),
                                                  })
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "vs-chip",
                                          {
                                            attrs: {
                                              transparent: "",
                                              color: "warning",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "questions.notAnswered"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                    tr.status == "visible_to_customers"
                                      ? _c(
                                          "vs-chip",
                                          {
                                            attrs: {
                                              transparent: "",
                                              color: "success",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "questions.visibleToCustomers"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "vs-chip",
                                          {
                                            attrs: {
                                              transparent: "",
                                              color: "warning",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "questions.notVisibleToCustomers"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  [
                    _c("vs-th", { staticClass: "text-lg" }, [
                      _vm._v(" " + _vm._s(_vm.$t("questions.question"))),
                    ]),
                    _c("vs-th", { staticClass: "text-lg" }, [
                      _vm._v(" " + _vm._s(_vm.$t("questions.askedAt"))),
                    ]),
                    _c("vs-th", { staticClass: "text-lg" }, [
                      _vm._v(" " + _vm._s(_vm.$t("questions.answer"))),
                    ]),
                    _c("vs-th", { staticClass: "text-lg" }, [
                      _vm._v(" " + _vm._s(_vm.$t("questions.bookingOption"))),
                    ]),
                    _c("vs-th", { staticClass: "text-lg" }, [
                      _vm._v(" " + _vm._s(_vm.$t("questions.status"))),
                    ]),
                  ],
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block lg:hidden" },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 gap-6" },
            _vm._l(_vm.customersQuestions, function (customersQuestion, index) {
              return _c(
                "div",
                { key: index, staticClass: "p-4 border rounded-lg shadow-md" },
                [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "flex justify-start gap-2 mb-4" },
                      [
                        _c("div", [
                          _c(
                            "svg",
                            {
                              staticClass: "w-5 h-5 text-gray-800",
                              attrs: {
                                "aria-hidden": "true",
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 20 20",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  stroke: "currentColor",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d: "M7.529 7.988a2.502 2.502 0 0 1 5 .191A2.441 2.441 0 0 1 10 10.582V12m-.01 3.008H10M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("questions.question")) + ": "
                          ),
                        ]),
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            customersQuestion.question.slice(0, 15) + "..."
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "flex justify-start gap-2 mb-4" },
                      [
                        _c("div", [
                          _c(
                            "svg",
                            {
                              staticClass: "w-5 h-5 text-gray-800",
                              attrs: {
                                "aria-hidden": "true",
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 20 20",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  stroke: "currentColor",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d: "M10 6v4l3.276 3.276M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("questions.askedAt")) + ": "
                          ),
                        ]),
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .moment(customersQuestion.created_at)
                              .locale(_vm.$i18n.locale)
                              .fromNow()
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "flex justify-start gap-2 mb-4" },
                      [
                        _c("div", [
                          _c(
                            "svg",
                            {
                              staticClass: "w-5 h-5 text-gray-800",
                              attrs: {
                                "aria-hidden": "true",
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 20 20",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  stroke: "currentColor",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d: "M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("questions.answer")) + ": "
                          ),
                        ]),
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            customersQuestion.answer
                              ? customersQuestion.answer.slice(0, 15) + "..."
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "flex justify-start gap-2 mb-4" },
                      [
                        _c("div", [
                          _c(
                            "svg",
                            {
                              staticClass: "w-5 h-5 text-gray-800",
                              attrs: {
                                "aria-hidden": "true",
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 19 18",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  stroke: "currentColor",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d: "M13.583 5.445h.01M8.86 16.71l-6.573-6.63a.993.993 0 0 1 0-1.4l7.329-7.394A.98.98 0 0 1 10.31 1l5.734.007A1.968 1.968 0 0 1 18 2.983v5.5a.994.994 0 0 1-.316.727l-7.439 7.5a.975.975 0 0 1-1.385.001Z",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("questions.bookingOption")) +
                              ": "
                          ),
                        ]),
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(customersQuestion.booking_option.name) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "flex justify-start gap-2 mb-4" },
                      [
                        _c("div", [
                          _c(
                            "svg",
                            {
                              staticClass: "w-5 h-5 text-gray-800",
                              attrs: {
                                "aria-hidden": "true",
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 20 20",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  stroke: "currentColor",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d: "M18 5h1v12a2 2 0 0 1-2 2m0 0a2 2 0 0 1-2-2V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v15a2 2 0 0 0 2 2h14ZM10 4h2m-2 3h2m-8 3h8m-8 3h8m-8 3h8M4 4h3v3H4V4Z",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("questions.status")) + ": "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        customersQuestion.answer
                          ? _c(
                              "vs-chip",
                              { attrs: { transparent: "", color: "success" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("questions.answered", {
                                        answeredAt:
                                          customersQuestion.answered_at,
                                      })
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "vs-chip",
                              { attrs: { transparent: "", color: "warning" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("questions.notAnswered")) +
                                    " "
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-center mt-6" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "w-full",
                          attrs: { type: "border", icon: "info" },
                          on: {
                            click: function ($event) {
                              return _vm.showData(customersQuestion)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("questions.details")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _c("vs-pagination", {
            staticClass: "mt-5",
            attrs: {
              total: Math.ceil(_vm.tableConfig.total / _vm.tableConfig.perPage),
              max: 5,
            },
            on: { input: _vm.handleChangePage },
            model: {
              value: _vm.tableConfig.currentPage,
              callback: function ($$v) {
                _vm.$set(_vm.tableConfig, "currentPage", $$v)
              },
              expression: "tableConfig.currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }