var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "dashboard-analytics" } }, [
    _c("div", { staticClass: "vx-row w-full" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/2 mb-10" },
        [
          _c("vx-card", { attrs: { title: "خيارات الحجز" } }, [
            _vm._v(" test "),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }