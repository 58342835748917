var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|email|min:3",
            expression: "'required|email|min:3'",
          },
        ],
        staticClass: "w-full",
        attrs: {
          "data-vv-validate-on": "blur",
          name: "email",
          "icon-no-border": "",
          icon: "icon icon-user",
          "icon-pack": "feather",
          "label-placeholder": "Email",
        },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("email"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|min:6|max:10",
            expression: "'required|min:6|max:10'",
          },
        ],
        staticClass: "w-full mt-6",
        attrs: {
          "data-vv-validate-on": "blur",
          type: "password",
          name: "password",
          "icon-no-border": "",
          icon: "icon icon-lock",
          "icon-pack": "feather",
          "label-placeholder": "Password",
        },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("password"))),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-between my-5" },
        [
          _c(
            "vs-checkbox",
            {
              staticClass: "mb-3",
              model: {
                value: _vm.checkbox_remember_me,
                callback: function ($$v) {
                  _vm.checkbox_remember_me = $$v
                },
                expression: "checkbox_remember_me",
              },
            },
            [_vm._v("Remember Me")]
          ),
          _c("router-link", { attrs: { to: "/pages/forgot-password" } }, [
            _vm._v("Forgot Password?"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-between mb-3" },
        [
          _c(
            "vs-button",
            { attrs: { type: "border" }, on: { click: _vm.registerUser } },
            [_vm._v("Register")]
          ),
          _c(
            "vs-button",
            {
              attrs: { disabled: !_vm.validateForm },
              on: { click: _vm.loginJWT },
            },
            [_vm._v("Login")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }