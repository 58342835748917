var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { attrs: { title: _vm.$t("home.bookingOptions") } }, [
        _c("div", { staticClass: "container-fluid mt-2 px-4" }, [
          _c(
            "div",
            { staticClass: "vx-row justify-end" },
            [
              _c(
                "vs-button",
                { attrs: { icon: "add" }, on: { click: _vm.addBookingOption } },
                [_vm._v(_vm._s(_vm.$t("Add")) + "sssssss")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "container-fluid mt-2 px-0 mb-5" }, [
          _vm.bookingOptions && _vm.bookingOptions.length > 0
            ? _c(
                "div",
                { staticClass: "grid lg:grid-cols-3 md:grid-cols-2 gap-3" },
                _vm._l(_vm.bookingOptions, function (bookingOption) {
                  return _c(
                    "div",
                    { key: bookingOption.id, staticClass: "flex-none w-20" },
                    [
                      _c(
                        "vs-card",
                        {
                          staticClass: "cardx my-5",
                          attrs: { "fixed-height": "" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "media" }, slot: "media" },
                            [
                              _c("img", {
                                staticClass: "object-cover h-48",
                                attrs: {
                                  src:
                                    _vm.imagesURL +
                                    "/" +
                                    (bookingOption.images.length > 0
                                      ? bookingOption.images[0]
                                      : bookingOption.main_image),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "extra-content" },
                              slot: "extra-content",
                            },
                            [
                              _c("div", { staticClass: "container" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vx-row justify-between px-2 pt-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        bookingOption.availability ==
                                        "available"
                                          ? _c(
                                              "vs-tooltip",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  text: "This booking option is available to the customers",
                                                },
                                              },
                                              [
                                                _c(
                                                  "vs-chip",
                                                  {
                                                    attrs: { color: "success" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "home.editBookingOptionForm.bookingOptionAvailable"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "vs-tooltip",
                                              {
                                                attrs: {
                                                  text: "his booking option is hidden from the customers",
                                                },
                                              },
                                              [
                                                _c(
                                                  "vs-chip",
                                                  {
                                                    attrs: { color: "warning" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "home.editBookingOptionForm.bookingOptionHidden"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c(
                                          "vs-dropdown",
                                          { staticClass: "mx-1" },
                                          [
                                            _c("vs-button", {
                                              attrs: {
                                                color: "dark",
                                                type: "border",
                                                icon: "more_horiz",
                                              },
                                            }),
                                            _c(
                                              "vs-dropdown-menu",
                                              [
                                                bookingOption.has_seasonal_pricing
                                                  ? _c(
                                                      "vs-dropdown-item",
                                                      [
                                                        _c(
                                                          "vs-button",
                                                          {
                                                            staticClass: "px-2",
                                                            attrs: {
                                                              type: "flat",
                                                              color: "dark",
                                                              "icon-pack":
                                                                "FA5",
                                                              icon: "fas fa-dollar-sign",
                                                              to: {
                                                                name: "custom-pricings",
                                                                params: {
                                                                  option_id:
                                                                    bookingOption.id,
                                                                },
                                                                hash: "#seasonalPricings",
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "home.seasonalPricings"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                bookingOption.has_days_pricing
                                                  ? _c(
                                                      "vs-dropdown-item",
                                                      [
                                                        _c(
                                                          "vs-button",
                                                          {
                                                            staticClass: "px-2",
                                                            attrs: {
                                                              type: "flat",
                                                              color: "dark",
                                                              "icon-pack":
                                                                "FA5",
                                                              icon: "fas fa-dollar-sign",
                                                              to: {
                                                                name: "custom-pricings",
                                                                params: {
                                                                  option_id:
                                                                    bookingOption.id,
                                                                },
                                                                hash: "#daysPricings",
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "home.daysPricings"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "vs-dropdown-item",
                                                  [
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "mt-2 px-2",
                                                        attrs: {
                                                          "icon-pack": "FA5",
                                                          icon: "far fa-copy",
                                                          type: "flat",
                                                          color: "dark",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.duplicateBookingOption(
                                                              bookingOption
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "home.editBookingOptionForm.duplicateBookingOption"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "vs-dropdown-item",
                                                  [
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "mt-2 px-2",
                                                        attrs: {
                                                          "icon-pack": "FA5",
                                                          icon: "far fa-trash",
                                                          type: "flat",
                                                          color: "danger",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.deleteBookingOptionActive = true
                                                            _vm.bookingOptionToDelete =
                                                              bookingOption
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "home.editBookingOptionForm.deleteBookingOption"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("vs-button", {
                                          attrs: {
                                            color: "primary",
                                            type: "border",
                                            icon: "edit",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editBookingOption(
                                                bookingOption
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("hr"),
                              ]),
                              _c("div", {}, [
                                _c("div", { staticClass: "vx-row px-2 py-5" }, [
                                  _c("div", { staticClass: "vx-col w-2/3" }, [
                                    _c("span", [
                                      _vm._v(_vm._s(bookingOption.name)),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "font-medium" },
                                      [
                                        _vm._v(
                                          _vm._s(bookingOption.description)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "vx-col w-1/3" },
                                    [
                                      _c(
                                        "vs-tooltip",
                                        {
                                          attrs: {
                                            text: _vm.$t(
                                              "home.editBookingOptionForm.taxApplied"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "small",
                                            { staticClass: "font-medium" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "home.editBookingOptionForm.thePrice"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                bookingOption.is_taxable_final
                                                  ? Math.round(
                                                      bookingOption.price * 1.15
                                                    ).toLocaleString()
                                                  : bookingOption.price.toLocaleString()
                                              ) +
                                                " " +
                                                _vm._s(_vm.$t("SAR"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              bookingOption.event_start_date_str ||
                              bookingOption.bookings_count_per_size_str
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "container mt-2 text-center flex-1 justify-center items-center",
                                    },
                                    [
                                      _c("hr"),
                                      _c("div", { staticClass: "px-2 mt-5" }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              background: "#b3b3b3",
                                              "border-radius": "20px",
                                              color: "white",
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  bookingOption.bookings_count_per_size_str
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      bookingOption.event_start_date_str
                                        ? _c(
                                            "div",
                                            { staticClass: "px-2 mt-5" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "border-radius": "20px",
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        bookingOption.event_start_date_str
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm.bookingOptions && _vm.bookingOptions.length == 0
            ? _c(
                "div",
                {
                  staticClass:
                    "row w-full mt-10 lg:mt-5 flex flex-wrap justify-center",
                },
                [
                  _c("h5", { staticClass: "mt-3 mr-2 text-center gray-note" }, [
                    _vm._v(
                      _vm._s(_vm.$t("bookingOptionPreview.noBookingOptions"))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "vs-popup",
        {
          staticClass: "holamundo",
          attrs: {
            title: _vm.currentBookingOption.name,
            active: _vm.currentBookingOptionIsActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.currentBookingOptionIsActive = $event
            },
          },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap" },
              [
                _c(
                  "vs-row",
                  { staticClass: "w-full" },
                  [
                    _c("image-slider", {
                      attrs: { images: _vm.currentBookingOption.images },
                    }),
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  { staticClass: "w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "mt-5 w-full pr-2",
                      attrs: {
                        readonly: "",
                        label: _vm.$t("bookingOptionPreview.description"),
                        name: "bookingOptionDescription",
                      },
                      model: {
                        value: _vm.currentBookingOption.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentBookingOption, "description", $$v)
                        },
                        expression: "currentBookingOption.description",
                      },
                    }),
                    _c("vs-textarea", {
                      staticClass: "mt-5",
                      attrs: {
                        readonly: "",
                        label: _vm.$t("bookingOptionPreview.description"),
                      },
                      model: {
                        value: _vm.currentBookingOption.detailedDescription,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentBookingOption,
                            "detailedDescription",
                            $$v
                          )
                        },
                        expression: "currentBookingOption.detailedDescription",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col w-full md:mb-2 mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "m-0 flex flex-col text-left items-start" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-col" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mt-2",
                              attrs: { disabled: "", name: "is_separated" },
                              model: {
                                value:
                                  _vm.currentBookingOption
                                    .later_deposit_payment_enabled,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentBookingOption,
                                    "later_deposit_payment_enabled",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentBookingOption.later_deposit_payment_enabled",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "bookingOptionPreview.laterDepositAllowed"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("booking-option-form", {
        attrs: {
          editBookingOptionActive: _vm.editBookingOptionActive,
          currentBookingOption: _vm.currentBookingOption,
          isEditBookingOption: _vm.isEditBookingOption,
          actionType: _vm.actionType,
        },
        on: {
          "update:editBookingOptionActive": function ($event) {
            _vm.editBookingOptionActive = $event
          },
          "update:edit-booking-option-active": function ($event) {
            _vm.editBookingOptionActive = $event
          },
          updateCurrentBookingOption: function ($event) {
            _vm.currentBookingOption = $event
          },
        },
      }),
      _c(
        "vs-prompt",
        {
          staticClass: "export-options",
          attrs: {
            title: "",
            "accept-text": _vm.$t("home.editBookingOptionPrompt.accept"),
            "cancel-text": _vm.$t("home.editBookingOptionPrompt.cancel"),
            active: _vm.editBookingOptionPromptActive,
          },
          on: {
            accept: function ($event) {
              _vm.editBookingOptionActive = true
            },
            "update:active": function ($event) {
              _vm.editBookingOptionPromptActive = $event
            },
          },
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("home.editBookingOptionPrompt.message")) + " "
          ),
        ]
      ),
      _vm.bookingOptionToDelete
        ? _c(
            "vs-prompt",
            {
              attrs: {
                title: _vm.$t("home.editBookingOptionForm.delete.promptHeader"),
                color: "danger",
                "accept-text": _vm.$t(
                  "home.editBookingOptionForm.delete.accept"
                ),
                "cancel-text": _vm.$t(
                  "home.editBookingOptionForm.delete.cancel"
                ),
                active: _vm.deleteBookingOptionActive,
              },
              on: {
                accept: _vm.deleteBookingOption,
                cancel: function ($event) {
                  _vm.bookingOptionToDelete = null
                },
                close: function ($event) {
                  _vm.bookingOptionToDelete = null
                },
                "update:active": function ($event) {
                  _vm.deleteBookingOptionActive = $event
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("home.editBookingOptionForm.delete.message", {
                      bookingOptionName: _vm.bookingOptionToDelete.name,
                    })
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }