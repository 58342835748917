var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex w-full justify-end my-3" },
        [
          _c(
            "vs-button",
            { attrs: { icon: "add" }, on: { click: _vm.addService } },
            [_vm._v(_vm._s(_vm.$t("Add")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5" },
        _vm._l(_vm.services, function (service) {
          return _c(
            "div",
            { key: service.id },
            [
              _c("vs-card", { attrs: { "fixed-height": "" } }, [
                _c("div", { attrs: { slot: "media" }, slot: "media" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.imgsLoaded,
                        expression: "imgsLoaded",
                      },
                    ],
                    staticClass: "object-cover h-48 rounded-t-lg",
                    attrs: { src: _vm.imagesURL + "/" + service.main_image },
                    on: { load: _vm.onImgLoad },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.imgsLoaded,
                          expression: "!imgsLoaded",
                        },
                      ],
                      staticClass: "object-cover h-48 rounded-t-lg",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center w-full h-48 rounded sm:w-96",
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "w-10 h-10",
                              attrs: {
                                "aria-hidden": "true",
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "currentColor",
                                viewBox: "0 0 20 18",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { slot: "extra-content" }, slot: "extra-content" },
                  [
                    _c("div", { staticClass: "container" }, [
                      _c(
                        "div",
                        { staticClass: "vx-row justify-end px-2 pt-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c(
                                "vs-dropdown",
                                {
                                  staticClass: "mx-1",
                                  attrs: { "vs-trigger-click": "" },
                                },
                                [
                                  _c("vs-button", {
                                    attrs: {
                                      color: "dark",
                                      type: "border",
                                      icon: "more_horiz",
                                    },
                                  }),
                                  _c(
                                    "vs-dropdown-menu",
                                    [
                                      _c(
                                        "vs-dropdown-item",
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "mt-2 px-2",
                                              attrs: {
                                                "icon-pack": "FA5",
                                                icon: "far fa-copy",
                                                type: "flat",
                                                color: "dark",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.duplicateService(
                                                    service
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "home.serviceForm.duplicateService"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-dropdown-item",
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "mt-2 px-2",
                                              attrs: {
                                                "icon-pack": "FA5",
                                                icon: "far fa-trash",
                                                type: "flat",
                                                color: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.deleteServicePromptActive = true
                                                  _vm.selectedService = service
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "home.serviceForm.deleteService"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("vs-button", {
                                attrs: {
                                  color: "primary",
                                  type: "border",
                                  icon: "edit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editService(service)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("hr"),
                    ]),
                    _c("div", {}, [
                      _c("div", { staticClass: "vx-row px-2 py-5" }, [
                        _c("div", { staticClass: "vx-col w-2/3" }, [
                          _c("span", [_vm._v(_vm._s(service.name))]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", { staticClass: "font-medium" }, [
                            _vm._v(_vm._s(service.description)),
                          ]),
                        ]),
                        _c("div", { staticClass: "vx-col w-1/3" }, [
                          _c("small", { staticClass: "font-medium" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("home.editBookingOptionForm.thePrice")
                              )
                            ),
                          ]),
                          _c("br"),
                          service.pricing_type == "fixed"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(service.final_price.toLocaleString()) +
                                    " " +
                                    _vm._s(_vm.$t("SAR"))
                                ),
                              ])
                            : _c("span", [
                                service.first_input_price > 0
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          Math.round(
                                            service.first_input_price * 1.15
                                          ).toLocaleString()
                                        ) +
                                          " " +
                                          _vm._s(_vm.$t("SAR")) +
                                          " "
                                      ),
                                      _c(
                                        "small",
                                        { staticClass: "font-medium" },
                                        [
                                          _vm._v(
                                            "/ " + _vm._s(_vm.$t("person"))
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                service.second_input_price > 0
                                  ? _c("br")
                                  : _vm._e(),
                                service.second_input_price > 0
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          Math.round(
                                            service.second_input_price * 1.15
                                          ).toLocaleString()
                                        ) +
                                          " " +
                                          _vm._s(_vm.$t("SAR")) +
                                          " "
                                      ),
                                      _c(
                                        "small",
                                        { staticClass: "font-medium" },
                                        [_vm._v("/ " + _vm._s(_vm.$t("hour")))]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                          _c("br"),
                          _c("small", { staticClass: "font-medium" }, [
                            _vm._v("(" + _vm._s(_vm.$t("vatIncluded")) + ")"),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c("vs-pagination", {
        staticClass: "mt-5",
        attrs: { total: Math.ceil(_vm.tableConfig.totalPages) },
        on: { input: _vm.handleChangePage },
        model: {
          value: _vm.currentx,
          callback: function ($$v) {
            _vm.currentx = $$v
          },
          expression: "currentx",
        },
      }),
      _c("service-form-popup", {
        attrs: {
          isServiceFormActive: _vm.isServiceFormActive,
          serviceProp: _vm.selectedService,
          actionType: _vm.actionType,
        },
        on: {
          "update:isServiceFormActive": function ($event) {
            _vm.isServiceFormActive = $event
          },
          "update:is-service-form-active": function ($event) {
            _vm.isServiceFormActive = $event
          },
        },
      }),
      _vm.selectedService
        ? _c(
            "vs-prompt",
            {
              attrs: {
                title: _vm.$t("home.serviceForm.delete.promptHeader"),
                color: "danger",
                "accept-text": _vm.$t("home.serviceForm.delete.accept"),
                "cancel-text": _vm.$t("home.serviceForm.delete.cancel"),
                active: _vm.deleteServicePromptActive,
              },
              on: {
                accept: _vm.deleteBookingOption,
                cancel: function ($event) {
                  _vm.selectedService = null
                },
                close: function ($event) {
                  _vm.selectedService = null
                },
                "update:active": function ($event) {
                  _vm.deleteServicePromptActive = $event
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("home.serviceForm.delete.message", {
                      serviceName: _vm.selectedService.name,
                    })
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }