var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-button",
    {
      staticClass: "mb-3 mt-4 ml-2",
      attrs: { color: "#eb5424" },
      on: { click: _vm.registerAuth0 },
    },
    [_vm._v("Auth0")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }