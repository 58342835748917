var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-login" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col hidden lg:block lg:w-1/2" },
                      [
                        _c("img", {
                          staticClass: "mx-auto",
                          attrs: {
                            src: require("@/assets/images/pages/login.png"),
                            alt: "login",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "px-8 pt-8 login-tabs-container" },
                          [
                            _c("div", { staticClass: "vx-card__title mb-4" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v("Login"),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "Welcome back, please login to your account."
                                ),
                              ]),
                            ]),
                            _c(
                              "vs-tabs",
                              [
                                _c(
                                  "vs-tab",
                                  { attrs: { label: "JWT" } },
                                  [_c("login-jwt")],
                                  1
                                ),
                                _c(
                                  "vs-tab",
                                  { attrs: { label: "Firebase" } },
                                  [_c("login-firebase")],
                                  1
                                ),
                                _c(
                                  "vs-tab",
                                  { attrs: { label: "Auth0" } },
                                  [_c("login-auth0")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }