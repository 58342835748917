var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { attrs: { title: _vm.$t("onBoarding.services") } }, [
        _c("div", { staticClass: "container-fluid mt-2 px-4" }, [
          _c(
            "div",
            { staticClass: "vx-row justify-end" },
            [
              _c(
                "vs-button",
                { attrs: { icon: "add" }, on: { click: _vm.addService } },
                [_vm._v(_vm._s(_vm.$t("Add")))]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "container-fluid mt-2 px-0 mb-5" }, [
          _vm.services && _vm.services.length > 0
            ? _c(
                "div",
                { staticClass: "grid lg:grid-cols-3 md:grid-cols-2 gap-3" },
                _vm._l(_vm.services, function (service) {
                  return _c(
                    "div",
                    { key: service.id, staticClass: "flex-none w-20" },
                    [
                      _c(
                        "vs-card",
                        {
                          staticClass: "cardx my-5",
                          attrs: { "fixed-height": "" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "media" }, slot: "media" },
                            [
                              _c("img", {
                                staticClass: "object-cover h-48",
                                attrs: {
                                  src: _vm.imagesURL + "/" + service.main_image,
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "extra-content" },
                              slot: "extra-content",
                            },
                            [
                              _c("div", { staticClass: "container" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-row justify-end px-2 pt-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c(
                                          "vs-dropdown",
                                          { staticClass: "mx-1" },
                                          [
                                            _c("vs-button", {
                                              attrs: {
                                                color: "dark",
                                                type: "border",
                                                icon: "more_horiz",
                                              },
                                            }),
                                            _c(
                                              "vs-dropdown-menu",
                                              [
                                                _c(
                                                  "vs-dropdown-item",
                                                  [
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "mt-2 px-2",
                                                        attrs: {
                                                          "icon-pack": "FA5",
                                                          icon: "far fa-copy",
                                                          type: "flat",
                                                          color: "dark",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.duplicateService(
                                                              service
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "home.serviceForm.duplicateService"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "vs-dropdown-item",
                                                  [
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "mt-2 px-2",
                                                        attrs: {
                                                          "icon-pack": "FA5",
                                                          icon: "far fa-trash",
                                                          type: "flat",
                                                          color: "danger",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.deleteBookingOptionActive = true
                                                            _vm.currentService =
                                                              service
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "home.serviceForm.deleteService"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("vs-button", {
                                          attrs: {
                                            color: "primary",
                                            type: "border",
                                            icon: "edit",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editService(service)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("hr"),
                              ]),
                              _c("div", {}, [
                                _c("div", { staticClass: "vx-row px-2 py-5" }, [
                                  _c("div", { staticClass: "vx-col w-2/3" }, [
                                    _c("span", [_vm._v(_vm._s(service.name))]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "font-medium" },
                                      [_vm._v(_vm._s(service.description))]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vx-col w-1/3" }, [
                                    _c(
                                      "small",
                                      { staticClass: "font-medium" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.thePrice"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                    service.pricing_type == "fixed"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              service.final_price.toLocaleString()
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ])
                                      : _c("span", [
                                          service.first_input_price > 0
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    Math.round(
                                                      service.first_input_price *
                                                        1.15
                                                    ).toLocaleString()
                                                  ) +
                                                    " " +
                                                    _vm._s(_vm.$t("SAR")) +
                                                    " "
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "font-medium",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "/ " +
                                                        _vm._s(_vm.$t("person"))
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                          service.second_input_price > 0
                                            ? _c("br")
                                            : _vm._e(),
                                          service.second_input_price > 0
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    Math.round(
                                                      service.second_input_price *
                                                        1.15
                                                    ).toLocaleString()
                                                  ) +
                                                    " " +
                                                    _vm._s(_vm.$t("SAR")) +
                                                    " "
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "font-medium",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "/ " +
                                                        _vm._s(_vm.$t("hour"))
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                    _c("br"),
                                    _c(
                                      "small",
                                      { staticClass: "font-medium" },
                                      [
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.$t("vatIncluded")) +
                                            ")"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm.services && _vm.services.length == 0
            ? _c(
                "div",
                {
                  staticClass:
                    "row w-full mt-10 lg:mt-5 flex flex-wrap justify-center",
                },
                [
                  _c("h5", { staticClass: "mt-3 mr-2 text-center gray-note" }, [
                    _vm._v(
                      _vm._s(_vm.$t("home.servicesTable.noServicesAvailable"))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _c("add-on-form", {
        attrs: {
          serviceActive: _vm.serviceActive,
          currentService: _vm.currentService,
          isEdit: _vm.isEdit,
          actionType: _vm.actionType,
        },
        on: {
          "update:serviceActive": function ($event) {
            _vm.serviceActive = $event
          },
          "update:service-active": function ($event) {
            _vm.serviceActive = $event
          },
          updatecurrentService: function ($event) {
            _vm.currentService = $event
          },
        },
      }),
      _vm.currentService
        ? _c(
            "vs-prompt",
            {
              attrs: {
                title: _vm.$t("home.serviceForm.delete.promptHeader"),
                color: "danger",
                "accept-text": _vm.$t(
                  "home.editBookingOptionForm.delete.accept"
                ),
                "cancel-text": _vm.$t(
                  "home.editBookingOptionForm.delete.cancel"
                ),
                active: _vm.deleteBookingOptionActive,
              },
              on: {
                accept: _vm.deleteBookingOption,
                cancel: function ($event) {
                  _vm.currentService = null
                },
                close: function ($event) {
                  _vm.currentService = null
                },
                "update:active": function ($event) {
                  _vm.deleteBookingOptionActive = $event
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("home.serviceForm.delete.message", {
                      serviceName: _vm.currentService.name,
                    })
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }