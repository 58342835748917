var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "holamundo",
          attrs: {
            title: _vm.$t("businessEventsList.proposalForm.sendProposal"),
            active: _vm.isActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.isActive = $event
            },
            close: _vm.closePopup,
          },
        },
        [
          _vm.currentBusinessEvent
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap pt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mb-3 pr-2",
                      attrs: {
                        type: "number",
                        label: _vm.$t("businessEventsList.proposalForm.price"),
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.proposal.price,
                        callback: function ($$v) {
                          _vm.$set(_vm.proposal, "price", $$v)
                        },
                        expression: "proposal.price",
                      },
                    }),
                    _c("vs-textarea", {
                      staticClass: "w-full mb-3 mr-2",
                      attrs: {
                        label: _vm.$t(
                          "businessEventsList.proposalForm.details"
                        ),
                        height: "150px",
                      },
                      model: {
                        value: _vm.proposal.details,
                        callback: function ($$v) {
                          _vm.$set(_vm.proposal, "details", $$v)
                        },
                        expression: "proposal.details",
                      },
                    }),
                    _c("div", { staticClass: "vx-col w-full mb-2 pl-2" }, [
                      _c("div", { staticClass: "container mt-2" }, [
                        _c("div", { staticClass: "vx-row" }, [
                          _c("label", { staticClass: "v-select-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "businessEventsList.proposalForm.attachments"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "vx-row" }, [
                          _c("input", {
                            ref: "attachments",
                            staticClass: "w-full",
                            attrs: { type: "file", multiple: "multiple" },
                          }),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-row w-full mb-5 justify-end" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "text-sm",
                            attrs: { icon: "check", color: "success" },
                            on: { click: _vm.submitForm },
                          },
                          [_vm._v(_vm._s(_vm.$t("send")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }