var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "bg-white rounded-lg shadow-md py-10 px-5" }, [
      !_vm.is_phone_updated
        ? _c("div", [
            _c(
              "div",
              { staticClass: "flex flex-wrap w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full lg:w-1/2 mt-5 px-2",
                  attrs: { label: _vm.$t("full_name") },
                  model: {
                    value: _vm.userInfo.full_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.userInfo, "full_name", $$v)
                    },
                    expression: "userInfo.full_name",
                  },
                }),
                _c("vs-input", {
                  staticClass: "w-full lg:w-1/2 mt-5 px-2",
                  attrs: { label: _vm.$t("email"), dir: "ltr" },
                  model: {
                    value: _vm.userInfo.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.userInfo, "email", $$v)
                    },
                    expression: "userInfo.email",
                  },
                }),
                _c("vs-input", {
                  staticClass: "w-full lg:w-1/2 mt-5 px-2",
                  attrs: { label: _vm.$t("phone_number"), dir: "ltr" },
                  model: {
                    value: _vm.userInfo.phone_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.userInfo, "phone_number", $$v)
                    },
                    expression: "userInfo.phone_number",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex justify-end mt-10 px-2" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { disabled: _vm.is_sending_data },
                    on: { click: _vm.update },
                  },
                  [_vm._v(_vm._s(_vm.$t("updateInfo")))]
                ),
              ],
              1
            ),
          ])
        : _c("div", [
            _c(
              "div",
              { staticClass: "flex flex-wrap w-full justify-center" },
              [
                _c("vs-input", {
                  staticClass: "w-full sm:w-1/2 mt-5",
                  attrs: { label: _vm.$t("vc_enterance") },
                  model: {
                    value: _vm.vc,
                    callback: function ($$v) {
                      _vm.vc = $$v
                    },
                    expression: "vc",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "flex justify-center mt-5" }, [
              _c("span", { staticClass: "text-3xl" }, [
                _vm._v(_vm._s(_vm.countdown)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex justify-center mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "w-1/3",
                    attrs: {
                      disabled: _vm.countdown == 0 || _vm.is_sending_data,
                    },
                    on: { click: _vm.verifyVc },
                  },
                  [_vm._v(_vm._s(_vm.$t("verify")))]
                ),
              ],
              1
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }