import { render, staticRenderFns } from "./CustomerInformationBooking.vue?vue&type=template&id=61382a03"
import script from "./CustomerInformationBooking.vue?vue&type=script&lang=js"
export * from "./CustomerInformationBooking.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/qosoor-agent-web-app/qosoor-agent-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61382a03')) {
      api.createRecord('61382a03', component.options)
    } else {
      api.reload('61382a03', component.options)
    }
    module.hot.accept("./CustomerInformationBooking.vue?vue&type=template&id=61382a03", function () {
      api.rerender('61382a03', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/qosoor-components/CustomerInformationBooking.vue"
export default component.exports