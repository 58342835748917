var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-table",
        { attrs: { data: ["test", "test"] } },
        [
          _c("template", { slot: "header" }, [
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.$t("home.editBookingOptionForm.finalPricesTable.header")
                )
              ),
            ]),
          ]),
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "home.editBookingOptionForm.finalPricesTable.pricing"
                    )
                  )
                ),
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t("home.editBookingOptionForm.finalPricesTable.range")
                  )
                ),
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "home.editBookingOptionForm.finalPricesTable.beforeTax"
                    )
                  )
                ),
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t("home.editBookingOptionForm.finalPricesTable.tax")
                  )
                ),
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "home.editBookingOptionForm.finalPricesTable.totalPrice"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
          _vm._l(_vm.finalPrices, function (priceRange, index) {
            return [
              _c(
                "vs-tr",
                { key: `test-${index}` },
                [
                  _c("vs-td", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "home.editBookingOptionForm.finalPricesTable.rangeManPrice"
                        )
                      )
                    ),
                  ]),
                  _c("vs-td", [
                    _vm._v(
                      _vm._s(priceRange.from_first_input_count) +
                        " " +
                        _vm._s(_vm.$t("to")) +
                        " " +
                        _vm._s(
                          priceRange.to_first_input_count
                            ? priceRange.to_first_input_count
                            : "∞"
                        )
                    ),
                  ]),
                  _c("vs-td", [
                    _vm._v(
                      _vm._s(priceRange.first_input_price) +
                        " " +
                        _vm._s(_vm.$t("SAR"))
                    ),
                  ]),
                  _c("vs-td", [_vm._v("ssss " + _vm._s(_vm.$t("SAR")))]),
                  _c("vs-td", [_vm._v("ssss " + _vm._s(_vm.$t("SAR")))]),
                ],
                1
              ),
              _c(
                "vs-tr",
                { key: `testssss-${index}` },
                [
                  _c("vs-td", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "home.editBookingOptionForm.finalPricesTable.rangeWomanPrice"
                        )
                      )
                    ),
                  ]),
                  _c("vs-td", [
                    _vm._v(
                      _vm._s(priceRange.from_second_input_count) +
                        " " +
                        _vm._s(_vm.$t("to")) +
                        " " +
                        _vm._s(
                          priceRange.to_second_input_count
                            ? priceRange.to_second_input_count
                            : "∞"
                        )
                    ),
                  ]),
                  _c("vs-td", [
                    _vm._v(
                      _vm._s(priceRange.second_input_price) +
                        " " +
                        _vm._s(_vm.$t("SAR"))
                    ),
                  ]),
                  _c("vs-td", [_vm._v("ssss " + _vm._s(_vm.$t("SAR")))]),
                  _c("vs-td", [_vm._v("ssss " + _vm._s(_vm.$t("SAR")))]),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }