var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-login" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c(
            "vs-alert",
            {
              attrs: {
                active: _vm.errorAlertIsShown,
                closable: "",
                "icon-pack": "feather",
                color: "danger",
                "close-icon": "icon-x",
              },
              on: {
                "update:active": function ($event) {
                  _vm.errorAlertIsShown = $event
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
          ),
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col hidden lg:block lg:w-1/2" },
                      [
                        _c("div", { staticClass: "grid grid-rows-7" }, [
                          _c("img", {
                            staticClass: "w-1/2 row-span-4 justify-self-center",
                            attrs: {
                              src: require("@/assets/images/logo/logo-orange.png"),
                              alt: "login",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-primary row-span-2 justify-self-center mt-10 text-9xl",
                              staticStyle: { "font-size": "50px" },
                            },
                            [_vm._v(_vm._s(_vm.$t("appName")))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-primary row-span-1 justify-self-center text-9xl",
                              staticStyle: { "font-size": "20px" },
                            },
                            [_vm._v(_vm._s(_vm.$t("appSlug")))]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "px-8 pt-8 login-tabs-container" },
                          [
                            _c("div", { staticClass: "vx-card__title mb-4" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("login") || "تسجيل الدخول")
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("welcomeLogin") ||
                                      "أهلا بك مجددا، قم بإدخال رقمك للدخول"
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "vs-tabs",
                              [
                                _c(
                                  "vs-tab",
                                  { attrs: { label: "" } },
                                  [
                                    _c("login", {
                                      on: { error: _vm.showErrorAlert },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }