var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.customerQuestionProp
      ? _c(
          "div",
          [
            _c(
              "vs-popup",
              {
                staticClass: "holamundo",
                attrs: {
                  title: _vm.$t("questions.questionAboutPopupHead", {
                    bookingOptionName: _vm.customerQuestion.booking_option.name,
                  }),
                  active: _vm.isActive,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.isActive = $event
                  },
                  close: _vm.closePopup,
                },
              },
              [
                _c("div", { staticClass: "grid grid-cols-1" }, [
                  _c(
                    "div",
                    { staticClass: "w-full my-3" },
                    [
                      _vm.customerQuestion.status == "visible_to_customers"
                        ? _c(
                            "vs-chip",
                            { attrs: { transparent: "", color: "success" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("questions.visibleToCustomers")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "vs-chip",
                            { attrs: { transparent: "", color: "warning" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("questions.notVisibleToCustomers")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("vs-textarea", {
                        staticClass: "w-full disabled-textarea",
                        attrs: {
                          label: _vm.$t("questions.form.question"),
                          height: "150px",
                          disabled: true,
                        },
                        model: {
                          value: _vm.customerQuestion.question,
                          callback: function ($$v) {
                            _vm.$set(_vm.customerQuestion, "question", $$v)
                          },
                          expression: "customerQuestion.question",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c(
                        "vx-tooltip",
                        {
                          attrs: {
                            text: _vm.$t("questions.form.answerTooltip"),
                          },
                        },
                        [
                          _c("vs-textarea", {
                            staticClass: "w-full",
                            class: {
                              "disabled-textarea":
                                !_vm.customerQuestion.allow_edit_answer,
                            },
                            attrs: {
                              label: _vm.$t("questions.form.answer"),
                              height: "150px",
                              disabled: !_vm.customerQuestion.allow_edit_answer,
                            },
                            model: {
                              value: _vm.customerQuestion.answer,
                              callback: function ($$v) {
                                _vm.$set(_vm.customerQuestion, "answer", $$v)
                              },
                              expression: "customerQuestion.answer",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "w-full mt-5 mb-10" }, [
                    _c(
                      "div",
                      { staticClass: "flex w-full justify-end" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mx-2",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-check",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.submitForm.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("save")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }