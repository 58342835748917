var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-wrap pt-0" },
    [
      _c(
        "div",
        { staticClass: "mt-5 w-1/2 pr-2" },
        [
          _c("label", { staticClass: "v-select-label" }, [
            _vm._v(_vm._s(_vm.$t("addBookingSidebar.bookingOptions"))),
          ]),
          _c("v-select", {
            staticClass: "v-select-background",
            attrs: {
              value: _vm.bookingClone.option_id,
              options: _vm.bookingOptionsList,
              label: "name",
              reduce: (name) => name.id,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: { input: _vm.setBookingOption },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-5 w-1/2 pr-2" },
        [
          _c("label", { staticClass: "v-select-label" }, [
            _vm._v(_vm._s(_vm.$t("addBookingSidebar.eventType"))),
          ]),
          _c("v-select", {
            staticClass: "v-select-background",
            attrs: {
              value: _vm.bookingClone.event_id,
              options: _vm.eventTypesList,
              label: "arabic_name",
              reduce: (arabic_name) => arabic_name.id,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: { input: _vm.setEventType },
          }),
        ],
        1
      ),
      _vm.selectedOptionServicesList.length > 0
        ? _c("div", { staticClass: "w-full my-5" }, [
            _c("span", { staticClass: "text-lg" }, [
              _vm._v(_vm._s(_vm.$t("addBookingSidebar.extraServices"))),
            ]),
            _c(
              "div",
              { staticClass: "flex flex-wrap mt-2" },
              _vm._l(_vm.selectedOptionServicesList, function (service) {
                return _c(
                  "vs-checkbox",
                  {
                    key: `services-${service.id}`,
                    attrs: { "vs-value": service.id },
                    on: { change: _vm.serviceSelected },
                    model: {
                      value: _vm.selectedServices,
                      callback: function ($$v) {
                        _vm.selectedServices = $$v
                      },
                      expression: "selectedServices",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(service.name) +
                        " | " +
                        _vm._s(service.final_price) +
                        " " +
                        _vm._s(_vm.$t("SAR"))
                    ),
                  ]
                )
              }),
              1
            ),
          ])
        : _vm._e(),
      _vm.bookingClone.event_type && _vm.bookingClone.event_type.guests_inputs
        ? _c(
            "div",
            { staticClass: "flex flex-wrap mt-5 w-full" },
            _vm._l(
              _vm.bookingClone.event_type.guests_inputs,
              function (guests_input) {
                return _c("vs-input", {
                  key: guests_input.key,
                  staticClass: "w-1/2 pr-2",
                  attrs: { label: guests_input.name, type: "number" },
                  on: { change: _vm.fillForm, input: _vm.guestInputChanged },
                  model: {
                    value: _vm.bookingClone[guests_input.key],
                    callback: function ($$v) {
                      _vm.$set(_vm.bookingClone, guests_input.key, $$v)
                    },
                    expression: "bookingClone[guests_input.key]",
                  },
                })
              }
            ),
            1
          )
        : _vm._e(),
      !_vm.isTimeBasedBookingOption &&
      !(_vm.selectedOption && _vm.selectedOption.is_time_slotted)
        ? _c(
            "div",
            { staticClass: "mt-5 w-full" },
            [
              _c(
                "vs-checkbox",
                {
                  staticClass: "mt-2",
                  attrs: { name: "isManyDays" },
                  on: { change: _vm.fillForm },
                  model: {
                    value: _vm.isManyDays,
                    callback: function ($$v) {
                      _vm.isManyDays = $$v
                    },
                    expression: "isManyDays",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("addBookingSidebar.isManyDays")))]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isTimeBasedBookingOption
        ? _c("div", { staticClass: "mt-3 w-full flex flex-wrap" }, [
            _c(
              "div",
              { staticClass: "w-1/2 pr-2" },
              [
                _c("small", { staticClass: "date-label" }, [
                  _vm._v(_vm._s(_vm.$t("addBookingSidebar.startDate"))),
                ]),
                _c("datepicker", {
                  attrs: {
                    format: "dd-MM-yyyy",
                    language: _vm.$vs.rtl ? _vm.langAr : _vm.langEn,
                    name: "start-date",
                  },
                  on: { selected: _vm.fillForm, closed: _vm.checkValidEndDate },
                  model: {
                    value: _vm.bookingClone.start_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.bookingClone, "start_date", $$v)
                    },
                    expression: "bookingClone.start_date",
                  },
                }),
              ],
              1
            ),
            _vm.isManyDays
              ? _c(
                  "div",
                  { staticClass: "w-1/2 pr-2" },
                  [
                    _c("small", { staticClass: "date-label" }, [
                      _vm._v(_vm._s(_vm.$t("addBookingSidebar.endDate"))),
                    ]),
                    _c("datepicker", {
                      attrs: {
                        format: "dd-MM-yyyy",
                        language: _vm.$vs.rtl ? _vm.langAr : _vm.langEn,
                        name: "end-date",
                        disabledDates: _vm.disabledDatesTo,
                      },
                      on: {
                        selected: _vm.fillForm,
                        closed: _vm.checkValidEndDate,
                      },
                      model: {
                        value: _vm.bookingClone.end_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.bookingClone, "end_date", $$v)
                        },
                        expression: "bookingClone.end_date",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.selectedOption && _vm.selectedOption.is_time_slotted
        ? _c("div", { staticClass: "w-full mt-3 mb-1" }, [
            _c("span", { staticClass: "text-lg" }, [
              _vm._v(
                _vm._s(_vm.$t("addBookingSidebar.timeSlots.eventTime")) + ":"
              ),
            ]),
          ])
        : _vm._e(),
      _vm.selectedOption && _vm.selectedOption.is_time_slotted
        ? _c(
            "div",
            { staticClass: "w-full flex flex-wrap" },
            [
              _vm._l(_vm.time_slots, function (ts, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "p-5 m-3 border shadow hover:shadow-md rounded-lg transition-colors duration-200 cursor-pointer hover:bg-[#f5da6e]",
                    class: {
                      "bg-primary text-white": _vm.selectedIndex === index,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTile(index, ts)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatTime(ts.start_time)) +
                        " -> " +
                        _vm._s(_vm.formatTime(ts.end_time)) +
                        " "
                    ),
                  ]
                )
              }),
              _c(
                "div",
                {
                  staticClass:
                    "p-5 m-3 border shadow hover:shadow-md rounded-lg transition-colors duration-200 cursor-pointer hover:bg-[#f5da6e]",
                  class: { "bg-primary text-white": _vm.isCustomTileSelected },
                  on: { click: _vm.selectCustomTile },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("addBookingSidebar.timeSlots.customTime")) +
                      " "
                  ),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.isCustomTileSelected
        ? _c(
            "div",
            {
              staticClass:
                "w-full grid grid-cols-1 lg:grid-cols-2 mt-5 p-5 border rounded shadow",
            },
            [
              _c("div", { staticClass: "col-span-2" }, [
                _c("h3", { staticClass: "mb-3 text-lg font-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("addBookingSidebar.timeSlots.enterCustomTime")
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-span-2 flex" }, [
                _c(
                  "div",
                  { staticClass: "mx-2" },
                  [
                    _c("vs-input", {
                      attrs: {
                        label: _vm.$t("addBookingSidebar.timeSlots.startTime"),
                        type: "time",
                      },
                      on: { change: _vm.fillForm },
                      model: {
                        value: _vm.bookingClone.start_time,
                        callback: function ($$v) {
                          _vm.$set(_vm.bookingClone, "start_time", $$v)
                        },
                        expression: "bookingClone.start_time",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mx-2" },
                  [
                    _c("vs-input", {
                      attrs: {
                        label: _vm.$t("addBookingSidebar.timeSlots.endTime"),
                        type: "time",
                      },
                      on: { change: _vm.fillForm },
                      model: {
                        value: _vm.bookingClone.end_time,
                        callback: function ($$v) {
                          _vm.$set(_vm.bookingClone, "end_time", $$v)
                        },
                        expression: "bookingClone.end_time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("vs-textarea", {
        staticClass: "mt-5 w-full",
        attrs: {
          label: _vm.$t("addBookingSidebar.customerNotes"),
          name: "customer-notes",
        },
        on: { change: _vm.fillForm },
        model: {
          value: _vm.bookingClone.customer_notes,
          callback: function ($$v) {
            _vm.$set(_vm.bookingClone, "customer_notes", $$v)
          },
          expression: "bookingClone.customer_notes",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }