var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.bookingOption
      ? _c("div", { staticClass: "grid grid-cols-1 lg:grid-cols-2 gap-5" }, [
          _c(
            "div",
            [
              _c(
                "ValidationObserver",
                {
                  ref: "slotsFormRef",
                  staticClass:
                    "grid grid-cols-4 bg-white p-10 rounded-lg shadow",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-4 mt-3 mb-10 flex" },
                    [
                      _c(
                        "vs-checkbox",
                        {
                          on: { change: _vm.toggleIsTimeSlotted },
                          model: {
                            value: _vm.is_time_slotted,
                            callback: function ($$v) {
                              _vm.is_time_slotted = $$v
                            },
                            expression: "is_time_slotted",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.is_time_slotted
                                ? _vm.$t("working_hours.deactivate_time_slots")
                                : _vm.$t("working_hours.activate_time_slots")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.is_time_slotted
                    ? _c("div", { staticClass: "col-span-4" }, [
                        _c("span", { staticClass: "text-lg lg:text-xl" }, [
                          _vm._v(
                            _vm._s(_vm.$t("working_hours.booking_duration")) +
                              ":"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.is_time_slotted
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-span-4 grid grid-cols-12 gap-5 mt-5",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-span-4 grid grid-cols-2 content-center",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("working_hours.duration")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-7 grid grid-cols-2" },
                            [
                              _c("vs-input", {
                                attrs: { type: "number", placeholder: "60" },
                                on: { input: _vm.checkChanges },
                                model: {
                                  value: _vm.duration,
                                  callback: function ($$v) {
                                    _vm.duration = $$v
                                  },
                                  expression: "duration",
                                },
                              }),
                              _c(
                                "vs-select",
                                {
                                  on: { change: _vm.checkChanges },
                                  model: {
                                    value: _vm.duration_unit,
                                    callback: function ($$v) {
                                      _vm.duration_unit = $$v
                                    },
                                    expression: "duration_unit",
                                  },
                                },
                                _vm._l(
                                  [
                                    {
                                      text_ar: "دقيقة",
                                      text_en: "Minutes",
                                      key: "min",
                                    },
                                    {
                                      text_ar: "ساعة",
                                      text_en: "Hours",
                                      key: "hour",
                                    },
                                  ],
                                  function (item, index) {
                                    return _c("vs-select-item", {
                                      key: index,
                                      attrs: {
                                        value: item.key,
                                        text:
                                          _vm.$i18n.locale == "ar"
                                            ? item.text_ar
                                            : item.text_en,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.is_time_slotted
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-span-4 grid grid-cols-12 gap-5 mt-5",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-span-4 grid grid-cols-2 content-center",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("working_hours.buffer")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-7 grid grid-cols-2" },
                            [
                              _c("vs-input", {
                                attrs: { type: "number", placeholder: "60" },
                                on: { input: _vm.checkChanges },
                                model: {
                                  value: _vm.buffer,
                                  callback: function ($$v) {
                                    _vm.buffer = $$v
                                  },
                                  expression: "buffer",
                                },
                              }),
                              _c(
                                "vs-select",
                                {
                                  on: { change: _vm.checkChanges },
                                  model: {
                                    value: _vm.buffer_unit,
                                    callback: function ($$v) {
                                      _vm.buffer_unit = $$v
                                    },
                                    expression: "buffer_unit",
                                  },
                                },
                                _vm._l(
                                  [
                                    {
                                      text_ar: "دقيقة",
                                      text_en: "Minutes",
                                      key: "min",
                                    },
                                    {
                                      text_ar: "ساعة",
                                      text_en: "Hours",
                                      key: "hour",
                                    },
                                  ],
                                  function (item, index) {
                                    return _c("vs-select-item", {
                                      key: index,
                                      attrs: {
                                        value: item.key,
                                        text:
                                          _vm.$i18n.locale == "ar"
                                            ? item.text_ar
                                            : item.text_en,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.is_time_slotted
                    ? _c("div", { staticClass: "col-span-4 mt-5" }, [
                        _c("span", { staticClass: "text-lg lg:text-xl" }, [
                          _vm._v(
                            _vm._s(_vm.$t("working_hours.available_days")) + ":"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.working_hours, function (working_hour, index) {
                    return _vm.is_time_slotted
                      ? _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "col-span-4 grid grid-cols-12 gap-5 my-5",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-span-4 grid grid-cols-2 content-center",
                              },
                              [
                                _c("vs-checkbox", {
                                  on: { change: _vm.checkChanges },
                                  model: {
                                    value: _vm.working_hours[index].is_enabled,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.working_hours[index],
                                        "is_enabled",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "working_hours[index].is_enabled",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-lg",
                                    class: {
                                      "line-through": !working_hour.is_enabled,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$i18n.locale == "ar"
                                            ? working_hour.day_ar
                                            : working_hour.day
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-span-7" }, [
                              working_hour.is_enabled
                                ? _c(
                                    "div",
                                    [
                                      _vm._l(
                                        working_hour.working_hours,
                                        function (wh, inIndex) {
                                          return _c("div", { key: inIndex }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex space-x-2 mt-2",
                                              },
                                              [
                                                _c("vs-input", {
                                                  directives: [
                                                    {
                                                      name: "mask",
                                                      rawName: "v-mask",
                                                      value: "##:##",
                                                      expression: "'##:##'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    placeholder: "Ex: 8:00",
                                                    "val-icon-danger": "error",
                                                    danger:
                                                      working_hour.error !=
                                                      null,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.validateWorkingTimes(
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: wh.start_time,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        wh,
                                                        "start_time",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "wh.start_time",
                                                  },
                                                }),
                                                _c("vs-input", {
                                                  directives: [
                                                    {
                                                      name: "mask",
                                                      rawName: "v-mask",
                                                      value: "##:##",
                                                      expression: "'##:##'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    placeholder: "Ex: 23:00",
                                                    "val-icon-danger": "error",
                                                    danger:
                                                      working_hour.error !=
                                                      null,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.validateWorkingTimes(
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: wh.end_time,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        wh,
                                                        "end_time",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "wh.end_time",
                                                  },
                                                }),
                                                _c("vs-button", {
                                                  attrs: {
                                                    color: "danger",
                                                    type: "border",
                                                    icon: "close",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeWorkingHour(
                                                        index,
                                                        inIndex
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ])
                                        }
                                      ),
                                      working_hour.error
                                        ? _c("div", { staticClass: "mt-4" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-sm text-danger",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$i18n.locale == "ar"
                                                        ? working_hour.error
                                                            .message_ar
                                                        : working_hour.error
                                                            .message_en
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("working_hours.inactive")
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]),
                            working_hour.is_enabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-1 flex flex-col justify-end",
                                  },
                                  [
                                    _c("vs-button", {
                                      attrs: {
                                        color: "success",
                                        type: "border",
                                        icon: "add",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addWorkingHour(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e()
                  }),
                  _vm.is_time_slotted
                    ? _c(
                        "div",
                        { staticClass: "col-span-4 flex justify-end mt-5" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "success", icon: "check" },
                              on: { click: _vm.save },
                            },
                            [_vm._v(_vm._s(_vm.$t("working_hours.save")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.is_time_slotted
                    ? _c(
                        "div",
                        { staticClass: "col-span-4 my-10 flex justify-center" },
                        [
                          _c("span", { staticClass: "text-xl text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "working_hours.activate_time_slotts_please"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm.is_time_slotted
            ? _c("div", [
                _c("div", { staticClass: "grid grid-cols-2 gap-3" }, [
                  _c("div", { staticClass: "col-span-1" }, [
                    _c(
                      "div",
                      [
                        _c("datepicker", {
                          attrs: {
                            inline: true,
                            format: "dd-MM-yyyy",
                            "disabled-dates": _vm.disabledDates,
                            language: _vm.$vs.rtl ? _vm.langAr : _vm.langEn,
                          },
                          on: { selected: _vm.dateSelected },
                          model: {
                            value: _vm.previewDate,
                            callback: function ($$v) {
                              _vm.previewDate = $$v
                            },
                            expression: "previewDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.has_any_changes
                      ? _c("div", { staticClass: "my-5" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "working_hours.please_save_to_see_changes"
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-span-1 self-start" },
                    _vm._l(_vm.preview_time_slots, function (slot, index) {
                      return _c("div", { key: index }, [
                        _c("div", { staticClass: "w-1/2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-white p-5 rounded-lg shadow my-2 mx-2",
                            },
                            [
                              _c("span", { attrs: { clas: "" } }, [
                                _vm._v(_vm._s(slot.start_time)),
                              ]),
                              _vm._v(" -> "),
                              _c("span", { attrs: { clas: "" } }, [
                                _vm._v(_vm._s(slot.end_time)),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }