var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row w-full mt-5" }, [
    _c("div", { staticClass: "vx-col w-full m-0 p-0" }, [
      _c("div", { attrs: { id: "container" } }, [
        _c("div", { staticClass: "tabs" }, [
          _c("label", { staticClass: "tab" }, [
            _c("input", {
              staticClass: "tab-input",
              attrs: { type: "radio", name: "tab-input" },
            }),
            _c(
              "div",
              {
                class:
                  _vm.activeTab == "currentMonth" ? "active-tab" : "tab-box",
                on: {
                  click: function ($event) {
                    return _vm.dateClicked("currentMonth")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("dateButtons.currentMonth")))]
            ),
          ]),
          _c("label", { staticClass: "tab" }, [
            _c("input", {
              staticClass: "tab-input",
              attrs: { type: "radio", name: "tab-input" },
            }),
            _c(
              "div",
              {
                class: _vm.activeTab == "lastMonth" ? "active-tab" : "tab-box",
                on: {
                  click: function ($event) {
                    return _vm.dateClicked("lastMonth")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("dateButtons.lastMonth")))]
            ),
          ]),
          _c("label", { staticClass: "tab" }, [
            _c("input", {
              staticClass: "tab-input",
              attrs: { type: "radio", name: "tab-input" },
            }),
            _c(
              "div",
              {
                class:
                  _vm.activeTab == "currentWeek" ? "active-tab" : "tab-box",
                on: {
                  click: function ($event) {
                    return _vm.dateClicked("currentWeek")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("dateButtons.currentWeek")))]
            ),
          ]),
          _c("label", { staticClass: "tab" }, [
            _c("input", {
              staticClass: "tab-input",
              attrs: { type: "radio", name: "tab-input" },
            }),
            _c(
              "div",
              {
                class: _vm.activeTab == "lastWeek" ? "active-tab" : "tab-box",
                on: {
                  click: function ($event) {
                    return _vm.dateClicked("lastWeek")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("dateButtons.lastWeek")))]
            ),
          ]),
          _c("label", { staticClass: "tab" }, [
            _c("input", {
              staticClass: "tab-input",
              attrs: { type: "radio", name: "tab-input" },
            }),
            _c(
              "div",
              {
                class: _vm.activeTab == "currentDay" ? "active-tab" : "tab-box",
                on: {
                  click: function ($event) {
                    return _vm.dateClicked("currentDay")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("dateButtons.currentDay")))]
            ),
          ]),
          _c("label", { staticClass: "tab" }, [
            _c("input", {
              staticClass: "tab-input",
              attrs: { type: "radio", name: "tab-input" },
            }),
            _c(
              "div",
              {
                class: _vm.activeTab == "lastDay" ? "active-tab" : "tab-box",
                on: {
                  click: function ($event) {
                    return _vm.dateClicked("lastDay")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("dateButtons.lastDay")))]
            ),
          ]),
          _c("label", { staticClass: "tab" }, [
            _c("input", {
              staticClass: "tab-input",
              attrs: { type: "radio", name: "tab-input" },
            }),
            _c(
              "div",
              {
                class:
                  _vm.activeTab == "currentYear" ? "active-tab" : "tab-box",
                on: {
                  click: function ($event) {
                    return _vm.dateClicked("currentYear")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("dateButtons.currentYear")))]
            ),
          ]),
          _c("label", { staticClass: "tab" }, [
            _c("input", {
              staticClass: "tab-input",
              attrs: { type: "radio", name: "tab-input" },
            }),
            _c(
              "div",
              {
                class: _vm.activeTab == "lastYear" ? "active-tab" : "tab-box",
                on: {
                  click: function ($event) {
                    return _vm.dateClicked("lastYear")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("dateButtons.lastYear")))]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }