var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "dashboard-analytics" } }, [
    _c(
      "div",
      {
        staticClass:
          "grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-10 md:grid-cols-2 lg:grid-cols-3 lg:gap-10",
      },
      [
        _c("div", { staticClass: "col-span-1 md:col-span-2 lg:col-span-3" }, [
          _c(
            "div",
            { staticClass: "flex justify-between" },
            [
              _c("span", { staticClass: "text-xl md:text-4xl sm:text-2xl" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("statisticsCards.bookings.monthNameStatistics", {
                      monthName: _vm.currentMonthText,
                    })
                  )
                ),
              ]),
              _c(
                "vs-select",
                {
                  model: {
                    value: _vm.informationCardsFilters.event_date_type,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.informationCardsFilters,
                        "event_date_type",
                        $$v
                      )
                    },
                    expression: "informationCardsFilters.event_date_type",
                  },
                },
                _vm._l(
                  [
                    {
                      text: _vm.$t(
                        "statisticsCards.filters.bookingDateTypeSelect.bookingDate"
                      ),
                      value: "created_at",
                    },
                    {
                      text: _vm.$t(
                        "statisticsCards.filters.bookingDateTypeSelect.eventDate"
                      ),
                      value: "start_date",
                    },
                  ],
                  function (item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.value, text: item.text },
                    })
                  }
                ),
                1
              ),
            ],
            1
          ),
        ]),
        _c("information-card", {
          attrs: {
            title: _vm.$t("statisticsCards.bookings.bookingsCount", {
              bookingsCount: _vm.cardsData.real_bookings_count
                ? _vm.cardsData.real_bookings_count
                : 0,
            }),
            subtitle: _vm.$t("statisticsCards.bookings.totalPriceBookings", {
              totalPriceBookings: _vm.cardsData.total_price_real_bookings
                ? _vm.cardsData.total_price_real_bookings.toLocaleString()
                : 0,
            }),
            cta: _vm.$t("statisticsCards.bookings.knowMore"),
            image: require("@/assets/images/icons/booking.svg"),
            pageName: "bookings-list",
            textColor: "#626262",
          },
        }),
        _c("information-card", {
          attrs: {
            title: _vm.$t("statisticsCards.bookings.leadsCount", {
              leadsCount: _vm.cardsData.leads_count
                ? _vm.cardsData.leads_count
                : 0,
            }),
            subtitle: _vm.$t("statisticsCards.bookings.totalLeadBookings", {
              totalLeadBookings: _vm.cardsData.total_lead_bookings
                ? _vm.cardsData.total_lead_bookings.toLocaleString()
                : 0,
            }),
            cta: _vm.$t("statisticsCards.bookings.convertIntoRealSales"),
            image: require("@/assets/images/icons/people.svg"),
            pageName: "bookings-leads",
            textColor: "#626262",
          },
        }),
        _c("information-card", {
          attrs: {
            title: _vm.$t("statisticsCards.bookings.marketingCredit"),
            subtitle: _vm.$t("statisticsCards.bookings.marketingCreditAmount", {
              marketingCredit: _vm.cardsData.marketing_credit
                ? _vm.cardsData.marketing_credit.toLocaleString()
                : 0,
            }),
            cta: _vm.$t(
              "statisticsCards.bookings.knowMoreAboutBusinessMarketing"
            ),
            subtitleColor: "#05b317",
            image: require("@/assets/images/icons/money.svg"),
            pageName: "business-marketing",
            textColor: "#626262",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "my-10" }),
    _c("div", { staticClass: "vx-row flex justify-between mx-3" }, [
      _c("div", [
        _c("span", { staticClass: "text-3xl" }, [
          _vm._v(_vm._s(_vm.$route.meta.pageTitle)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full mb-10" },
        [
          _c(
            "vs-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t("home.bookingsWaitingForYourAction") +
                      "(" +
                      _vm.awaitingPlaceActionBookings.length +
                      ")",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "con-tab-ejemplo" },
                    [
                      _c(
                        "vx-card",
                        {
                          attrs: {
                            title: _vm.$t("home.bookingsWaitingForYourAction"),
                          },
                        },
                        [
                          _c("bookings-table", {
                            ref: "bookingsTableRef",
                            attrs: {
                              bookings: _vm.awaitingPlaceActionBookings,
                            },
                            on: { updateBookingReceipt: _vm.bookingsRequest },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t("home.lastAddedBookings") +
                      "(" +
                      _vm.lastAddedBookings.length +
                      ")",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "con-tab-ejemplo" },
                    [
                      _c(
                        "vx-card",
                        { attrs: { title: _vm.$t("home.lastAddedBookings") } },
                        [
                          _c("bookings-table", {
                            ref: "bookingsTableRef",
                            attrs: { bookings: _vm.lastAddedBookings },
                            on: { updateBookingReceipt: _vm.bookingsRequest },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t("home.upcomingBookings") +
                      "(" +
                      _vm.upcomingBookings.length +
                      ")",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "con-tab-ejemplo" },
                    [
                      _c(
                        "vx-card",
                        { attrs: { title: _vm.$t("home.upcomingBookings") } },
                        [
                          _c("bookings-table", {
                            ref: "bookingsTableRef",
                            attrs: { bookings: _vm.upcomingBookings },
                            on: { updateBookingReceipt: _vm.bookingsRequest },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t("home.cancelledBookings") +
                      "(" +
                      _vm.cancelledBookings.length +
                      ")",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "con-tab-ejemplo" },
                    [
                      _c(
                        "vx-card",
                        {
                          attrs: {
                            title: _vm.$t(
                              "home.bookingsWaitingForCustomerAction"
                            ),
                          },
                        },
                        [
                          _c("bookings-table", {
                            ref: "bookingsTableRef",
                            attrs: { bookings: _vm.cancelledBookings },
                            on: { updateBookingReceipt: _vm.bookingsRequest },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t("home.endedBookings") +
                      "(" +
                      _vm.endedBookings.length +
                      ")",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "con-tab-ejemplo" },
                    [
                      _c(
                        "vx-card",
                        {
                          attrs: {
                            title: _vm.$t(
                              "home.bookingsWaitingForCustomerAction"
                            ),
                          },
                        },
                        [
                          _c("bookings-table", {
                            ref: "bookingsTableRef",
                            attrs: { bookings: _vm.endedBookings },
                            on: { updateBookingReceipt: _vm.bookingsRequest },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t("home.bookingsWaitingForCustomerAction") +
                      "(" +
                      _vm.awaitingCustomerActionBookings.length +
                      ")",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "con-tab-ejemplo" },
                    [
                      _c(
                        "vx-card",
                        {
                          attrs: {
                            title: _vm.$t(
                              "home.bookingsWaitingForCustomerAction"
                            ),
                          },
                        },
                        [
                          _c("bookings-table", {
                            ref: "bookingsTableRef",
                            attrs: {
                              bookings: _vm.awaitingCustomerActionBookings,
                            },
                            on: { updateBookingReceipt: _vm.bookingsRequest },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t("pages.businessEvents") +
                      "(" +
                      this.businessEventsCount +
                      ")",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "con-tab-ejemplo" },
                    [
                      _c(
                        "vx-card",
                        { attrs: { title: _vm.$t("home.businessEvents") } },
                        [_c("BusinessEventsTable")],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }