var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "data-list-container",
      attrs: { id: "data-list-list-view" },
    },
    [
      _c("discount-coupons-form-popup", {
        attrs: {
          discountCouponPopupActive: _vm.sendOfferToCustomerPromptActive,
          currentDiscountCoupon: _vm.currentDiscountCoupon,
          isEditDiscountCoupon: _vm.isEditDiscountCoupon,
        },
        on: {
          "update:discountCouponPopupActive": function ($event) {
            _vm.sendOfferToCustomerPromptActive = $event
          },
          "update:discount-coupon-popup-active": function ($event) {
            _vm.sendOfferToCustomerPromptActive = $event
          },
        },
      }),
      _c("div", { staticClass: "container-fluid my-4 px-4" }, [
        _c(
          "div",
          { staticClass: "vx-row justify-end" },
          [
            _c(
              "vs-button",
              { attrs: { icon: "add" }, on: { click: _vm.addDiscountCoupon } },
              [_vm._v(_vm._s(_vm.$t("Add")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vs-table",
        {
          ref: "table",
          staticClass: "mb-5",
          attrs: {
            sst: true,
            pagination: "",
            total: parseInt(_vm.tableConfig.total),
            "max-items": parseInt(_vm.tableConfig.perPage),
            data: _vm.discountCoupons,
          },
          on: {
            "change-page": _vm.handleChangePage,
            selected: _vm.editDiscountCoupon,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "tbody",
                    [
                      _vm._l(data, function (tr, indextr) {
                        return _c(
                          "vs-tr",
                          { key: indextr, attrs: { data: tr } },
                          [
                            _c("vs-td", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "product-name font-medium truncate",
                                },
                                [_vm._v(_vm._s(tr.name))]
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "p",
                                { staticClass: "booking-date truncate" },
                                [_vm._v(_vm._s(tr.code))]
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "p",
                                { staticClass: "booking-date truncate" },
                                [
                                  _vm._v(
                                    _vm._s(tr.discount) +
                                      " " +
                                      _vm._s(
                                        tr.discount_type == "percentage"
                                          ? "%"
                                          : _vm.$t("SAR")
                                      )
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "vs-td",
                              [
                                tr.start_date || tr.end_date
                                  ? _c(
                                      "vs-chip",
                                      { attrs: { color: "success" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("discountCouponsList.yes")
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "vs-chip",
                                      { attrs: { color: "warning" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("discountCouponsList.no")
                                          )
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "vs-td",
                              [
                                tr.is_valid == "valid"
                                  ? _c(
                                      "vs-chip",
                                      { attrs: { color: "success" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("discountCouponsList.valid")
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "vs-chip",
                                      { attrs: { color: "warning" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "discountCouponsList.inValid"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                            _c("vs-td", { staticClass: "whitespace-no-wrap" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex flex-row whitespace-no-wrap",
                                },
                                [
                                  _c("vs-button", {
                                    staticClass: "mx-1 action-btn",
                                    attrs: {
                                      type: "border",
                                      "icon-pack": "feather",
                                      icon: "icon-trash",
                                      color: "danger",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteDiscountCouponDialog(
                                          tr
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _vm._l(_vm.generalInfoColumnsTitles, function (col, index) {
                return _c(
                  "vs-th",
                  { key: index, attrs: { "sort-key": col.sortKey } },
                  [
                    _c(
                      "p",
                      { staticClass: "table-head font-medium truncate" },
                      [_vm._v(_vm._s(col.title))]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }