var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "holamundo",
          attrs: { title: "", active: _vm.isActive },
          on: {
            "update:active": function ($event) {
              _vm.isActive = $event
            },
            close: _vm.closePopup,
          },
        },
        [
          _vm.currentBusinessEvent
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col p-0 w-full flex flex-wrap justify-between mb-5",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "w-2/3 text-sm",
                        attrs: { icon: "check", color: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.updateStatusClicked("agentInterested")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("interested")))]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "w-1/4",
                        attrs: {
                          icon: "block",
                          type: "border",
                          color: "danger",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.updateStatusClicked("agentNotInterested")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("notInterested")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col p-0 w-full mb-8" },
                  [
                    _c(
                      "vs-alert",
                      {
                        staticClass: "h-full text-center lg:text-left",
                        attrs: { color: "warning", icon: "note" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("pages.businessEventConfirmationDetails")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col px-5 w-full flex flex-wrap lg:justify-between justify-center",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "opacity-75 my-2 text-center lg:my-0 lg:text-right",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.currentBusinessEvent.event_name) +
                            " "
                        ),
                      ]
                    ),
                    _vm.currentBusinessEvent.budget
                      ? _c("h3", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.currentBusinessEvent.budget.toLocaleString()
                              ) +
                              " " +
                              _vm._s(_vm.$t("SAR")) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col p-0 w-full flex flex-wrap justify-center",
                  },
                  [
                    _c("hr", {
                      staticClass: "row w-11/12 mt-3",
                      staticStyle: { opacity: "0.5" },
                    }),
                  ]
                ),
                _c("div", { staticClass: "px-5 mt-5 w-full" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.currentBusinessEvent.event_description) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col p-0 w-full flex flex-wrap justify-center",
                  },
                  [
                    _c("hr", {
                      staticClass: "row w-11/12 mt-3",
                      staticStyle: { opacity: "0.5" },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex justify-between px-5 mt-5" },
                  [
                    _c("span", { staticClass: "text-sm mt-2" }, [
                      _vm._v(
                        _vm._s(_vm.$t("businessEventsList.contactUsNote"))
                      ),
                    ]),
                    _c(
                      "vs-button",
                      {
                        staticClass: "text-sm",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-message-circle",
                          color: "success",
                          type: "border",
                        },
                        on: { click: _vm.contactViaWhatsapp },
                      },
                      [_vm._v(_vm._s(_vm.$t("businessEventsList.contactUs")))]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }