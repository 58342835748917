var render = function render(_c, _vm) {
  return _c(
    "footer",
    { staticClass: "the-footer flex-wrap justify-between", class: _vm.classes },
    [
      _c("p", { attrs: { dir: "ltr" } }, [
        _c("span", [_vm._v("COPYRIGHT ©")]),
        _c("span", [_vm._v(_vm._s(new Date().getFullYear()) + " ")]),
        _c(
          "a",
          {
            attrs: {
              href: "https://eventful.sa",
              target: "_blank",
              rel: "nofollow",
            },
          },
          [_vm._v("Eventful")]
        ),
        _c("span", { staticClass: "hidden sm:inline-block" }, [
          _vm._v(", All rights Reserved"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }