var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c("div", { staticClass: "vx-row no-gutter" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                    },
                    [
                      _c("img", {
                        staticClass: "mx-auto",
                        attrs: {
                          src: require("@/assets/images/pages/register.jpg"),
                          alt: "register",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "px-8 pt-8 register-tabs-container" },
                        [
                          _c("div", { staticClass: "vx-card__title mb-4" }, [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v("Create Account"),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Fill the below form to create a new account."
                              ),
                            ]),
                          ]),
                          _c(
                            "vs-tabs",
                            [
                              _c(
                                "vs-tab",
                                { attrs: { label: "JWT" } },
                                [_c("register-jwt")],
                                1
                              ),
                              _c(
                                "vs-tab",
                                { attrs: { label: "Firebase" } },
                                [_c("register-firebase")],
                                1
                              ),
                              _c(
                                "vs-tab",
                                { attrs: { label: "Auth0" } },
                                [_c("register-auth0")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }