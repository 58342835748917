var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container w-full" }, [
    _c("div", { staticClass: "flex flex-wrap" }, [
      _vm.isSpecificToCustomer && _vm.booking != null
        ? _c(
            "div",
            { staticClass: "vx-col w-full md:mb-2" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "my-4",
                  attrs: { color: "warning", icon: "new_releases" },
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("discountCouponForm.specificCustomer", {
                          customerName: _vm.booking.customer_name,
                          bookingOption: _vm.booking.receipt.booking_option
                            ? _vm.booking.receipt.booking_option.name
                            : "",
                        })
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isSpecificToCustomer
        ? _c("div", { staticClass: "vx-col w-full" }, [
            _c("h4", { staticClass: "mt-3 opacity-75" }, [
              _vm._v(_vm._s(_vm.$t("discountCouponForm.generalInformation"))),
            ]),
          ])
        : _vm._e(),
      !_vm.isSpecificToCustomer
        ? _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" },
            [
              _c("vs-input", {
                staticClass: "w-full required",
                attrs: {
                  label: _vm.$t("discountCouponForm.name"),
                  autocomplete: "off",
                },
                model: {
                  value: _vm.currentDiscountCoupon.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentDiscountCoupon, "name", $$v)
                  },
                  expression: "currentDiscountCoupon.name",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isSpecificToCustomer
        ? _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" },
            [
              _c("vs-input", {
                staticClass: "w-full required",
                attrs: {
                  label: _vm.$t("discountCouponForm.code"),
                  autocomplete: "off",
                },
                model: {
                  value: _vm.currentDiscountCoupon.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentDiscountCoupon, "code", $$v)
                  },
                  expression: "currentDiscountCoupon.code",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isSpecificToCustomer
        ? _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  type: "Number",
                  label: _vm.$t("discountCouponForm.maxUsageCount"),
                  autocomplete: "off",
                },
                model: {
                  value: _vm.currentDiscountCoupon.max_usage_count,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentDiscountCoupon, "max_usage_count", $$v)
                  },
                  expression: "currentDiscountCoupon.max_usage_count",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [
          _c(
            "vs-checkbox",
            {
              attrs: { color: "warning" },
              on: { change: _vm.discountCouponDateRangeFresh },
              model: {
                value: _vm.dateRangeSection,
                callback: function ($$v) {
                  _vm.dateRangeSection = $$v
                },
                expression: "dateRangeSection",
              },
            },
            [
              _c("h4", { staticClass: "mt-3 opacity-75" }, [
                _vm._v(
                  _vm._s(
                    _vm.dateRangeSection
                      ? _vm.$t("discountCouponForm.dateRangeTitleEnabled")
                      : _vm.$t("discountCouponForm.dateRangeTitleDisabled")
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.dateRangeSection
        ? _c("div", { staticClass: "vx-col w-full md:mb-2 mt-2 pl-2" }, [
            _c("div", { staticClass: "flex w-full" }, [
              _c("small", { staticClass: "gray-note" }, [
                _vm._v(_vm._s(_vm.$t("discountCouponForm.dateNote"))),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex w-full" },
              [
                _c("v-date-picker", {
                  staticClass: "flex w-full",
                  attrs: {
                    mode: "datetime",
                    "is-range": "",
                    color: "orange",
                    masks: _vm.dateFormat,
                    "min-date": _vm.minDate,
                    "max-date": _vm.maxDate,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ inputValue, inputEvents, isDragging }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "flex w-full" },
                              [
                                _c(
                                  "vs-input",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "flex-grow py-1 bg-gray-100 border rounded w-full pr-1",
                                      class: isDragging
                                        ? "text-gray-600"
                                        : "text-gray-900",
                                      attrs: {
                                        label: _vm.$t(
                                          "discountCouponForm.startDate"
                                        ),
                                        value: inputValue.start,
                                        icon: "today",
                                      },
                                    },
                                    inputEvents.start
                                  )
                                ),
                                _c(
                                  "vs-input",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "flex-grow py-1 bg-gray-100 border rounded w-full",
                                      class: isDragging
                                        ? "text-gray-600"
                                        : "text-gray-900",
                                      attrs: {
                                        label: _vm.$t(
                                          "discountCouponForm.endDate"
                                        ),
                                        value: inputValue.end,
                                        icon: "event",
                                      },
                                    },
                                    inputEvents.end
                                  )
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1871987922
                  ),
                  model: {
                    value: _vm.discountCouponDateRange,
                    callback: function ($$v) {
                      _vm.discountCouponDateRange = $$v
                    },
                    expression: "discountCouponDateRange",
                  },
                }),
                _vm.discountCouponDateRange.start ||
                _vm.discountCouponDateRange.end
                  ? _c(
                      "div",
                      { staticClass: "pt-2" },
                      [
                        _c("vs-button", {
                          staticClass: "my-5 ml-1",
                          attrs: {
                            type: "border",
                            "icon-pack": "feather",
                            icon: "icon-trash",
                          },
                          on: { click: _vm.discountCouponDateRangeFresh },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "vx-col w-full mt-5" }, [
        _c("h4", { staticClass: "mt-3 opacity-75" }, [
          _vm._v(_vm._s(_vm.$t("discountCouponForm.pricing"))),
        ]),
      ]),
      _c("div", { staticClass: "vx-col w-full mb-2 mt-2 pl-3" }, [
        _c("div", { staticClass: "container mt-2" }, [
          _c("div", { staticClass: "vx-row required" }, [
            _c("label", { staticClass: "v-select-label" }, [
              _vm._v(_vm._s(_vm.$t("discountCouponForm.discountType"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c(
                "vs-radio",
                {
                  staticClass: "mr-8 mt-1",
                  attrs: {
                    "vs-name": "discountCouponForm.discount_type",
                    "vs-value": "fixed",
                  },
                  model: {
                    value: _vm.currentDiscountCoupon.discount_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentDiscountCoupon, "discount_type", $$v)
                    },
                    expression: "currentDiscountCoupon.discount_type",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("discountCouponForm.fixedDiscount")))]
              ),
              _c(
                "vs-radio",
                {
                  staticClass: "mr-4 mt-1",
                  attrs: {
                    "vs-name": "discountCouponForm.discount_type",
                    "vs-value": "percentage",
                  },
                  model: {
                    value: _vm.currentDiscountCoupon.discount_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentDiscountCoupon, "discount_type", $$v)
                    },
                    expression: "currentDiscountCoupon.discount_type",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("discountCouponForm.percentageDiscount"))
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-full md:mb-2 pl-2 mt-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              type: "Number",
              label: _vm.$t("discountCouponForm.discount"),
              "description-text":
                _vm.currentDiscountCoupon.discount_type == "percentage"
                  ? _vm.$t("discountCouponForm.discountInPercentage")
                  : _vm.$t("discountCouponForm.discountInRiyals"),
              autocomplete: "off",
            },
            model: {
              value: _vm.currentDiscountCoupon.discount,
              callback: function ($$v) {
                _vm.$set(_vm.currentDiscountCoupon, "discount", $$v)
              },
              expression: "currentDiscountCoupon.discount",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              type: "Number",
              label: _vm.$t("discountCouponForm.minPrice"),
              "description-text": _vm.$t("discountCouponForm.minPriceDescText"),
              autocomplete: "off",
            },
            model: {
              value: _vm.currentDiscountCoupon.min_price,
              callback: function ($$v) {
                _vm.$set(_vm.currentDiscountCoupon, "min_price", $$v)
              },
              expression: "currentDiscountCoupon.min_price",
            },
          }),
        ],
        1
      ),
      _vm.currentDiscountCoupon.discount_type == "percentage"
        ? _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  type: "Number",
                  label: _vm.$t("discountCouponForm.maxPrice"),
                  "description-text": _vm.$t(
                    "discountCouponForm.maxPriceDescText"
                  ),
                  autocomplete: "off",
                },
                model: {
                  value: _vm.currentDiscountCoupon.max_amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentDiscountCoupon, "max_amount", $$v)
                  },
                  expression: "currentDiscountCoupon.max_amount",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "vx-col w-full md:mb-2 mt-2" }, [
        _c(
          "div",
          { staticClass: "flex w-full justify-end space-x-2" },
          [
            _c(
              "vs-button",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-trash",
                  color: "danger",
                },
                on: { click: _vm.deleteDiscountCouponDialog },
              },
              [_vm._v(_vm._s(_vm.$t("discountCouponForm.deleteOffer")))]
            ),
            _c(
              "vs-button",
              {
                attrs: { "icon-pack": "feather", icon: "icon-check" },
                on: { click: _vm.submitForm },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.isEditDiscountCoupon
                      ? _vm.$t("discountCouponForm.editOffer")
                      : _vm.$t("discountCouponForm.sendOffer")
                  )
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full my-3 opacity-50" }, [
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }