var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("business-event-confirmation", {
        attrs: {
          confirmationIsActive: _vm.businessEventConfirmationIsActive,
          currentBusinessEvent: _vm.currentBusinessEvent,
        },
        on: {
          "update:confirmationIsActive": function ($event) {
            _vm.businessEventConfirmationIsActive = $event
          },
          "update:confirmation-is-active": function ($event) {
            _vm.businessEventConfirmationIsActive = $event
          },
          "update:currentBusinessEvent": function ($event) {
            _vm.currentBusinessEvent = $event
          },
          "update:current-business-event": function ($event) {
            _vm.currentBusinessEvent = $event
          },
          openPreview: _vm.showData,
          getBusinessEvents: _vm.getBusinessEvents,
        },
      }),
      _c("business-event-preview", {
        attrs: {
          previewIsActive: _vm.businessEventPreviewIsActive,
          currentBusinessEvent: _vm.currentBusinessEvent,
        },
        on: {
          "update:previewIsActive": function ($event) {
            _vm.businessEventPreviewIsActive = $event
          },
          "update:preview-is-active": function ($event) {
            _vm.businessEventPreviewIsActive = $event
          },
          "update:currentBusinessEvent": function ($event) {
            _vm.currentBusinessEvent = $event
          },
          "update:current-business-event": function ($event) {
            _vm.currentBusinessEvent = $event
          },
          sendProposal: _vm.showSendProposal,
        },
      }),
      _c("send-proposal-form-popup", {
        attrs: {
          sendProposalIsActive: _vm.sendProposalIsActive,
          currentBusinessEvent: _vm.currentBusinessEvent,
        },
        on: {
          "update:sendProposalIsActive": function ($event) {
            _vm.sendProposalIsActive = $event
          },
          "update:send-proposal-is-active": function ($event) {
            _vm.sendProposalIsActive = $event
          },
          "update:currentBusinessEvent": function ($event) {
            _vm.currentBusinessEvent = $event
          },
          "update:current-business-event": function ($event) {
            _vm.currentBusinessEvent = $event
          },
          openPreview: _vm.showData,
        },
      }),
      _c(
        "vs-table",
        {
          ref: "businessEventsTable",
          staticClass: "mb-5",
          attrs: {
            sst: true,
            pagination: "",
            total: parseInt(_vm.tableConfig.total),
            "max-items": parseInt(_vm.tableConfig.perPage),
            data: _vm.businessEvents,
          },
          on: { "change-page": _vm.handleChangePage, selected: _vm.showData },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "tbody",
                    [
                      _vm._l(data, function (tr, indextr) {
                        return _c(
                          "vs-tr",
                          { key: indextr, attrs: { data: tr } },
                          [
                            _c("vs-td", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "product-name font-medium truncate",
                                },
                                [_vm._v(_vm._s(tr.event_name))]
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "p",
                                { staticClass: "booking-date truncate" },
                                [_vm._v(_vm._s(tr.event_date))]
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "p",
                                { staticClass: "booking-date truncate" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(tr.budget.toLocaleString()) +
                                      " " +
                                      _vm._s(_vm.$t("SAR")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("vs-td", { staticClass: "whitespace-no-wrap" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    padding: "4px 10px",
                                    color: "white",
                                    "border-radius": "100px",
                                  },
                                  style: { background: tr.status.color },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        tr.status.display_name || "غير محدد"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _vm._l(_vm.generalInfoColumnsTitles, function (col, index) {
                return _c(
                  "vs-th",
                  { key: index, attrs: { "sort-key": col.sortKey } },
                  [
                    _c(
                      "p",
                      { staticClass: "table-head font-medium truncate" },
                      [_vm._v(_vm._s(col.title))]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }