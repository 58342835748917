var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("settlement-details-popup", {
        attrs: {
          settlementDetailsActive: _vm.settlementDetailsActive,
          currentSettlement: _vm.currentSettlement,
        },
        on: {
          "update:settlementDetailsActive": function ($event) {
            _vm.settlementDetailsActive = $event
          },
          "update:settlement-details-active": function ($event) {
            _vm.settlementDetailsActive = $event
          },
          "update:currentSettlement": function ($event) {
            _vm.currentSettlement = $event
          },
          "update:current-settlement": function ($event) {
            _vm.currentSettlement = $event
          },
        },
      }),
      _c(
        "vs-table",
        {
          ref: "SettlementsTable",
          staticClass: "mb-5",
          attrs: {
            sst: true,
            pagination: "",
            total: parseInt(_vm.tableConfig.total),
            "max-items": parseInt(_vm.tableConfig.perPage),
            data: _vm.settlements,
            stripe: "",
          },
          on: { "change-page": _vm.handleChangePage, selected: _vm.showData },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "tbody",
                    [
                      _vm._l(data, function (tr, indextr) {
                        return _c(
                          "vs-tr",
                          { key: indextr, attrs: { data: tr } },
                          [
                            _c("vs-td", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "product-name font-medium truncate",
                                },
                                [_vm._v(_vm._s(tr.title))]
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "p",
                                { staticClass: "booking-date truncate" },
                                [_vm._v(_vm._s(tr.start_date))]
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "p",
                                { staticClass: "booking-date truncate" },
                                [_vm._v(_vm._s(tr.end_date))]
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "div",
                                { staticClass: "vx-row" },
                                [
                                  _c(
                                    "vs-tooltip",
                                    {
                                      attrs: {
                                        text: "hello",
                                        "vs-z-index": "999999",
                                      },
                                    },
                                    [
                                      _c(
                                        "vs-chip",
                                        {
                                          staticClass: "truncate",
                                          attrs: {
                                            color: tr.status_object.color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$i18n.locale == "en"
                                                  ? tr.status_object
                                                      .display_name
                                                  : tr.status_object
                                                      .display_name_ar
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("vs-td", [
                              _vm._v(" " + _vm._s(tr.number_of_bookings) + " "),
                            ]),
                            _c("vs-td", [
                              _vm._v(
                                " " +
                                  _vm._s(tr.total_amount) +
                                  " " +
                                  _vm._s(_vm.$t("SAR")) +
                                  " "
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "p",
                                { staticClass: "booking-date truncate" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("months." + tr.month_name))
                                  ),
                                ]
                              ),
                            ]),
                            _c("vs-td", { staticClass: "whitespace-no-wrap" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex flex-row whitespace-no-wrap",
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "mx-1",
                                      attrs: {
                                        type: "border",
                                        color: "success",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.exportToExcel(tr)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "venueSettlements.exportBookings"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  ["settled"].includes(tr.status) &&
                                  tr.last_receipt_document_url
                                    ? _c(
                                        "vs-button",
                                        {
                                          staticClass: "mx-1",
                                          attrs: {
                                            type: "border",
                                            color: "#6e6e6e",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.goToLink(
                                                tr.last_receipt_document_url
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "venueSettlements.viewReceipt"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  ["waiting_agent_confirmation"].includes(
                                    tr.status
                                  )
                                    ? _c(
                                        "vs-button",
                                        {
                                          staticClass: "mx-1 action-btn",
                                          attrs: { color: "success" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.takeActionPrompt(
                                                tr,
                                                "approved"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "venueSettlements.confirmation.approvedBtn"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  ["waiting_agent_confirmation"].includes(
                                    tr.status
                                  )
                                    ? _c(
                                        "vs-button",
                                        {
                                          staticClass: "mx-1 action-btn",
                                          attrs: {
                                            type: "border",
                                            color: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.takeActionPrompt(
                                                tr,
                                                "rejected"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "venueSettlements.confirmation.rejectedBtn"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._l(tr.attachments, function (attachment) {
                                    return ["settled"].includes(tr.status)
                                      ? _c(
                                          "vs-button",
                                          {
                                            staticClass: "mx-1",
                                            attrs: {
                                              type: "border",
                                              color: "#7d34eb",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.goToLink(attachment)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "venueSettlements.transformationReceipt"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              [
                _c("vs-th", [
                  _c("p", { staticClass: "table-head font-medium truncate" }, [
                    _vm._v(_vm._s(_vm.$t("venueSettlements.title"))),
                  ]),
                ]),
                _c("vs-th", [
                  _c("p", { staticClass: "table-head font-medium truncate" }, [
                    _vm._v(_vm._s(_vm.$t("venueSettlements.startDate"))),
                  ]),
                ]),
                _c("vs-th", [
                  _c("p", { staticClass: "table-head font-medium truncate" }, [
                    _vm._v(_vm._s(_vm.$t("venueSettlements.endDate"))),
                  ]),
                ]),
                _c("vs-th", [
                  _c("p", { staticClass: "table-head font-medium truncate" }, [
                    _vm._v(_vm._s(_vm.$t("venueSettlements.status"))),
                  ]),
                ]),
                _c("vs-th", [
                  _c("p", { staticClass: "table-head font-medium truncate" }, [
                    _vm._v(_vm._s(_vm.$t("venueSettlements.bookingsCount"))),
                  ]),
                ]),
                _c("vs-th", [
                  _c("p", { staticClass: "table-head font-medium truncate" }, [
                    _vm._v(_vm._s(_vm.$t("venueSettlements.settlementAmount"))),
                  ]),
                ]),
                _c("vs-th", [
                  _c("p", { staticClass: "table-head font-medium truncate" }, [
                    _vm._v(_vm._s(_vm.$t("venueSettlements.month"))),
                  ]),
                ]),
                _c("vs-th"),
              ],
            ],
            2
          ),
        ],
        2
      ),
      _c(
        "vs-prompt",
        {
          attrs: {
            active: _vm.settlementConfirmation.isActive,
            title: _vm.$t("venueSettlements.confirmation.title"),
            "accept-text": _vm.$t(
              "venueSettlements.confirmation." +
                _vm.settlementConfirmation.action +
                "Btn"
            ),
            color:
              _vm.settlementConfirmation.action == "approved"
                ? "success"
                : "danger",
          },
          on: {
            accept: _vm.takeAction,
            "update:active": function ($event) {
              return _vm.$set(_vm.settlementConfirmation, "isActive", $event)
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "venueSettlements.confirmation." +
                    _vm.settlementConfirmation.action +
                    "Details"
                )
              )
            ),
          ]),
          _vm.settlementConfirmation.action == "rejected"
            ? _c("vs-textarea", {
                attrs: {
                  label: _vm.$t(
                    "venueSettlements.confirmation.rejectionReason"
                  ),
                  placeholder: _vm.$t(
                    "venueSettlements.confirmation.rejectedDetailsPlaceholder"
                  ),
                },
                model: {
                  value: _vm.settlementConfirmation.rejection_reason,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.settlementConfirmation,
                      "rejection_reason",
                      $$v
                    )
                  },
                  expression: "settlementConfirmation.rejection_reason",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }