var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "data-list-container",
      attrs: { id: "data-list-list-view" },
    },
    [
      _c("booking-form-pop-up", {
        attrs: {
          bookingFormPopUpActive: _vm.bookingFormPopUpActive,
          currentBooking: _vm.currentBooking,
          isEdit: _vm.isBookingListEdit,
          startDate: _vm.startDate,
          endDate: _vm.endDate,
        },
        on: {
          "update:bookingFormPopUpActive": function ($event) {
            _vm.bookingFormPopUpActive = $event
          },
          "update:booking-form-pop-up-active": function ($event) {
            _vm.bookingFormPopUpActive = $event
          },
          "update:currentBooking": function ($event) {
            _vm.currentBooking = $event
          },
          "update:current-booking": function ($event) {
            _vm.currentBooking = $event
          },
          updateCurrentBooking: function ($event) {
            _vm.currentBooking = $event
          },
        },
      }),
      _c("booking-preview", {
        ref: "preview",
        attrs: {
          bookingPreviewActive: _vm.popupActivo,
          currentBooking: _vm.currentBooking,
        },
        on: {
          "update:bookingPreviewActive": function ($event) {
            _vm.popupActivo = $event
          },
          "update:booking-preview-active": function ($event) {
            _vm.popupActivo = $event
          },
          "update:currentBooking": function ($event) {
            _vm.currentBooking = $event
          },
          "update:current-booking": function ($event) {
            _vm.currentBooking = $event
          },
          updateCurrentBooking: function ($event) {
            _vm.currentBooking = $event
          },
          openEditBookingPopUp: _vm.editData,
        },
      }),
      _c("booking-confirmation", {
        attrs: {
          bookingConfirmationPopUp: _vm.bookingConfirmationPopUp,
          currentBooking: _vm.currentBooking,
          bookingConfirmationType: _vm.bookingConfirmationType,
        },
        on: {
          "update:bookingConfirmationPopUp": function ($event) {
            _vm.bookingConfirmationPopUp = $event
          },
          "update:booking-confirmation-pop-up": function ($event) {
            _vm.bookingConfirmationPopUp = $event
          },
          "update:currentBooking": function ($event) {
            _vm.currentBooking = $event
          },
          "update:current-booking": function ($event) {
            _vm.currentBooking = $event
          },
          updateCurrentBooking: function ($event) {
            _vm.currentBooking = $event
          },
          openPreview: function ($event) {
            _vm.popupActivo = true
          },
        },
      }),
      _c(
        "vs-prompt",
        {
          staticClass: "export-options",
          attrs: {
            title: _vm.$t("calendar.bookingBlock"),
            color: "danger",
            "accept-text": _vm.$t("delete"),
            "cancel-text": _vm.$t("close"),
            active: _vm.bookingBlockPromptActive,
          },
          on: {
            accept: _vm.deleteBookingBlock,
            "update:active": function ($event) {
              _vm.bookingBlockPromptActive = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("calendar.doYouWantToRemoveBookingBlock"))),
          ]),
        ]
      ),
      _c(
        "vs-table",
        {
          ref: "table",
          attrs: {
            sst: true,
            pagination: "",
            search: "",
            "max-items": parseInt(_vm.pages ? _vm.pages.per_page : 1),
            total: parseInt(_vm.pages ? _vm.pages.total : 1),
            data: _vm.bookingsItems,
          },
          on: {
            "change-page": _vm.handleChangePage,
            search: _vm.handleSearch,
            sort: _vm.sortBookings,
            selected: _vm.showData,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "tbody",
                    [
                      _vm._l(data, function (tr, indextr) {
                        return _c(
                          "vs-tr",
                          { key: indextr, attrs: { data: tr } },
                          [
                            _c(
                              "vs-td",
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: tr.source_description } },
                                  [
                                    _c(
                                      "vs-chip",
                                      {
                                        staticClass:
                                          "product-no font-medium truncate",
                                        attrs: { color: tr.source_color },
                                      },
                                      [_vm._v(_vm._s(tr.source))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("vs-td", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "product-no font-medium truncate",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      tr.state_id == 8 ? "" : tr.booking_number
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "product-name font-medium truncate",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      tr.state_id == 8
                                        ? "حجب الحجوزات"
                                        : tr.customer_name
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "vs-td",
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: tr.state.description } },
                                  [
                                    _c(
                                      "vs-chip",
                                      {
                                        staticClass: "product-order-status",
                                        attrs: { color: tr.state.color },
                                      },
                                      [_vm._v(_vm._s(tr.state.display_name))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-td",
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: tr.created_at } },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "booking-created" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                tr.created_at,
                                                "DD-MM-YYYY"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-td",
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: tr.start_date } },
                                  [
                                    _c("p", { staticClass: "booking-date" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              tr.start_date,
                                              "DD-MM-YYYY"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              { staticClass: "mb-5" },
              [
                _c(
                  "vs-select",
                  {
                    model: {
                      value: _vm.bookingFilters.event_date_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.bookingFilters, "event_date_type", $$v)
                      },
                      expression: "bookingFilters.event_date_type",
                    },
                  },
                  _vm._l(
                    [
                      {
                        text: _vm.$t(
                          "statisticsCards.filters.bookingDateTypeSelect.bookingDate"
                        ),
                        value: "created_at",
                      },
                      {
                        text: _vm.$t(
                          "statisticsCards.filters.bookingDateTypeSelect.eventDate"
                        ),
                        value: "start_date",
                      },
                    ],
                    function (item, index) {
                      return _c("vs-select-item", {
                        key: index,
                        attrs: { value: item.value, text: item.text },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "template",
            { slot: "thead" },
            [
              _vm._l(_vm.generalInfoColumnsTitles, function (col, index) {
                return _c(
                  "vs-th",
                  { key: index, attrs: { "sort-key": col.sortKey } },
                  [_vm._v(" " + _vm._s(col.title) + " ")]
                )
              }),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }