var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "holamundo",
          attrs: { title: "", active: _vm.isActive },
          on: {
            "update:active": function ($event) {
              _vm.isActive = $event
            },
            close: _vm.closePopup,
          },
        },
        [
          _vm.currentBooking && _vm.currentBooking.receipt.booking_option
            ? _c(
                "div",
                [
                  _vm.bookingConfirmationType == "bookingConfirmation"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mb-5",
                        },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-2/3 text-sm",
                              attrs: { icon: "check", color: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateBookingState("accept")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("acceptBooking")))]
                          ),
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-1/4",
                              attrs: {
                                icon: "block",
                                type: "border",
                                color: "danger",
                              },
                              on: { click: _vm.rejectBookingConfirmation },
                            },
                            [_vm._v(_vm._s(_vm.$t("rejectBooking")))]
                          ),
                        ],
                        1
                      )
                    : _vm.bookingConfirmationType == "bookingVisit"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mb-5",
                        },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-2/3 text-sm",
                              attrs: { icon: "check", color: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateBookingState(
                                    "booking_check_accept"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("acceptBookingVisit")))]
                          ),
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-1/4",
                              attrs: {
                                icon: "block",
                                type: "border",
                                color: "danger",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateBookingState(
                                    "booking_check_reject"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("rejectBookingVisit")))]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mb-5",
                        },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-2/3 text-sm",
                              attrs: { icon: "check", color: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateBookingState(
                                    "booking_check_accept"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("bookingCheckAccept")))]
                          ),
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-1/4",
                              attrs: {
                                icon: "block",
                                type: "border",
                                color: "danger",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateBookingState(
                                    "booking_check_reject"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("bookingCheckReject")))]
                          ),
                        ],
                        1
                      ),
                  _c(
                    "div",
                    { staticClass: "vx-col p-0 w-full mb-8" },
                    [
                      _vm.bookingConfirmationType == "bookingConfirmation"
                        ? _c(
                            "vs-alert",
                            {
                              staticClass: "h-full text-center lg:text-left",
                              attrs: { color: "warning", icon: "note" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "addBookingSidebar.bookingConfirmationAlert"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm.bookingConfirmationType == "bookingVisit"
                        ? _c(
                            "vs-alert",
                            {
                              staticClass: "h-full text-center lg:text-left",
                              attrs: { color: "warning", icon: "note" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "addBookingSidebar.bookingVisitAlert"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "vs-alert",
                            {
                              staticClass: "h-full text-center lg:text-left",
                              attrs: { color: "warning", icon: "note" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("bookingCheckConfirmationAlert")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col p-0 w-full flex flex-wrap lg:justify-between justify-center",
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "opacity-75 my-2 text-center lg:my-0 lg:text-right",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.currentBooking.receipt.booking_option != null
                                ? _vm.currentBooking.receipt.booking_option.name
                                : ""
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col p-0 w-full flex flex-wrap justify-center",
                    },
                    [
                      _c("hr", {
                        staticClass: "row w-11/12 mt-3",
                        staticStyle: { opacity: "0.5" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                    },
                    [
                      _c(
                        "h3",
                        { staticClass: "opacity-75 text-lg lg:text-2xl" },
                        [
                          _c("i", { staticClass: "far fa-solid fa-gifts" }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("addBookingSidebar.eventType"))
                          ),
                        ]
                      ),
                      _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentBooking.event_type
                              ? _vm.currentBooking.event_type.arabic_name
                              : ""
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._l(
                    _vm.currentBooking.event_type.guests_inputs,
                    function (guestInput) {
                      return _c(
                        "div",
                        {
                          key: guestInput.key,
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                        },
                        [
                          _c(
                            "h3",
                            { staticClass: "opacity-75 text-lg lg:text-2xl" },
                            [
                              _c("i", {
                                staticClass: "far fa-solid fa-ballot-check",
                              }),
                              _vm._v(" " + _vm._s(guestInput.name)),
                            ]
                          ),
                          _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentBooking[guestInput.key] > 0
                                  ? _vm.currentBooking[guestInput.key]
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      )
                    }
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                    },
                    [
                      _c(
                        "h3",
                        { staticClass: "opacity-75 text-lg lg:text-2xl" },
                        [
                          _c("i", {
                            staticClass: "far fa-solid fa-calendar-check",
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("addBookingSidebar.bookingDate"))
                          ),
                        ]
                      ),
                      _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentBooking.start_date
                              ? _vm.currentBooking.start_date
                              : ""
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm.currentBooking.start_time && _vm.currentBooking.end_time
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                        },
                        [
                          _c(
                            "h3",
                            { staticClass: "opacity-75 text-lg lg:text-2xl" },
                            [
                              _c("i", { staticClass: "far fa-solid fa-clock" }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("booking.bookingTime"))
                              ),
                            ]
                          ),
                          _c("div", [
                            _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentBooking.start_time
                                    ? _vm.convertToAmPm(
                                        _vm.currentBooking.start_time
                                      )
                                    : ""
                                )
                              ),
                            ]),
                            _c("h3", { staticClass: "text-lg lg:text-2xl" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentBooking.end_time
                                    ? _vm.convertToAmPm(
                                        _vm.currentBooking.end_time
                                      )
                                    : ""
                                )
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.bookingConfirmationType == "bookingVisit"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mt-5",
                        },
                        [
                          _c("vs-textarea", {
                            staticClass: "mt-5 w-full",
                            attrs: {
                              label: _vm.$t("addBookingSidebar.visitDetails"),
                              rows: "5",
                              value: _vm.currentBooking.customer_notes,
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.bookingConfirmationType == "bookingConfirmation"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap md:justify-between justify-center mt-5",
                        },
                        [
                          _c("h3", { staticClass: "opacity-75 mx-2" }, [
                            _c("i", {
                              staticClass:
                                "far fa-solid fa-dollar-sign ml-1 mr-1",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("addBookingSidebar.bookingFinalValue")
                                )
                            ),
                          ]),
                          _c("div", [
                            _c("h3", { staticClass: "text-danger mx-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.currentBooking.receipt
                                      .final_price_for_agent
                                      ? _vm.currentBooking.receipt.final_price_for_agent.toLocaleString()
                                      : 0 + " "
                                  ) +
                                  " " +
                                  _vm._s(_vm.$t("SAR")) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col p-0 w-full flex flex-wrap justify-center",
                              },
                              [
                                _c(
                                  "small",
                                  { staticClass: "very-small-note" },
                                  [_vm._v(_vm._s(_vm.$t("vatIncluded")))]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.bookingConfirmationType == "bookingConfirmation" &&
                  _vm.currentBooking.receipt &&
                  _vm.currentBooking.receipt.booking_copy &&
                  _vm.currentBooking.receipt.booking_copy.internal_services
                    .length > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col p-0 w-full flex flex-wrap justify-between mt-0",
                        },
                        [
                          _c(
                            "h3",
                            { staticClass: "opacity-75 text-lg lg:text-2xl" },
                            [
                              _c("i", {
                                staticClass: "far fa-solid fa-layer-plus",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("addBookingSidebar.servicesNumber", {
                                      servicesCount:
                                        _vm.currentBooking.receipt.booking_copy
                                          .internal_services.length,
                                    })
                                  )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.bookingConfirmationType == "bookingConfirmation"
                    ? _c("div", [
                        _vm.currentBooking.state_id == 3
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col p-0 w-full flex flex-wrap md:justify-between justify-center",
                              },
                              [
                                _vm.currentBooking.state_id == 3
                                  ? _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "opacity-75 text-success md:mt-2 mt-5",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-solid fa-check",
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("depositPaid"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.currentBooking.receipt.qosoor_discount > 0
                                  ? _c(
                                      "h3",
                                      { staticClass: "md:mt-2 mt-5" },
                                      [
                                        _c(
                                          "vs-chip",
                                          { attrs: { color: "#6a0dad" } },
                                          [
                                            _c("vs-avatar", {
                                              attrs: {
                                                icon: "celebration",
                                                color: "#6a0dad",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("eventfulDiscount")
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentBooking.receipt.agent_discount > 0
                                  ? _c(
                                      "h3",
                                      { staticClass: "md:mt-2 mt-5" },
                                      [
                                        _c(
                                          "vs-chip",
                                          { attrs: { color: "#6a0dad" } },
                                          [
                                            _c("vs-avatar", {
                                              attrs: {
                                                icon: "celebration",
                                                color: "#6a0dad",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("eventfulDiscount")
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col p-0 w-full flex flex-wrap md:justify-end justify-center",
                              },
                              [
                                _vm.currentBooking.receipt.qosoor_discount > 0
                                  ? _c(
                                      "h3",
                                      { staticClass: "md:mt-2 mt-5" },
                                      [
                                        _c(
                                          "vs-chip",
                                          { attrs: { color: "#6a0dad" } },
                                          [
                                            _c("vs-avatar", {
                                              attrs: {
                                                icon: "celebration",
                                                color: "#6a0dad",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("eventfulDiscount")
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentBooking.receipt.agent_discount > 0
                                  ? _c(
                                      "h3",
                                      { staticClass: "md:mt-2 mt-5" },
                                      [
                                        _c(
                                          "vs-chip",
                                          { attrs: { color: "#10163A" } },
                                          [
                                            _c("vs-avatar", {
                                              attrs: {
                                                icon: "celebration",
                                                color: "#10163A",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("agentDiscount", {
                                                    venueName:
                                                      _vm.currentVenue
                                                        .arabic_name,
                                                  })
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                      ])
                    : _vm._e(),
                  _vm.bookingConfirmationType == "bookingConfirmation" &&
                  _vm.currentBooking.customer_notes
                    ? _c("vs-textarea", {
                        staticClass: "mt-5 w-full",
                        attrs: {
                          label: _vm.$t("addBookingSidebar.customerNotes"),
                          value: _vm.currentBooking.customer_notes,
                          readonly: "",
                        },
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _vm.currentBooking
        ? _c(
            "vs-prompt",
            {
              attrs: {
                title: _vm.$t(
                  "home.editBookingOptionForm.rejectConfirmPrompt.title"
                ),
                color: "danger",
                "buttons-hidden": true,
                active: _vm.rejectConfirmationPrompt,
              },
              on: {
                "update:active": function ($event) {
                  _vm.rejectConfirmationPrompt = $event
                },
              },
            },
            [
              _vm.currentBooking
                ? _c("div", [
                    _vm.cancelationReasonRadio == null
                      ? _c("div", { staticClass: "vx-col w-full" }, [
                          _c("div", { staticClass: "container mt-2" }, [
                            _c("div", { staticClass: "vx-row required" }, [
                              _c("label", { staticClass: "v-select-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("bookingConfirmation.rejection.why")
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "vx-row" },
                              [
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-6 mt-2",
                                    attrs: {
                                      "vs-name": "unavailable_venue_in_date",
                                      "vs-value": "unavailable_venue_in_date",
                                    },
                                    model: {
                                      value: _vm.cancelationReasonRadio,
                                      callback: function ($$v) {
                                        _vm.cancelationReasonRadio = $$v
                                      },
                                      expression: "cancelationReasonRadio",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "bookingConfirmation.rejection.unavailable_venue_in_date",
                                          {
                                            date: _vm.currentBooking.start_date,
                                          }
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-2 mt-2",
                                    attrs: {
                                      "vs-name":
                                        "unavailable_booking_option_in_date",
                                      "vs-value":
                                        "unavailable_booking_option_in_date",
                                    },
                                    model: {
                                      value: _vm.cancelationReasonRadio,
                                      callback: function ($$v) {
                                        _vm.cancelationReasonRadio = $$v
                                      },
                                      expression: "cancelationReasonRadio",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "bookingConfirmation.rejection.unavailable_booking_option_in_date",
                                          {
                                            option_name:
                                              _vm.currentBooking.receipt
                                                .booking_option.name,
                                            date: _vm.currentBooking.start_date,
                                          }
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-6 mt-2",
                                    attrs: {
                                      "vs-name": "other",
                                      "vs-value": "other",
                                    },
                                    model: {
                                      value: _vm.cancelationReasonRadio,
                                      callback: function ($$v) {
                                        _vm.cancelationReasonRadio = $$v
                                      },
                                      expression: "cancelationReasonRadio",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "bookingConfirmation.rejection.other"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _c("div", [
                          _vm.cancelationReasonRadio ==
                          "unavailable_venue_in_date"
                            ? _c(
                                "div",
                                { staticClass: "vx-col w-full md:mb-2 pl-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex w-full mb-3" },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "border",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.cancelationReasonRadio = null
                                              _vm.suggestedDates = []
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("back")))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "container mt-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vx-row required" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "v-select-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "bookingConfirmation.rejection.chooseAvailableDate"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "flex w-full" }, [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "flex flex-wrap w-full",
                                        },
                                        _vm._l(
                                          _vm.suggestedDatesArr,
                                          function (suggestedDate) {
                                            return _c(
                                              "li",
                                              {
                                                key: suggestedDate,
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "vs-checkbox",
                                                  {
                                                    attrs: {
                                                      "vs-value": suggestedDate,
                                                    },
                                                    model: {
                                                      value: _vm.suggestedDates,
                                                      callback: function ($$v) {
                                                        _vm.suggestedDates = $$v
                                                      },
                                                      expression:
                                                        "suggestedDates",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(suggestedDate)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _vm.suggestedDates.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-col w-full space-y-3 mt-5",
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "bookingConfirmation.rejection.option_and_date_will_be_blocked",
                                                      {
                                                        option_name:
                                                          _vm.currentBooking
                                                            .receipt
                                                            .booking_option
                                                            .name,
                                                        date: _vm.currentBooking
                                                          .start_date,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "w-full",
                                                attrs: {
                                                  icon: "block",
                                                  color: "danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.blockDateAgentSelection =
                                                      "option_only"
                                                    _vm.updateBookingState(
                                                      "reject"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "bookingConfirmation.rejection.yes_block_it"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "w-full",
                                                attrs: {
                                                  icon: "check",
                                                  color: "success",
                                                  type: "border",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.blockDateAgentSelection =
                                                      null
                                                    _vm.updateBookingState(
                                                      "reject"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "bookingConfirmation.rejection.do_not_block_it"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "bookingConfirmation.rejection.do_not_do_it_again"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.cancelationReasonRadio ==
                          "unavailable_booking_option_in_date"
                            ? _c(
                                "div",
                                { staticClass: "vx-col w-full md:mb-2 pl-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex w-full mb-3" },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "border",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.cancelationReasonRadio = null
                                              _vm.suggestedDates = []
                                              _vm.alternativeBookingOptions = []
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("back")))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "container mt-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vx-row required" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "v-select-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "bookingConfirmation.rejection.chooseAvailableDate"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "flex w-full" }, [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "flex flex-wrap w-full",
                                        },
                                        _vm._l(
                                          _vm.suggestedDatesArr,
                                          function (suggestedDate) {
                                            return _c(
                                              "li",
                                              {
                                                key: suggestedDate,
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "vs-checkbox",
                                                  {
                                                    attrs: {
                                                      "vs-value": suggestedDate,
                                                    },
                                                    model: {
                                                      value: _vm.suggestedDates,
                                                      callback: function ($$v) {
                                                        _vm.suggestedDates = $$v
                                                      },
                                                      expression:
                                                        "suggestedDates",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(suggestedDate)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _c("div", { staticClass: "my-5" }),
                                    _c(
                                      "div",
                                      { staticClass: "vx-row required" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "v-select-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "bookingConfirmation.rejection.chooseAlternativeBookingOptions"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "flex w-full" }, [
                                      _c(
                                        "ul",
                                        { staticClass: "centerx w-full" },
                                        _vm._l(
                                          _vm.bookingOptionsList,
                                          function (bookingOption) {
                                            return _c(
                                              "li",
                                              {
                                                key: bookingOption.id,
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  "vs-checkbox",
                                                  {
                                                    attrs: {
                                                      "vs-value": bookingOption,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.alternativeBookingOptions,
                                                      callback: function ($$v) {
                                                        _vm.alternativeBookingOptions =
                                                          $$v
                                                      },
                                                      expression:
                                                        "alternativeBookingOptions",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(bookingOption.name)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]),
                                  _vm.suggestedDates.length > 0 &&
                                  _vm.alternativeBookingOptions.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-col w-full space-y-3 mt-5",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "bookingConfirmation.rejection.date_will_be_blocked",
                                                    {
                                                      date: _vm.currentBooking
                                                        .start_date,
                                                    }
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "w-full",
                                              attrs: {
                                                icon: "block",
                                                color: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.blockDateAgentSelection =
                                                    "whole_venue"
                                                  _vm.updateBookingState(
                                                    "reject"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "bookingConfirmation.rejection.yes_block_it"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "w-full",
                                              attrs: {
                                                icon: "check",
                                                color: "success",
                                                type: "border",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.blockDateAgentSelection =
                                                    null
                                                  _vm.updateBookingState(
                                                    "reject"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "bookingConfirmation.rejection.do_not_block_it"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "bookingConfirmation.rejection.do_not_do_it_again"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm.cancelationReasonRadio == "other"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col w-full md:mb-2 mt-5 pl-2",
                                },
                                [
                                  _c("h5", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "bookingConfirmation.rejection.writeRejectionReason"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "flex w-full" },
                                    [
                                      _c("vs-input", {
                                        staticClass: "w-full",
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "bookingConfirmation.rejection.rejection_reason_placeholder"
                                          ),
                                        },
                                        model: {
                                          value: _vm.otherCancelationReason,
                                          callback: function ($$v) {
                                            _vm.otherCancelationReason = $$v
                                          },
                                          expression: "otherCancelationReason",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.otherCancelationReason.length > 5
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-col w-full space-y-3 mt-5",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "bookingConfirmation.rejection.rejection_reason_will_be_sent_to_customer"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "w-full",
                                              attrs: {
                                                icon: "block",
                                                color: "danger",
                                                type: "border",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.blockDateAgentSelection =
                                                    null
                                                  _vm.updateBookingState(
                                                    "reject"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "bookingConfirmation.rejection.send_rejection_reason"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }