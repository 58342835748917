var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "the-navbar__user-meta flex items-center" }, [
    _c("div", { staticClass: "text-right leading-tight hidden sm:block" }, [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c("h4", { staticClass: "mt-1 mx-2" }, [
            this.semiLightTheme
              ? _c("i", { staticClass: "far fa-sun fa-lg text-warning" })
              : _c("i", { staticClass: "far fa-moon fa-lg" }),
          ]),
          _c("vs-switch", {
            on: { click: _vm.changeTheme },
            model: {
              value: _vm.semiLightTheme,
              callback: function ($$v) {
                _vm.semiLightTheme = $$v
              },
              expression: "semiLightTheme",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }