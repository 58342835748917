var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("booking-preview", {
        ref: "preview",
        attrs: {
          bookingPreviewActive: _vm.bookingPreviewActive,
          currentBooking: _vm.currentBooking,
        },
        on: {
          "update:bookingPreviewActive": function ($event) {
            _vm.bookingPreviewActive = $event
          },
          "update:booking-preview-active": function ($event) {
            _vm.bookingPreviewActive = $event
          },
          "update:currentBooking": function ($event) {
            _vm.currentBooking = $event
          },
          "update:current-booking": function ($event) {
            _vm.currentBooking = $event
          },
          updateCurrentBooking: function ($event) {
            _vm.currentBooking = $event
          },
          openEditBookingPopUp: _vm.editData,
        },
      }),
      _c("booking-form-pop-up", {
        attrs: {
          currentBooking: _vm.currentBooking,
          bookingFormPopUpActive: _vm.bookingFormPopUpActive,
          isEdit: _vm.isBookingEdit,
          startDate: _vm.startDate,
          endDate: _vm.endDate,
        },
        on: {
          "update:currentBooking": function ($event) {
            _vm.currentBooking = $event
          },
          "update:current-booking": function ($event) {
            _vm.currentBooking = $event
          },
          updateCurrentBooking: function ($event) {
            _vm.currentBooking = $event
          },
          "update:bookingFormPopUpActive": function ($event) {
            _vm.bookingFormPopUpActive = $event
          },
          "update:booking-form-pop-up-active": function ($event) {
            _vm.bookingFormPopUpActive = $event
          },
        },
      }),
      _c("booking-confirmation", {
        attrs: {
          bookingConfirmationPopUp: _vm.bookingConfirmationPopUp,
          currentBooking: _vm.currentBooking,
          bookingConfirmationType: _vm.bookingConfirmationType,
        },
        on: {
          "update:bookingConfirmationPopUp": function ($event) {
            _vm.bookingConfirmationPopUp = $event
          },
          "update:booking-confirmation-pop-up": function ($event) {
            _vm.bookingConfirmationPopUp = $event
          },
          "update:currentBooking": function ($event) {
            _vm.currentBooking = $event
          },
          "update:current-booking": function ($event) {
            _vm.currentBooking = $event
          },
          updateCurrentBooking: function ($event) {
            _vm.currentBooking = $event
          },
          openPreview: function ($event) {
            _vm.bookingPreviewActive = true
          },
        },
      }),
      _c("h4", { staticClass: "mb-2" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "vs-table",
        {
          ref: "table",
          staticClass: "mb-5",
          attrs: {
            sst: true,
            pagination: _vm.isPagination,
            search: _vm.isSearch,
            "max-items": parseInt(_vm.maxItems),
            total: parseInt(_vm.total),
            data: _vm.bookingsItems,
          },
          on: { selected: _vm.showData },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "tbody",
                    [
                      _vm._l(data, function (tr, indextr) {
                        return _c(
                          "vs-tr",
                          { key: indextr, attrs: { data: tr } },
                          [
                            _c(
                              "vs-td",
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: tr.state.description } },
                                  [
                                    _c(
                                      "vs-chip",
                                      {
                                        staticClass: "product-order-status",
                                        attrs: { color: tr.state.color },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "font-medium truncate",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(tr.state.display_name)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("vs-td", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "product-name font-medium truncate",
                                },
                                [_vm._v(_vm._s(tr.customer_name))]
                              ),
                            ]),
                            _c("vs-td", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "product-no font-medium truncate",
                                },
                                [_vm._v(_vm._s(tr.booking_number))]
                              ),
                            ]),
                            _c(
                              "vs-td",
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: tr.source_description } },
                                  [
                                    _c(
                                      "vs-chip",
                                      {
                                        staticClass: "product-order-status",
                                        attrs: { color: tr.source_color },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "font-medium truncate",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  `bookingSource.${tr.source}`
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-td",
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: tr.created_at } },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "booking-created truncate",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                tr.created_at,
                                                "DD-MM-YYYY"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-td",
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: tr.start_date } },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "booking-date truncate" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                tr.start_date,
                                                "DD-MM-YYYY"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _vm._l(_vm.generalInfoColumnsTitles, function (col, index) {
                return _c(
                  "vs-th",
                  { key: index, attrs: { "sort-key": col.sortKey } },
                  [
                    _c(
                      "p",
                      { staticClass: "table-head font-medium truncate" },
                      [_vm._v(_vm._s(col.title))]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }