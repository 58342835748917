var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex flex-wrap" },
      [
        _c("vs-input", {
          staticClass: "w-full pr-1 mb-3",
          attrs: { label: _vm.$t("discountCouponForm.name"), disabled: "" },
          model: {
            value: _vm.discountCoupon.name,
            callback: function ($$v) {
              _vm.$set(_vm.discountCoupon, "name", $$v)
            },
            expression: "discountCoupon.name",
          },
        }),
        _c("vs-input", {
          staticClass: "w-1/2 pr-1 mb-3",
          attrs: { label: _vm.$t("discountCouponForm.code"), disabled: "" },
          model: {
            value: _vm.discountCoupon.code,
            callback: function ($$v) {
              _vm.$set(_vm.discountCoupon, "code", $$v)
            },
            expression: "discountCoupon.code",
          },
        }),
        _c("vs-input", {
          staticClass: "w-1/2 pr-1 mb-3",
          attrs: {
            label: _vm.$t("discountCouponForm.maxUsageCount"),
            disabled: "",
          },
          model: {
            value: _vm.discountCoupon.max_usage_count,
            callback: function ($$v) {
              _vm.$set(_vm.discountCoupon, "max_usage_count", $$v)
            },
            expression: "discountCoupon.max_usage_count",
          },
        }),
        _vm.discountCoupon.has_date_range == "yes"
          ? _c(
              "div",
              { staticClass: "w-full flex" },
              [
                _c("vs-input", {
                  staticClass: "w-1/2 pr-1 mb-3",
                  attrs: {
                    label: _vm.$t("discountCouponForm.startDate"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.discountCoupon.start_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.discountCoupon, "start_date", $$v)
                    },
                    expression: "discountCoupon.start_date",
                  },
                }),
                _c("vs-input", {
                  staticClass: "w-1/2 pr-1 mb-3",
                  attrs: {
                    label: _vm.$t("discountCouponForm.endDate"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.discountCoupon.end_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.discountCoupon, "end_date", $$v)
                    },
                    expression: "discountCoupon.end_date",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.discountCoupon.discount_type == "fixed"
          ? _c("vs-input", {
              staticClass: "w-full lg:w-1/2 md:mb-2 pl-2 mt-2",
              attrs: {
                label: _vm.$t("discountCouponForm.discountType"),
                value: _vm.$t("discountCouponForm.fixedDiscount"),
                disabled: "",
              },
            })
          : _vm._e(),
        _vm.discountCoupon.discount_type == "percentage"
          ? _c("vs-input", {
              staticClass: "w-full lg:w-1/2 md:mb-2 pl-2 mt-2",
              attrs: {
                label: _vm.$t("discountCouponForm.discountType"),
                value: _vm.$t("discountCouponForm.percentageDiscount"),
                disabled: "",
              },
            })
          : _vm._e(),
        _c("vs-input", {
          staticClass: "w-full lg:w-1/2 md:mb-2 pl-2 mt-2",
          attrs: {
            label: _vm.$t("discountCouponForm.discount"),
            "description-text":
              _vm.discountCoupon.discount_type == "percentage"
                ? _vm.$t("discountCouponForm.discountInPercentage")
                : _vm.$t("discountCouponForm.discountInRiyals"),
            disabled: "",
          },
          model: {
            value: _vm.discountCoupon.discount,
            callback: function ($$v) {
              _vm.$set(_vm.discountCoupon, "discount", $$v)
            },
            expression: "discountCoupon.discount",
          },
        }),
        _vm.discountCoupon.min_price
          ? _c("vs-input", {
              staticClass: "w-full lg:w-1/2 md:mb-2 pl-2 mt-2",
              attrs: {
                label: _vm.$t("discountCouponForm.minPrice"),
                "description-text": _vm.$t(
                  "discountCouponForm.minPriceDescText"
                ),
                disabled: "",
              },
              model: {
                value: _vm.discountCoupon.min_price,
                callback: function ($$v) {
                  _vm.$set(_vm.discountCoupon, "min_price", $$v)
                },
                expression: "discountCoupon.min_price",
              },
            })
          : _vm._e(),
        _vm.discountCoupon.max_amount
          ? _c("vs-input", {
              staticClass: "w-full lg:w-1/2 md:mb-2 pl-2 mt-2",
              attrs: {
                label: _vm.$t("discountCouponForm.maxPrice"),
                "description-text": _vm.$t(
                  "discountCouponForm.maxPriceDescText"
                ),
                disabled: "",
              },
              model: {
                value: _vm.discountCoupon.max_amount,
                callback: function ($$v) {
                  _vm.$set(_vm.discountCoupon, "max_amount", $$v)
                },
                expression: "discountCoupon.max_amount",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }