var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentRoom != null
    ? _c(
        "div",
        [
          _c(
            "vs-popup",
            {
              staticClass: "holamundo",
              attrs: {
                title: !_vm.isEditRoom
                  ? _vm.$t("editRoomForm.addRoom")
                  : _vm.$t("editRoomForm.editRoom") +
                    " | " +
                    _vm.currentRoom.name,
                active: _vm.isActive,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isActive = $event
                },
                close: _vm.closePopup,
              },
            },
            [
              _c("div", { staticClass: "container w-full" }, [
                _c("div", { staticClass: "flex flex-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2 md:mb-2 pl-2 mt-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full required",
                        attrs: {
                          label: _vm.$t("editRoomForm.name"),
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.currentRoom.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentRoom, "name", $$v)
                          },
                          expression: "currentRoom.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2 md:mb-2 pl-2 mt-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          type: "number",
                          label: _vm.$t("editRoomForm.count"),
                        },
                        model: {
                          value: _vm.currentRoom.count,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentRoom, "count", $$v)
                          },
                          expression: "currentRoom.count",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:mb-2 pl-2 mt-4" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mx-2",
                          attrs: { "icon-pack": "feather", icon: "icon-check" },
                          on: { click: _vm.editRoom },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }