var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentBookingOption != null
    ? _c(
        "div",
        [
          _c(
            "vs-popup",
            {
              staticClass: "holamundo",
              attrs: {
                title:
                  _vm.actionType == "addBookingOption"
                    ? _vm.$t("home.editBookingOptionForm.addBookingOption")
                    : _vm.actionType == "editBookingOption"
                    ? _vm.$t("home.editBookingOptionForm.editBookingOption") +
                      " | " +
                      _vm.currentBookingOption.name
                    : _vm.$t(
                        "home.editBookingOptionForm.duplicateBookingOption"
                      ) +
                      " | " +
                      _vm.currentBookingOption.name,
                active: _vm.isActive,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isActive = $event
                },
                close: _vm.closePopup,
              },
            },
            [
              _c("div", { staticClass: "container w-full mb-10" }, [
                _c("div", { staticClass: "flex flex-wrap" }, [
                  _c("h4", { staticClass: "my-3 opacity-75 w-full" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("home.editBookingOptionForm.bookingOptionImages")
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" },
                    [
                      _c("vue-upload-multiple-image", {
                        attrs: {
                          idUpload: _vm.imagesUploadId,
                          showEdit: false,
                          popupText: _vm.$t(
                            "VueUploadMultipleImages.popupText"
                          ),
                          primaryText: _vm.$t(
                            "VueUploadMultipleImages.primaryText"
                          ),
                          markIsPrimaryText: _vm.$t(
                            "VueUploadMultipleImages.markIsPrimaryText"
                          ),
                          browseText: _vm.$t(
                            "VueUploadMultipleImages.browseText"
                          ),
                          dragText: "",
                          maxImage: 20,
                          "data-images": _vm.images,
                        },
                        on: {
                          "upload-success": _vm.uploadImageSuccess,
                          "before-remove": _vm.beforeRemove,
                          "mark-is-primary": _vm.primaryMarked,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h4", { staticClass: "my-3 opacity-75 w-full" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("home.editBookingOptionForm.generalInformation")
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2 md:mb-2 pl-2 mt-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full required",
                        attrs: {
                          label: _vm.$t("home.editBookingOptionForm.name"),
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.currentBookingOption.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentBookingOption, "name", $$v)
                          },
                          expression: "currentBookingOption.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2 md:mb-2 pl-2 mt-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          label: _vm.$t(
                            "home.editBookingOptionForm.description"
                          ),
                        },
                        model: {
                          value: _vm.currentBookingOption.description,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentBookingOption,
                              "description",
                              $$v
                            )
                          },
                          expression: "currentBookingOption.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:mb-2 pl-2 mt-2" },
                    [
                      _c("vs-textarea", {
                        staticClass: "w-full",
                        attrs: {
                          label: _vm.$t(
                            "home.editBookingOptionForm.detailedDescription"
                          ),
                          height: "150px",
                        },
                        model: {
                          value: _vm.currentBookingOption.detailed_description,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentBookingOption,
                              "detailed_description",
                              $$v
                            )
                          },
                          expression:
                            "currentBookingOption.detailed_description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col w-full mb-2 pl-2" }, [
                    _c("div", { staticClass: "container mt-2" }, [
                      _c("div", { staticClass: "vx-row" }, [
                        _c("label", { staticClass: "v-select-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "home.editBookingOptionForm.minMaxCountTitle"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-row mt-3" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col p-0 w-full lg:w-1/2 md:mb-2 pr-2 mt-2",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                type: "number",
                                label: _vm.$t(
                                  "home.editBookingOptionForm.minimumMenCount"
                                ),
                              },
                              model: {
                                value: _vm.currentBookingOption.men_count_min,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentBookingOption,
                                    "men_count_min",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentBookingOption.men_count_min",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col p-0 w-full lg:w-1/2 md:mb-2 pr-2 mt-2",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                type: "number",
                                label: _vm.$t(
                                  "home.editBookingOptionForm.maximumMenCount"
                                ),
                              },
                              model: {
                                value: _vm.currentBookingOption.men_count_max,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentBookingOption,
                                    "men_count_max",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentBookingOption.men_count_max",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col p-0 w-full lg:w-1/2 md:mb-2 pr-2 mt-2",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                type: "number",
                                label: _vm.$t(
                                  "home.editBookingOptionForm.minimumWomenCount"
                                ),
                              },
                              model: {
                                value: _vm.currentBookingOption.women_count_min,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentBookingOption,
                                    "women_count_min",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentBookingOption.women_count_min",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col p-0 w-full lg:w-1/2 md:mb-2 pr-2 mt-2",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                type: "number",
                                label: _vm.$t(
                                  "home.editBookingOptionForm.maximumWomenCount"
                                ),
                              },
                              model: {
                                value: _vm.currentBookingOption.women_count_max,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentBookingOption,
                                    "women_count_max",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentBookingOption.women_count_max",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-full mb-2 mt-4 pl-2" }, [
                    _c("div", { staticClass: "container mt-2" }, [
                      _c("div", { staticClass: "vx-row required" }, [
                        _c("label", { staticClass: "v-select-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("home.editBookingOptionForm.availability")
                            )
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "vx-row mt-3" },
                        [
                          _c(
                            "vs-radio",
                            {
                              staticClass: "mr-8 mt-1",
                              attrs: {
                                "vs-name": "currentBookingOption.availability",
                                "vs-value": "available",
                              },
                              model: {
                                value: _vm.currentBookingOption.availability,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentBookingOption,
                                    "availability",
                                    $$v
                                  )
                                },
                                expression: "currentBookingOption.availability",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.selectText.available"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "vs-radio",
                            {
                              staticClass: "mr-4 mt-1",
                              attrs: {
                                "vs-name": "currentBookingOption.availability",
                                "vs-value": "hidden",
                              },
                              model: {
                                value: _vm.currentBookingOption.availability,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentBookingOption,
                                    "availability",
                                    $$v
                                  )
                                },
                                expression: "currentBookingOption.availability",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.selectText.hidden"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:mb-2 pl-2 mt-2" },
                    [
                      _c("label", { staticClass: "v-select-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "home.editBookingOptionForm.chooseEventTypes"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "flex flex-wrap" },
                        _vm._l(_vm.eventTypesList, function (eventType) {
                          return _c(
                            "li",
                            { key: eventType.id, staticClass: "mt-2 mr-6" },
                            [
                              _c(
                                "vs-checkbox",
                                {
                                  attrs: { "vs-value": eventType.id },
                                  model: {
                                    value: _vm.selectedEventTypes,
                                    callback: function ($$v) {
                                      _vm.selectedEventTypes = $$v
                                    },
                                    expression: "selectedEventTypes",
                                  },
                                },
                                [_vm._v(_vm._s(eventType.display_name))]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:mb-2 pl-2 mt-10" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("home.editBookingOptionForm.categories")
                          )
                        ),
                      ]),
                      _vm._l(_vm.categories, function (category) {
                        return _c(
                          "div",
                          { key: category.id, staticClass: "mt-5" },
                          [
                            _vm._v(" " + _vm._s(category.title) + " "),
                            _c(
                              "ul",
                              { staticClass: "flex flex-wrap" },
                              _vm._l(category.types, function (category_type) {
                                return _c(
                                  "li",
                                  {
                                    key: category_type.id,
                                    staticClass: "mt-2",
                                  },
                                  [
                                    _c(
                                      "vs-checkbox",
                                      {
                                        attrs: { "vs-value": category_type.id },
                                        on: {
                                          change: function ($event) {
                                            return _vm.categoryTypeSelected(
                                              category_type.id
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedCategoryTypes,
                                          callback: function ($$v) {
                                            _vm.selectedCategoryTypes = $$v
                                          },
                                          expression: "selectedCategoryTypes",
                                        },
                                      },
                                      [_vm._v(_vm._s(category_type.title))]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm.showDates
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full md:mb-2 mt-5 pl-2" },
                        [
                          _c("div", { staticClass: "flex w-full" }, [
                            _c("small", { staticClass: "gray-note" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.eventDatesNote"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex w-full" },
                            [
                              _c("v-date-picker", {
                                staticClass: "flex w-full",
                                attrs: {
                                  mode: "datetime",
                                  "is-range": "",
                                  color: "orange",
                                  masks: _vm.dateFormat,
                                  "min-date": _vm.minDate,
                                  "max-date": _vm.maxDate,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({
                                        inputValue,
                                        inputEvents,
                                        isDragging,
                                      }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "flex w-full" },
                                            [
                                              _c(
                                                "vs-input",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "flex-grow py-1 bg-gray-100 border rounded w-full pr-1",
                                                    class: isDragging
                                                      ? "text-gray-600"
                                                      : "text-gray-900",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "home.editBookingOptionForm.eventStartDate"
                                                      ),
                                                      value: inputValue.start,
                                                      icon: "today",
                                                    },
                                                  },
                                                  inputEvents.start
                                                )
                                              ),
                                              _c(
                                                "vs-input",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "flex-grow py-1 bg-gray-100 border rounded w-full",
                                                    class: isDragging
                                                      ? "text-gray-600"
                                                      : "text-gray-900",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "home.editBookingOptionForm.eventEndDate"
                                                      ),
                                                      value: inputValue.end,
                                                      icon: "event",
                                                    },
                                                  },
                                                  inputEvents.end
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1016828722
                                ),
                                model: {
                                  value: _vm.optionDateRange,
                                  callback: function ($$v) {
                                    _vm.optionDateRange = $$v
                                  },
                                  expression: "optionDateRange",
                                },
                              }),
                              _vm.optionDateRange.start ||
                              _vm.optionDateRange.end
                                ? _c(
                                    "div",
                                    { staticClass: "pt-2" },
                                    [
                                      _c("vs-button", {
                                        staticClass: "my-5 mx-1",
                                        attrs: {
                                          type: "border",
                                          "icon-pack": "feather",
                                          icon: "icon-trash",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.optionDateRange = {
                                              start: null,
                                              end: null,
                                            }
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "w-full" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full my-3 opacity-50" },
                      [_c("hr")]
                    ),
                    _c("div", { staticClass: "vx-col w-full mb-2 pl-2" }, [
                      _c("div", { staticClass: "container mt-2" }, [
                        _c("div", { staticClass: "vx-row required" }, [
                          _c("label", { staticClass: "v-select-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.bookingOptionType"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-row mt-3" },
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-6 mt-1",
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.availability_type",
                                  "vs-value": "size_based",
                                },
                                model: {
                                  value:
                                    _vm.currentBookingOption.availability_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "availability_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.availability_type",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.sizeBased"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-2 mt-1",
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.availability_type",
                                  "vs-value": "rooms_based",
                                },
                                model: {
                                  value:
                                    _vm.currentBookingOption.availability_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "availability_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.availability_type",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.roomsBased"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm.actionType != "duplicateBookingOption" &&
                    _vm.currentBookingOption.availability_type == "size_based"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                type: "number",
                                label:
                                  _vm.$t(
                                    "home.editBookingOptionForm.sizeNote"
                                  ) + ":",
                              },
                              model: {
                                value: _vm.currentBookingOption.bookings_size,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentBookingOption,
                                    "bookings_size",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentBookingOption.bookings_size",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm.actionType != "duplicateBookingOption" &&
                  _vm.currentBookingOption.availability_type == "rooms_based"
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full md:mb-2 pl-2 mt-2" },
                        [
                          _c("label", { staticClass: "v-select-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("home.editBookingOptionForm.chooseRooms")
                              )
                            ),
                          ]),
                          _c(
                            "ul",
                            { staticClass: "flex flex-wrap" },
                            _vm._l(_vm.roomsList, function (room) {
                              return _c(
                                "li",
                                { key: room.id, staticClass: "mt-2 mr-6" },
                                [
                                  _c(
                                    "vs-checkbox",
                                    {
                                      attrs: { "vs-value": room.id },
                                      model: {
                                        value: _vm.selectedRooms,
                                        callback: function ($$v) {
                                          _vm.selectedRooms = $$v
                                        },
                                        expression: "selectedRooms",
                                      },
                                    },
                                    [_vm._v(_vm._s(room.name))]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.actionType == "duplicateBookingOption" &&
                  _vm.agentVenuesData.length
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full md:mb-2 pl-2 mt-2" },
                        [
                          _c("h4", { staticClass: "my-2 opacity-75 w-full" }, [
                            _vm._v("الفروع:"),
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.agentVenuesData, function (venue) {
                              return _c(
                                "li",
                                {
                                  key: venue.id,
                                  staticClass: "mt-2 mr-6 mb-5",
                                },
                                [
                                  _c(
                                    "vs-checkbox",
                                    {
                                      attrs: { "vs-value": venue.id },
                                      model: {
                                        value: _vm.selectedBranches,
                                        callback: function ($$v) {
                                          _vm.selectedBranches = $$v
                                        },
                                        expression: "selectedBranches",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(venue.name) +
                                          " " +
                                          _vm._s(
                                            venue.id == _vm.currentVenueId
                                              ? "(الفرع الحالي)"
                                              : ""
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm.currentBookingOption.availability_type ==
                                    "rooms_based" &&
                                  _vm.selectedBranches.includes(venue.id)
                                    ? _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "flex flex-wrap mt-2 ml-5",
                                        },
                                        _vm._l(venue.rooms, function (room) {
                                          return _c(
                                            "li",
                                            {
                                              key: room.id,
                                              staticClass: "mt-2 mr-5",
                                            },
                                            [
                                              _c(
                                                "vs-checkbox",
                                                {
                                                  attrs: {
                                                    "vs-value": room.id,
                                                  },
                                                  model: {
                                                    value: venue.selectedRooms,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        venue,
                                                        "selectedRooms",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "venue.selectedRooms",
                                                  },
                                                },
                                                [_vm._v(_vm._s(room.name))]
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm.currentBookingOption.availability_type ==
                                    "size_based" &&
                                  _vm.selectedBranches.includes(venue.id)
                                    ? _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "flex flex-wrap mt-2 ml-5",
                                        },
                                        [
                                          _c(
                                            "li",
                                            { staticClass: "mt-2 mr-5" },
                                            [
                                              _c("vs-input", {
                                                staticClass: "w-full",
                                                attrs: {
                                                  type: "number",
                                                  label:
                                                    _vm.$t(
                                                      "home.editBookingOptionForm.sizeNote"
                                                    ) + ":",
                                                },
                                                model: {
                                                  value: venue.bookings_size,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      venue,
                                                      "bookings_size",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "venue.bookings_size",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "vx-col w-full my-3 opacity-50" }, [
                    _c("hr"),
                  ]),
                  _c("h4", { staticClass: "my-2 opacity-75 w-full" }, [
                    _vm._v(
                      _vm._s(_vm.$t("home.editBookingOptionForm.pricing"))
                    ),
                  ]),
                  _c("div", { staticClass: "vx-col w-full mb-2 pl-4" }, [
                    _c("div", { staticClass: "container mt-2" }, [
                      _c("div", { staticClass: "vx-row required" }, [
                        _c("label", { staticClass: "v-select-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("home.editBookingOptionForm.pricingType")
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-row mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.pricing_type",
                                  "vs-value": "fixed",
                                },
                                model: {
                                  value: _vm.currentBookingOption.pricing_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "pricing_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.pricing_type",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.fixed"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "vx-row ml-6 pl-1" }, [
                              _c("small", { staticClass: "gray-note" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.fixedNote"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mt-3",
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.pricing_type",
                                  "vs-value": "per_person",
                                },
                                model: {
                                  value: _vm.currentBookingOption.pricing_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "pricing_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.pricing_type",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.per_person"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "vx-row ml-6 pl-1" }, [
                              _c("small", { staticClass: "gray-note" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.perPersonNote"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mt-3",
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.pricing_type",
                                  "vs-value": "per_person_range",
                                },
                                model: {
                                  value: _vm.currentBookingOption.pricing_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "pricing_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.pricing_type",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.perPersonRange"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "vx-row ml-6 pl-1" }, [
                              _c("small", { staticClass: "gray-note" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.perPersonRangeNote"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mt-3",
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.pricing_type",
                                  "vs-value": "fixed_range",
                                },
                                model: {
                                  value: _vm.currentBookingOption.pricing_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "pricing_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.pricing_type",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.fixedRange"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "vx-row ml-6 pl-1" }, [
                              _c("small", { staticClass: "gray-note" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.fixedRangeNote"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.currentBookingOption.pricing_type == "fixed"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full lg:w-1/2 lg:mr-5 md:mb-2 pl-2 mt-2",
                        },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "number",
                              label: _vm.$t("home.editBookingOptionForm.price"),
                            },
                            model: {
                              value: _vm.currentBookingOption.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentBookingOption, "price", $$v)
                              },
                              expression: "currentBookingOption.price",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentBookingOption.pricing_type == "per_person"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2",
                        },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "number",
                              label: _vm.$t(
                                "home.editBookingOptionForm.manPrice"
                              ),
                            },
                            model: {
                              value: _vm.currentBookingOption.man_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentBookingOption,
                                  "man_price",
                                  $$v
                                )
                              },
                              expression: "currentBookingOption.man_price",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentBookingOption.pricing_type == "per_person"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2",
                        },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "number",
                              label: _vm.$t(
                                "home.editBookingOptionForm.womanPrice"
                              ),
                            },
                            model: {
                              value: _vm.currentBookingOption.woman_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentBookingOption,
                                  "woman_price",
                                  $$v
                                )
                              },
                              expression: "currentBookingOption.woman_price",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentBookingOption.pricing_type == "per_person" ||
                  _vm.currentBookingOption.pricing_type == "per_person_range"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full lg:w-1/2 lg:mr-5 md:mb-2 pl-2 mt-2",
                        },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "number",
                              label: _vm.$t(
                                "home.editBookingOptionForm.minimumPrice"
                              ),
                            },
                            model: {
                              value: _vm.currentBookingOption.minimum_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentBookingOption,
                                  "minimum_price",
                                  $$v
                                )
                              },
                              expression: "currentBookingOption.minimum_price",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentBookingOption.pricing_type == "per_person_range" ||
                  _vm.currentBookingOption.pricing_type == "fixed_range"
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full md:mb-2 pl-2 mt-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-row mb-5" },
                            [
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "h-full mb-5 py-2 pr-2",
                                  attrs: {
                                    color: "warning",
                                    icon: "sms",
                                    active: _vm.rangePricingAlert,
                                    closable: "",
                                    "close-icon": "close",
                                  },
                                  on: {
                                    "update:active": function ($event) {
                                      _vm.rangePricingAlert = $event
                                    },
                                  },
                                },
                                [
                                  _c("h5", { staticClass: "mb-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.rangePricing.alert.header"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.rangePricing.alert.line1"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm.currentBookingOption.pricing_type ==
                                  "per_person_range"
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.rangePricing.alert.line2_per_person"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.currentBookingOption.pricing_type ==
                                  "fixed_range"
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.rangePricing.alert.line2_fixed"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.currentBookingOption.range_pricing,
                            function (rangePricing, index) {
                              return _c("div", { key: index }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-2 lg:grid-cols-9",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cos-span-1 lg:col-span-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-11/12",
                                          attrs: {
                                            type: "number",
                                            label: _vm.$t(
                                              "servicesForm.groupPricing.menCount"
                                            ),
                                            autocomplete: "off",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.computePrices()
                                            },
                                          },
                                          model: {
                                            value: rangePricing.men_count,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                rangePricing,
                                                "men_count",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rangePricing.men_count",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cos-span-1 lg:col-span-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-11/12",
                                          attrs: {
                                            type: "number",
                                            label: _vm.$t(
                                              "servicesForm.groupPricing.manPrice"
                                            ),
                                            autocomplete: "off",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.computePrices()
                                            },
                                          },
                                          model: {
                                            value: rangePricing.man_price,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                rangePricing,
                                                "man_price",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rangePricing.man_price",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cos-span-1 lg:col-span-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-11/12",
                                          attrs: {
                                            type: "number",
                                            label: _vm.$t(
                                              "servicesForm.groupPricing.womenCount"
                                            ),
                                            autocomplete: "off",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.computePrices()
                                            },
                                          },
                                          model: {
                                            value: rangePricing.women_count,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                rangePricing,
                                                "women_count",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rangePricing.women_count",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cos-span-1 lg:col-span-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-11/12",
                                          attrs: {
                                            type: "number",
                                            label: _vm.$t(
                                              "servicesForm.groupPricing.womanPrice"
                                            ),
                                            autocomplete: "off",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.computePrices()
                                            },
                                          },
                                          model: {
                                            value: rangePricing.woman_price,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                rangePricing,
                                                "woman_price",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rangePricing.woman_price",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mt-1 col-span-1 flex justify-end",
                                      },
                                      [
                                        _c("vs-button", {
                                          staticClass: "m-0 mt-5",
                                          attrs: {
                                            color: "danger",
                                            icon: "delete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeRow(index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("hr"),
                              ])
                            }
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-2 w-full flex justify-end" },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "m-0 mt-5 mr-2",
                                  attrs: { color: "success", icon: "add" },
                                  on: { click: _vm.addRow },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("servicesForm.groupPricing.addRow")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "vx-col w-full pl-4" }, [
                    _c("div", { staticClass: "container mt-2" }, [
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full lg:w-1/2 mt-5" },
                          [
                            _c("div", { staticClass: "vx-row mt-2 required" }, [
                              _c("label", { staticClass: "v-select-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.taxingStatus"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "vx-row mt-1" },
                              [
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-6 mt-2",
                                    attrs: {
                                      "vs-name":
                                        "currentBookingOption.is_taxable",
                                      "vs-value": 1,
                                    },
                                    model: {
                                      value:
                                        _vm.currentBookingOption.is_taxable,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentBookingOption,
                                          "is_taxable",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentBookingOption.is_taxable",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.taxable"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-2 mt-2",
                                    attrs: {
                                      "vs-name":
                                        "currentBookingOption.is_taxable",
                                      "vs-value": 0,
                                    },
                                    model: {
                                      value:
                                        _vm.currentBookingOption.is_taxable,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentBookingOption,
                                          "is_taxable",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentBookingOption.is_taxable",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.nonTaxable"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm.currentBookingOption.is_taxable
                          ? _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mt-5" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vx-row mt-2 required" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "v-select-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.taxingIncluding"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-row mt-1" },
                                  [
                                    _c(
                                      "vs-radio",
                                      {
                                        staticClass: "mr-6 mt-1 lg:mt-2",
                                        attrs: {
                                          "vs-name": "tax_included",
                                          "vs-value": "included",
                                        },
                                        model: {
                                          value: _vm.tax_included,
                                          callback: function ($$v) {
                                            _vm.tax_included = $$v
                                          },
                                          expression: "tax_included",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.taxIncluded.included"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-radio",
                                      {
                                        staticClass: "mr-2 mt-3 lg:mt-2",
                                        attrs: {
                                          "vs-name": "tax_included",
                                          "vs-value": "not-included",
                                        },
                                        model: {
                                          value: _vm.tax_included,
                                          callback: function ($$v) {
                                            _vm.tax_included = $$v
                                          },
                                          expression: "tax_included",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.editBookingOptionForm.taxIncluded.notIncluded"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-full my-3 opacity-50" }, [
                    _c("hr"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mt-3" },
                    [
                      _vm.currentBookingOption.pricing_type == "fixed" ||
                      _vm.currentBookingOption.pricing_type == "per_person"
                        ? _c(
                            "vs-table",
                            { attrs: { data: _vm.eventTypesList } },
                            [
                              _c("template", { slot: "header" }, [
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.finalPricesTable.header"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _c(
                                "template",
                                { slot: "thead" },
                                [
                                  _c("vs-th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.finalPricesTable.pricing"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.finalPricesTable.beforeTax"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.finalPricesTable.tax"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.finalPricesTable.totalPrice"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              [
                                _vm.currentBookingOption.pricing_type == "fixed"
                                  ? _c(
                                      "vs-tr",
                                      [
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.editBookingOptionForm.finalPricesTable.fixedPrice"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.finalPrices.fixedPrices
                                                .beforeTax
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.finalPrices.fixedPrices.tax
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.finalPrices.fixedPrices
                                                .finalPrice
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentBookingOption.pricing_type != "fixed"
                                  ? _c(
                                      "vs-tr",
                                      [
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.editBookingOptionForm.finalPricesTable.manPrice"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.finalPrices.variablePrices
                                                .manPrice.beforeTax
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.finalPrices.variablePrices
                                                .manPrice.tax
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.finalPrices.variablePrices
                                                .manPrice.finalPrice
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentBookingOption.pricing_type != "fixed"
                                  ? _c(
                                      "vs-tr",
                                      [
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.editBookingOptionForm.finalPricesTable.womanPrice"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.finalPrices.variablePrices
                                                .womanPrice.beforeTax
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.finalPrices.variablePrices
                                                .womanPrice.tax
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.finalPrices.variablePrices
                                                .womanPrice.finalPrice
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.currentBookingOption.pricing_type ==
                        "per_person_range" ||
                      _vm.currentBookingOption.pricing_type == "fixed_range"
                        ? _c(
                            "vs-table",
                            { attrs: { data: _vm.eventTypesList } },
                            [
                              _c("template", { slot: "header" }, [
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.finalPricesTable.header"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _c(
                                "template",
                                { slot: "thead" },
                                [
                                  _c("vs-th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.finalPricesTable.pricing"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.finalPricesTable.range"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.finalPricesTable.beforeTax"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.finalPricesTable.tax"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("vs-th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.finalPricesTable.totalPrice"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.rangeFinalPrices,
                                function (rangePricing, index) {
                                  return [
                                    _c(
                                      "vs-tr",
                                      {
                                        key:
                                          index +
                                          Math.random()
                                            .toString(36)
                                            .substring(2, 12),
                                      },
                                      [
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.editBookingOptionForm.finalPricesTable.rangeManPrice"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(rangePricing.men_count) +
                                              " " +
                                              _vm._s(_vm.$t("to")) +
                                              " " +
                                              _vm._s(
                                                _vm.currentBookingOption
                                                  .range_pricing.length !=
                                                  index + 1
                                                  ? _vm.currentBookingOption
                                                      .range_pricing[index + 1]
                                                      .men_count - 1
                                                  : "-"
                                              )
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(rangePricing.menBeforeTax) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(rangePricing.menTax) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(rangePricing.menFinalPrice) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-tr",
                                      {
                                        key:
                                          index +
                                          Math.random()
                                            .toString(36)
                                            .substring(2, 12),
                                      },
                                      [
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.editBookingOptionForm.finalPricesTable.rangeWomanPrice"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(rangePricing.women_count) +
                                              " " +
                                              _vm._s(_vm.$t("to")) +
                                              " " +
                                              _vm._s(
                                                _vm.currentBookingOption
                                                  .range_pricing.length !=
                                                  index + 1
                                                  ? _vm.currentBookingOption
                                                      .range_pricing[index + 1]
                                                      .women_count - 1
                                                  : "-"
                                              )
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              rangePricing.womenBeforeTax
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(rangePricing.womenTax) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              rangePricing.womenFinalPrice
                                            ) +
                                              " " +
                                              _vm._s(_vm.$t("SAR"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full lg:w-1/2 mb-2 mt-4 pl-4" },
                    [
                      _c("div", { staticClass: "container mt-2" }, [
                        _c("div", { staticClass: "vx-row required" }, [
                          _c("label", { staticClass: "v-select-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.hasSeasonalPricing"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-row mt-3" },
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-6",
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.has_seasonal_pricing",
                                  "vs-value": "1",
                                },
                                model: {
                                  value:
                                    _vm.currentBookingOption
                                      .has_seasonal_pricing,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "has_seasonal_pricing",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.has_seasonal_pricing",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.yes"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.has_seasonal_pricing",
                                  "vs-value": "0",
                                },
                                model: {
                                  value:
                                    _vm.currentBookingOption
                                      .has_seasonal_pricing,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "has_seasonal_pricing",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.has_seasonal_pricing",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.no"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full lg:w-1/2 mb-2 mt-4 pl-4" },
                    [
                      _c("div", { staticClass: "container mt-2" }, [
                        _c("div", { staticClass: "vx-row required" }, [
                          _c("label", { staticClass: "v-select-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.hasDaysPricing"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-row mt-3" },
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-6",
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.has_days_pricing",
                                  "vs-value": "1",
                                },
                                model: {
                                  value:
                                    _vm.currentBookingOption.has_days_pricing,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "has_days_pricing",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.has_days_pricing",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.yes"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  "vs-name":
                                    "currentBookingOption.has_days_pricing",
                                  "vs-value": "0",
                                },
                                model: {
                                  value:
                                    _vm.currentBookingOption.has_days_pricing,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentBookingOption,
                                      "has_days_pricing",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentBookingOption.has_days_pricing",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "home.editBookingOptionForm.selectText.no"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm.actionType == "duplicateBookingOption"
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full mb-2 mt-4 pl-2" },
                        [
                          _c("div", { staticClass: "container mt-2" }, [
                            _c(
                              "div",
                              { staticClass: "vx-row" },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    model: {
                                      value: _vm.copyCustomPricings,
                                      callback: function ($$v) {
                                        _vm.copyCustomPricings = $$v
                                      },
                                      expression: "copyCustomPricings",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "home.editBookingOptionForm.copyCustomPricings"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-row mt-0 pt-0" },
                              [
                                _c(
                                  "vs-list",
                                  { staticClass: "mt-0 pt-0" },
                                  [
                                    _c("vs-list-item", {
                                      attrs: {
                                        icon: "info",
                                        subtitle: _vm.$t(
                                          "home.editBookingOptionForm.copyCustomPricingsNote"
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "vx-col w-full my-3 opacity-50" }, [
                    _c("hr"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:mb-2 pl-2 mt-4" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mx-2",
                          attrs: { "icon-pack": "feather", icon: "icon-check" },
                          on: { click: _vm.editBookingOption },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }