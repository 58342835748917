var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.service
      ? _c(
          "div",
          [
            _c(
              "vs-popup",
              {
                staticClass: "holamundo",
                attrs: {
                  title:
                    _vm.actionType == "addService"
                      ? _vm.$t("servicesForm.addService")
                      : _vm.actionType == "editService"
                      ? _vm.$t("servicesForm.editService") +
                        " | " +
                        _vm.service.name
                      : _vm.$t("servicesForm.duplicateService") +
                        " | " +
                        _vm.service.name,
                  active: _vm.isActive,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.isActive = $event
                  },
                  close: _vm.closePopup,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "grid lg:grid-cols-2 gap-5 pt-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("vue-upload-multiple-image", {
                          attrs: {
                            idUpload: _vm.imagesUploadId,
                            showEdit: false,
                            popupText: _vm.$t(
                              "VueUploadMultipleImages.popupText"
                            ),
                            primaryText: _vm.$t(
                              "VueUploadMultipleImages.primaryText"
                            ),
                            markIsPrimaryText: _vm.$t(
                              "VueUploadMultipleImages.markIsPrimaryText"
                            ),
                            browseText: _vm.$t(
                              "VueUploadMultipleImages.browseText"
                            ),
                            dragText: "",
                            maxImage: 20,
                            "data-images": _vm.images,
                          },
                          on: {
                            "upload-success": _vm.uploadImageSuccess,
                            "before-remove": _vm.beforeRemove,
                            "mark-is-primary": _vm.primaryMarked,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("vs-input", {
                      staticClass: "w-full required col-span-2",
                      attrs: {
                        label: _vm.$t("servicesForm.name"),
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.service.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.service, "name", $$v)
                        },
                        expression: "service.name",
                      },
                    }),
                    _c("vs-textarea", {
                      staticClass: "w-full col-span-2",
                      attrs: {
                        label: _vm.$t("servicesForm.description"),
                        height: "150px",
                      },
                      model: {
                        value: _vm.service.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.service, "description", $$v)
                        },
                        expression: "service.description",
                      },
                    }),
                    _c("div", { staticClass: "col-span-2" }, [
                      _c("span", { staticClass: "text-md" }, [
                        _vm._v(
                          _vm._s(_vm.$t("servicesForm.choosePricingType"))
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 flex flex-wrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3 rtl:space-x-reverse",
                        },
                        [
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name": "service.pricing_type",
                                "vs-value": "fixed",
                              },
                              model: {
                                value: _vm.service.pricing_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.service, "pricing_type", $$v)
                                },
                                expression: "service.pricing_type",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.selectText.fixed"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name": "service.pricing_type",
                                "vs-value": "variable",
                              },
                              model: {
                                value: _vm.service.pricing_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.service, "pricing_type", $$v)
                                },
                                expression: "service.pricing_type",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.selectText.per_person"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.service.pricing_type == "fixed"
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 grid gap-3" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full col-span-2 lg:col-span-1",
                              attrs: {
                                ttype: "number",
                                label: _vm.$t("servicesForm.fixedPrice"),
                              },
                              model: {
                                value: _vm.service.fixed_price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.service, "fixed_price", $$v)
                                },
                                expression: "service.fixed_price",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "col-span-2 grid gap-3" },
                          [
                            _c(
                              "vs-tabs",
                              { staticClass: "w-full" },
                              [
                                _c(
                                  "vs-tab",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "servicesForm.groupPricing.primaryPrices"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "con-tab-ejemplo" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "vx-row mb-5" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full md:mb-2 pl-2 mt-2",
                                              },
                                              [
                                                _c(
                                                  "vx-tooltip",
                                                  {
                                                    attrs: {
                                                      text: _vm.$t(
                                                        "servicesForm.tooltip.manPrice"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("vs-input", {
                                                      staticClass: "w-full",
                                                      attrs: {
                                                        type: "number",
                                                        label: _vm.$t(
                                                          "servicesForm.manPrice"
                                                        ),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.service
                                                            .first_input_price,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.service,
                                                            "first_input_price",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "service.first_input_price",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-col w-full md:mb-2 pl-2 mt-2",
                                              },
                                              [
                                                _c(
                                                  "vx-tooltip",
                                                  {
                                                    attrs: {
                                                      text: _vm.$t(
                                                        "servicesForm.tooltip.womanPrice"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("vs-input", {
                                                      staticClass: "w-full",
                                                      attrs: {
                                                        type: "number",
                                                        label: _vm.$t(
                                                          "servicesForm.womanPrice"
                                                        ),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.service
                                                            .second_input_price,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.service,
                                                            "second_input_price",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "service.second_input_price",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-tab",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "servicesForm.groupPricing.groupPrices"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "con-tab-ejemplo" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "vx-row mb-5" },
                                          [
                                            _c(
                                              "vs-alert",
                                              {
                                                staticClass:
                                                  "h-full mb-5 py-2 pr-2",
                                                attrs: {
                                                  color: "warning",
                                                  icon: "sms",
                                                  active:
                                                    _vm.groupPricesingAlertActive,
                                                  closable: "",
                                                  "close-icon": "close",
                                                },
                                                on: {
                                                  "update:active": function (
                                                    $event
                                                  ) {
                                                    _vm.groupPricesingAlertActive =
                                                      $event
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "mb-3" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "servicesForm.groupPricing.alert.header"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "servicesForm.groupPricing.alert.line1"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "servicesForm.groupPricing.alert.line2"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "vx-row h-full" },
                                          [
                                            _vm._l(
                                              _vm.service.custom_prices,
                                              function (customPricing, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "mt-2 w-full flex justify-between",
                                                  },
                                                  [
                                                    _c("vs-input", {
                                                      staticClass: "pl-2",
                                                      attrs: {
                                                        type: "number",
                                                        label: _vm.$t(
                                                          "servicesForm.groupPricing.menCount"
                                                        ),
                                                        autocomplete: "off",
                                                      },
                                                      model: {
                                                        value:
                                                          customPricing.men_count,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            customPricing,
                                                            "men_count",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customPricing.men_count",
                                                      },
                                                    }),
                                                    _c("vs-input", {
                                                      staticClass: "pl-1",
                                                      attrs: {
                                                        type: "number",
                                                        label: _vm.$t(
                                                          "servicesForm.groupPricing.manPrice"
                                                        ),
                                                        autocomplete: "off",
                                                      },
                                                      model: {
                                                        value:
                                                          customPricing.first_input_price,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            customPricing,
                                                            "first_input_price",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customPricing.first_input_price",
                                                      },
                                                    }),
                                                    _c("vs-input", {
                                                      staticClass: "pl-1",
                                                      attrs: {
                                                        type: "number",
                                                        label: _vm.$t(
                                                          "servicesForm.groupPricing.womenCount"
                                                        ),
                                                        autocomplete: "off",
                                                      },
                                                      model: {
                                                        value:
                                                          customPricing.women_count,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            customPricing,
                                                            "women_count",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customPricing.women_count",
                                                      },
                                                    }),
                                                    _c("vs-input", {
                                                      staticClass: "pl-1",
                                                      attrs: {
                                                        type: "number",
                                                        label: _vm.$t(
                                                          "servicesForm.groupPricing.womanPrice"
                                                        ),
                                                        autocomplete: "off",
                                                      },
                                                      model: {
                                                        value:
                                                          customPricing.second_input_price,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            customPricing,
                                                            "second_input_price",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customPricing.second_input_price",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mt-1 px-2",
                                                      },
                                                      [
                                                        _c("vs-button", {
                                                          staticClass:
                                                            "m-0 mt-5",
                                                          attrs: {
                                                            color: "danger",
                                                            icon: "delete",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeRow(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mt-2 w-full flex justify-end",
                                              },
                                              [
                                                _c(
                                                  "vs-button",
                                                  {
                                                    staticClass:
                                                      "m-0 mt-5 mr-2",
                                                    attrs: {
                                                      color: "success",
                                                      icon: "add",
                                                    },
                                                    on: { click: _vm.addRow },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "servicesForm.groupPricing.addRow"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _c("div", { staticClass: "col-span-2 lg:col-span-1" }, [
                      _c("span", { staticClass: "text-md" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("home.editBookingOptionForm.taxingStatus")
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse",
                        },
                        [
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name": "service.percentage_tax",
                                "vs-value": 1,
                              },
                              model: {
                                value: _vm.service.percentage_tax,
                                callback: function ($$v) {
                                  _vm.$set(_vm.service, "percentage_tax", $$v)
                                },
                                expression: "service.percentage_tax",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("home.editBookingOptionForm.taxable")
                                )
                              ),
                            ]
                          ),
                          _c(
                            "vs-radio",
                            {
                              attrs: {
                                "vs-name": "service.percentage_tax",
                                "vs-value": 0,
                              },
                              model: {
                                value: _vm.service.percentage_tax,
                                callback: function ($$v) {
                                  _vm.$set(_vm.service, "percentage_tax", $$v)
                                },
                                expression: "service.percentage_tax",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "home.editBookingOptionForm.nonTaxable"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.service.percentage_tax
                      ? _c("div", { staticClass: "col-span-2 lg:col-span-1" }, [
                          _c("span", { staticClass: "text-md" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "home.editBookingOptionForm.taxingIncluding"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse",
                            },
                            [
                              _c(
                                "vs-radio",
                                {
                                  attrs: {
                                    "vs-name": "tax_included",
                                    "vs-value": "included",
                                  },
                                  model: {
                                    value: _vm.tax_included,
                                    callback: function ($$v) {
                                      _vm.tax_included = $$v
                                    },
                                    expression: "tax_included",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.taxIncluded.included"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "vs-radio",
                                {
                                  attrs: {
                                    "vs-name": "tax_included",
                                    "vs-value": "not-included",
                                  },
                                  model: {
                                    value: _vm.tax_included,
                                    callback: function ($$v) {
                                      _vm.tax_included = $$v
                                    },
                                    expression: "tax_included",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "home.editBookingOptionForm.taxIncluded.notIncluded"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "col-span-2" }, [
                      _c("span", { staticClass: "text-md" }, [
                        _vm._v(
                          _vm._s(_vm.$t("servicesForm.addBookingOptions"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mt-2" },
                        [
                          _c("v-select", {
                            staticClass: "v-select-background",
                            attrs: {
                              multiple: "",
                              options: _vm.bookingOptionsList,
                              label: "name",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            model: {
                              value: _vm.selectedBookingOptions,
                              callback: function ($$v) {
                                _vm.selectedBookingOptions = $$v
                              },
                              expression: "selectedBookingOptions",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-span-2 my-10" }, [
                  _c(
                    "div",
                    { staticClass: "flex w-full justify-end" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mx-2",
                          attrs: { "icon-pack": "feather", icon: "icon-check" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.submitForm.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }