var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row" },
    [
      _c("vs-divider"),
      _c("div", { staticClass: "vx-col w-1/2 my-3" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("addBookingSidebar.invoices")))]),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-1/2" },
        [
          _c(
            "vs-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.currentBooking.option_id != null,
                  expression: "this.currentBooking.option_id != null",
                },
              ],
              staticClass: "btn-outline-primary",
              on: { click: _vm.setReceiptAsBookingOption },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("addBookingSidebar.setReceiptAsBookingOption"))
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full mt-5",
            attrs: {
              label: _vm.$t("addBookingSidebar.total_price"),
              type: "number",
              name: "total_price",
              autocomplete: "off",
            },
            on: { keyup: _vm.fillReceiptForm },
            model: {
              value: _vm.receiptClone.total_price,
              callback: function ($$v) {
                _vm.$set(_vm.receiptClone, "total_price", $$v)
              },
              expression: "receiptClone.total_price",
            },
          }),
          _c("span", { staticClass: "text-danger" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [
          _c("vs-input", {
            staticClass: "w-full mt-5",
            attrs: {
              label: _vm.$t("addBookingSidebar.agent_discount"),
              name: "agent_manual_discount",
            },
            on: { keyup: _vm.fillReceiptForm },
            model: {
              value: _vm.receiptClone.agent_manual_discount,
              callback: function ($$v) {
                _vm.$set(_vm.receiptClone, "agent_manual_discount", $$v)
              },
              expression: "receiptClone.agent_manual_discount",
            },
          }),
          _c("span", { staticClass: "text-danger" }),
        ],
        1
      ),
      _c("div", { staticClass: "vx-col md:w-1/2 w-full pt-5" }, [
        _c("div", { staticClass: "vx-row mt-2 mx-2 required" }, [
          _c("label", { staticClass: "v-select-label" }, [
            _vm._v(_vm._s(_vm.$t("home.editBookingOptionForm.taxingStatus"))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "vx-row mt-1 mx-2" },
          [
            _c(
              "vs-radio",
              {
                staticClass: "mr-2 mt-2",
                attrs: {
                  "vs-name": "receiptClone.percentage_tax",
                  "vs-value": 15,
                },
                on: { change: _vm.fillReceiptForm },
                model: {
                  value: _vm.receiptClone.percentage_tax,
                  callback: function ($$v) {
                    _vm.$set(_vm.receiptClone, "percentage_tax", $$v)
                  },
                  expression: "receiptClone.percentage_tax",
                },
              },
              [_vm._v(_vm._s(_vm.$t("home.editBookingOptionForm.taxable")))]
            ),
            _c(
              "vs-radio",
              {
                staticClass: "mr-2 mt-2",
                attrs: {
                  "vs-name": "receiptClone.percentage_tax",
                  "vs-value": 0,
                },
                on: { change: _vm.fillReceiptForm },
                model: {
                  value: _vm.receiptClone.percentage_tax,
                  callback: function ($$v) {
                    _vm.$set(_vm.receiptClone, "percentage_tax", $$v)
                  },
                  expression: "receiptClone.percentage_tax",
                },
              },
              [_vm._v(_vm._s(_vm.$t("home.editBookingOptionForm.nonTaxable")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [
          _c("vs-input", {
            staticClass: "w-full mt-5",
            attrs: {
              disabled: "",
              label: _vm.$t("addBookingSidebar.tax"),
              name: "tax",
            },
            on: { keyup: _vm.fillReceiptForm },
            model: {
              value: _vm.receiptClone.percentage_tax,
              callback: function ($$v) {
                _vm.$set(_vm.receiptClone, "percentage_tax", $$v)
              },
              expression: "receiptClone.percentage_tax",
            },
          }),
          _c("span", { staticClass: "text-danger" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full mt-5",
            attrs: {
              disabled: "",
              label: _vm.$t("addBookingSidebar.totalPriceWithTax"),
              name: "totalPriceWithTax",
              autocomplete: "off",
            },
            on: { keyup: _vm.fillReceiptForm },
            model: {
              value: _vm.receiptClone.total_price_with_tax,
              callback: function ($$v) {
                _vm.$set(_vm.receiptClone, "total_price_with_tax", $$v)
              },
              expression: "receiptClone.total_price_with_tax",
            },
          }),
          _c("span", { staticClass: "text-danger" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full mt-5",
            attrs: {
              disabled: "",
              label: _vm.$t("addBookingSidebar.servicesTotalPrice"),
              name: "deposit",
              autocomplete: "off",
            },
            on: { keyup: _vm.fillReceiptForm },
            model: {
              value: _vm.receiptClone.services_total_price,
              callback: function ($$v) {
                _vm.$set(_vm.receiptClone, "services_total_price", $$v)
              },
              expression: "receiptClone.services_total_price",
            },
          }),
          _c("span", { staticClass: "text-danger" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full mt-5",
            attrs: {
              disabled: "",
              label: _vm.$t("addBookingSidebar.finalPrice"),
              name: "totalPriceWithTax",
              autocomplete: "off",
            },
            on: { keyup: _vm.fillReceiptForm },
            model: {
              value: _vm.receiptClone.final_price,
              callback: function ($$v) {
                _vm.$set(_vm.receiptClone, "final_price", $$v)
              },
              expression: "receiptClone.final_price",
            },
          }),
          _c("span", { staticClass: "text-danger" }),
        ],
        1
      ),
      _c("vs-divider"),
      _vm.receiptClone.deposit
        ? _c("div", { staticClass: "vx-col w-full my-5" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("addBookingSidebar.deposit_amount_note")) + " "
              ),
              _c("span", { staticClass: "text-2xl" }, [
                _vm._v(_vm._s(_vm.receiptClone.deposit)),
              ]),
              _vm._v(" " + _vm._s(_vm.$t("SAR"))),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [
          _c("vs-input", {
            staticClass: "w-full mt-5",
            attrs: {
              type: "number",
              label: _vm.$t("addBookingSidebar.paid_at_place_amount"),
              name: "paid_at_place_amount_value",
            },
            on: { keyup: _vm.fillReceiptForm },
            model: {
              value: _vm.receiptClone.paid_at_place_amount_value,
              callback: function ($$v) {
                _vm.$set(_vm.receiptClone, "paid_at_place_amount_value", $$v)
              },
              expression: "receiptClone.paid_at_place_amount_value",
            },
          }),
          _c("span", { staticClass: "text-danger" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full mt-5",
            attrs: {
              disabled: "",
              label: _vm.$t("addBookingSidebar.remainingAmount"),
              name: "remainingAmount",
              autocomplete: "off",
            },
            on: { keyup: _vm.fillReceiptForm },
            model: {
              value: _vm.receiptClone.remaining_amount,
              callback: function ($$v) {
                _vm.$set(_vm.receiptClone, "remaining_amount", $$v)
              },
              expression: "receiptClone.remaining_amount",
            },
          }),
          _c("span", { staticClass: "text-danger" }),
        ],
        1
      ),
      _c("vs-textarea", {
        staticClass: "mt-5 w-full",
        attrs: {
          label: _vm.$t("addBookingSidebar.agentPaymentNotes"),
          name: "payment_notes_by_agent",
        },
        on: { change: _vm.bookingCloneChanged },
        model: {
          value: _vm.bookingClone.payment_notes_by_agent,
          callback: function ($$v) {
            _vm.$set(_vm.bookingClone, "payment_notes_by_agent", $$v)
          },
          expression: "bookingClone.payment_notes_by_agent",
        },
      }),
      _c("vs-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }