var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-dropdown",
    {
      staticClass: "cursor-pointer",
      attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
    },
    [
      _c("feather-icon", {
        staticClass: "cursor-pointer mt-1 sm:mr-6 mr-2",
        attrs: { icon: "BellIcon", badge: _vm.unreadNotifications.length },
      }),
      _c(
        "vs-dropdown-menu",
        {
          staticClass:
            "notification-dropdown dropdown-custom vx-navbar-dropdown",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "notification-top text-center p-5 bg-primary text-white",
            },
            [
              _c("h3", { staticClass: "text-white" }, [
                _vm._v(
                  _vm._s(_vm.unreadNotifications.length) +
                    " " +
                    _vm._s(_vm.$t("new"))
                ),
              ]),
              _c("p", { staticClass: "opacity-75" }, [
                _vm._v(_vm._s(_vm.$t("appNotifications"))),
              ]),
            ]
          ),
          _c(
            _vm.scrollbarTag,
            {
              key: _vm.$vs.rtl,
              ref: "mainSidebarPs",
              tag: "component",
              staticClass: "scroll-area--nofications-dropdown p-0 mb-10",
              attrs: { settings: _vm.settings },
            },
            [
              _c(
                "ul",
                { staticClass: "bordered-items" },
                _vm._l(_vm.unreadNotifications, function (ntf) {
                  return _c(
                    "li",
                    {
                      key: ntf.index,
                      staticClass:
                        "flex justify-between px-4 py-4 notification cursor-pointer",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-start" },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: ntf.icon,
                              svgClasses: [
                                `text-${ntf.category}`,
                                "stroke-current mr-1 h-6 w-6",
                              ],
                            },
                          }),
                          _c("div", { staticClass: "mx-2" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-medium block notification-title",
                                class: [`text-${ntf.category}`],
                              },
                              [_vm._v(_vm._s(ntf.title))]
                            ),
                            _c("small", [_vm._v(_vm._s(ntf.msg))]),
                          ]),
                        ],
                        1
                      ),
                      _c("small", { staticClass: "mt-1 whitespace-no-wrap" }, [
                        _vm._v(_vm._s(_vm.elapsedTime(ntf.time))),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer",
            },
            [_c("span", [_vm._v("View All Notifications")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }