var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-wrap pt-0" },
    [
      _vm.isEdit
        ? _c("vs-input", {
            staticClass: "mt-5 w-1/2 pr-2",
            attrs: {
              disabled: "",
              label: _vm.$t("addBookingSidebar.bookingNumber"),
              name: "bookingNumber",
            },
            model: {
              value: _vm.booking.booking_number,
              callback: function ($$v) {
                _vm.$set(_vm.booking, "booking_number", $$v)
              },
              expression: "booking.booking_number",
            },
          })
        : _vm._e(),
      _vm.isEdit
        ? _c("vs-input", {
            staticClass: "mt-5 w-1/2 pr-2",
            attrs: {
              disabled: "",
              label: _vm.$t("addBookingSidebar.created_at"),
              name: "created_at",
            },
            model: {
              value: _vm.booking.created_at,
              callback: function ($$v) {
                _vm.$set(_vm.booking, "created_at", $$v)
              },
              expression: "booking.created_at",
            },
          })
        : _vm._e(),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
        ],
        staticClass: "mt-5 w-1/2 pr-2",
        attrs: {
          label: _vm.$t("addBookingSidebar.customerName"),
          "data-vv-scope": "step-1",
          name: "customer_name",
          autocomplete: "off",
        },
        on: { keyup: _vm.fillForm },
        model: {
          value: _vm.bookingClone.customer_name,
          callback: function ($$v) {
            _vm.$set(_vm.bookingClone, "customer_name", $$v)
          },
          expression: "bookingClone.customer_name",
        },
      }),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: {
              required: true,
              regex:
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            },
            expression:
              "{required: true,regex: /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/im }",
          },
        ],
        staticClass: "mt-5 w-1/2 pr-2",
        attrs: {
          label: _vm.$t("addBookingSidebar.customerPhoneNumber"),
          "data-vv-scope": "step-1",
          name: "customer_phone_number",
          autocomplete: "off",
        },
        on: { keyup: _vm.fillForm },
        model: {
          value: _vm.bookingClone.customer_phone_number,
          callback: function ($$v) {
            _vm.$set(_vm.bookingClone, "customer_phone_number", $$v)
          },
          expression: "bookingClone.customer_phone_number",
        },
      }),
      _c(
        "vs-checkbox",
        {
          staticClass: "mt-10 mb-5 w-full pr-2",
          model: {
            value: _vm.bookingClone.is_booking_for_business,
            callback: function ($$v) {
              _vm.$set(_vm.bookingClone, "is_booking_for_business", $$v)
            },
            expression: "bookingClone.is_booking_for_business",
          },
        },
        [_vm._v(_vm._s(_vm.$t("addBookingSidebar.isBookingForBusiness")))]
      ),
      _vm.bookingClone.is_booking_for_business
        ? _c(
            "div",
            { staticClass: "w-full mb-5 flex flex-wrap" },
            [
              _c("vs-input", {
                staticClass: "w-1/2 pr-2",
                attrs: {
                  label: _vm.$t("addBookingSidebar.customerVatNumber"),
                  name: "customer_vat_number",
                  autocomplete: "off",
                },
                on: { keyup: _vm.fillForm },
                model: {
                  value: _vm.bookingClone.customer_vat_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.bookingClone, "customer_vat_number", $$v)
                  },
                  expression: "bookingClone.customer_vat_number",
                },
              }),
              _c("vs-input", {
                staticClass: "w-1/2 pr-2",
                attrs: {
                  label: _vm.$t(
                    "addBookingSidebar.customerCommercialRegistrationNumber"
                  ),
                  name: "customer_commercial_registration_number",
                  autocomplete: "off",
                },
                on: { keyup: _vm.fillForm },
                model: {
                  value:
                    _vm.bookingClone.customer_commercial_registration_number,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.bookingClone,
                      "customer_commercial_registration_number",
                      $$v
                    )
                  },
                  expression:
                    "bookingClone.customer_commercial_registration_number",
                },
              }),
              _c("vs-input", {
                staticClass: "w-1/2 pr-2 mt-2",
                attrs: {
                  label: _vm.$t("addBookingSidebar.customerAddress"),
                  name: "customer_city",
                },
                on: { keyup: _vm.fillForm },
                model: {
                  value: _vm.bookingClone.customer_city,
                  callback: function ($$v) {
                    _vm.$set(_vm.bookingClone, "customer_city", $$v)
                  },
                  expression: "bookingClone.customer_city",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }