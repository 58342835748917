var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "data-list-container", attrs: { id: "pageView" } },
    [
      _vm.error
        ? _c(
            "vs-alert",
            {
              staticClass: "h-auto mb-4",
              attrs: { border: "", color: "danger" },
            },
            [_vm._v(" " + _vm._s(_vm.error) + " ")]
          )
        : _vm._e(),
      !!_vm.venue.commercial_record_image ||
      !!_vm.venue.company_name ||
      !!_vm.venue.vat_number
        ? _c(
            "vx-card",
            { staticClass: "my-5" },
            [
              _c("div", { staticClass: "vx-row" }, [
                !!_vm.venue.company_name
                  ? _c("div", { staticClass: "vx-col w-1/2" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("contract.company_name")) + ": "
                      ),
                      _c("h4", { staticStyle: { display: "inline" } }, [
                        _vm._v(_vm._s(_vm.venue.company_name)),
                      ]),
                    ])
                  : _vm._e(),
                !!_vm.venue.vat_number
                  ? _c("div", { staticClass: "vx-col w-1/2" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("contract.vat_number")) + ": "
                      ),
                      _c("h4", { staticStyle: { display: "inline" } }, [
                        _vm._v(_vm._s(_vm.venue.vat_number)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("vs-divider"),
              !!_vm.venue.commercial_record_image
                ? _c("div", { staticClass: "vx-row flex justify-center" }, [
                    _c("img", {
                      staticStyle: { "max-height": "300px" },
                      attrs: {
                        src:
                          _vm.baseURL + "/" + _vm.venue.commercial_record_image,
                      },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.agentContract != "null"
        ? _c(
            "vx-card",
            { staticClass: "my-5" },
            [
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.$vs.rtl,
                  tag: "component",
                  staticClass: "scroll-area max-h-250",
                  attrs: { settings: _vm.settings, dir: "ltr" },
                },
                [
                  _c(
                    "vue-markdown",
                    { staticClass: "px-4", attrs: { dir: "rtl" } },
                    [_vm._v(_vm._s(_vm.agentContract))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.percentage != "null"
        ? _c("vx-card", [
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm
                      .$t("contract.percentage")
                      .replace(":percentage:", _vm.percentage)
                  ) +
                  " "
              ),
            ]),
          ])
        : _vm._e(),
      _vm.agentContract != "null" && _vm.percentage != "null"
        ? _c("div", { staticClass: "vx-col w-full md:mb-2 mt-2" }, [
            _c(
              "div",
              { staticClass: "m-0 flex flex-col text-left items-start" },
              [
                _c(
                  "div",
                  { staticClass: "flex flex-col" },
                  [
                    _c(
                      "vs-checkbox",
                      {
                        staticClass: "mt-2",
                        attrs: { name: "is_separated" },
                        model: {
                          value: _vm.is_approved,
                          callback: function ($$v) {
                            _vm.is_approved = $$v
                          },
                          expression: "is_approved",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("contract.applyCheck")))]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "mt-2 ml-6 pl-2" }, [
                  _vm._v(_vm._s(_vm.$t("contract.message"))),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vx-col w-full md:mt-8" },
        [
          _vm.agentContract != "null" && _vm.percentage != "null"
            ? _c("vs-button", { on: { click: _vm.sendApprovedSubmission } }, [
                _vm._v(_vm._s(_vm.$t("contract.apply"))),
              ])
            : _c("p", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.$t("contract.noContract"))),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }