var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full flex flex-wrap" },
    [
      _vm.user.is_q_super_admin
        ? _c("h3", { staticStyle: { "{fontWeight": "'900'}" } }, [
            _vm._v(
              _vm._s(_vm.$vs.rtl ? _vm.venue.arabic_name : _vm.venue.name)
            ),
          ])
        : _c("v-select", {
            staticClass: "v-select-background text-xs lg:text-sm lg:w-64 w-32",
            attrs: {
              value: _vm.$vs.rtl ? _vm.venue.arabic_name : _vm.venue.name,
              options: _vm.agentVenues,
              label: _vm.$vs.rtl ? "arabic_name" : "name",
              clearable: false,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: { input: _vm.changeVenue },
          }),
      _vm.venue.subdomain_url
        ? _c("div", { staticClass: "flex flex-col p-2 px-4 justify-center" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.venue.subdomain_url,
                  target: "_blank",
                  rel: "noopener noreferrer",
                  title: "Opens in new tab",
                },
              },
              [_c("i", { staticClass: "fas fa-external-link-alt fa-lg" })]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }